import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./LicenceGeneratorNew.css";
import { FieldGroup } from "../Components/Forms";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'


import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../Components/Loading";
import useFetch from "./useFetch";
import { listLicences } from "../graphql/queries";
import { createLicence } from "../graphql/mutations";
import aws_exports from "../aws-exports";
import { Discovery } from "aws-sdk";

import CounterTypeSelect  from "./CounterTypeSelect.js";

import Tables from "./Table";

Amplify.configure(aws_exports, {});



//Find counter registration in the SQL database  wit null Organisation. These records are automatically generated
//and counter need to be registered properly with an organisation and stored in th dynamodb database as well

export default function UnregisteredCounters(props) {


  const [theData, setTheData] = useState(null);
  const [labelList, setLabelList] = useState(null);



 
 useEffect(() => {

    setTheData (null)
    setLabelList(null)
    loadData();
}, [props.Update]);




const loadData = async () => {

    //We need an ID for new Population
    
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/getunregistered";
    
    // https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
    //Get ID for pop
    let myInit = {
    
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
      
      }  
    
    };

    
    API.get(apiName, path, myInit)
    .then ((response) => {
    


       let len = response.length;

       let tmp = [];
       for (let i = 0; i<len; i++){
         response[i]["key"]=i;
         tmp.push (
          {
          value: response[i].Counter_ID,
          label: response[i].Counter_ID+" > "+response[i].Description
          }
        )
       } 
     
       setTheData(response)
       setLabelList (tmp)
        // TrgPop.PopId = newPopId+1;

   
    
    return;
    
    })
    .catch ((er) => {
    console.log ("Catch AFTER get types "+JSON.stringify(er));
    
    alert ("Error fetching types   "+er);
    return;
    
    });

    return 
}







let content = ""; //<button onClick={() => loadData (true)}> Show Unregistered</button>;

if (theData != null){


  content =  <div>

<Select
  className={"select-react-component select-counter-org"}
  placeholder="Select Uncompleted"
  //value={addCounterToOrg}
  options={labelList}
  //isMulti
  isSearchable
  ignoreAccents={false}
  onChange={(d) => props.Callback (d) }
  //isClearable={false}
/>
</div>;
}



return (
   <div>
    {content}
    </div>
)
}