import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton, Modal } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";

import MoveFishModal from "./MoveFishModal"






import "./Actions.css";
import "./../Feeding.css";

import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";





Amplify.configure(aws_exports, {});




 







let StatTime = new Date (2000, 1,1, 0,0,0,0);

let ModifyDesc = "";  //

const SetModifyDesc = (strName)=>{
    if (ModifyDesc.indexOf(strName) >= 0)
        return;
    if (ModifyDesc.length > 0 )
      ModifyDesc+= "; ";

    ModifyDesc += strName;

    if (ModifyDesc.length >= 120)
      ModifyDesc = ModifyDesc.substr(0,120);

}


 
export default function MoveFish(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);

 const [IsLoading, setLoading] = useState(true);




 const [SaveMsg, setSaveMsg] = useState("");

  const [SourceCages, setSourceCages] = useState([]);

  const [TargetCages, setTargetCages] = useState([]);
  const [TheCages, setTheCages] = useState (null);
  const [SelectedSource, setSelectedSource] = useState(null);
  const [SelectedTarget, setSelectedTarget] = useState (null);

 
  

  const [ShowModal, setShowModal] = useState(false);

 

 useEffect(() => {

  loadData();


}, [props.FarmID, props.DashModified]);


const loadData = async () => {

  fetchCagesList()


}



  const fetchCagesList = () => {
    

    setLoading(true);
  

    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/getcageswithcounts";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID

      }
    };

   

    

    API.get(apiName, path, myInit).then(response => {

      


        let sourceItems = [];
        response.map(cg => 
          {if (cg.NoOfFish > 0)
            sourceItems.push({
              label: cg.Name,
              value: cg.CageId
            })
          }
        );

        setSourceCages (sourceItems);


        let targetItems = [];
        response.map(cg =>            
            targetItems.push({
              label: cg.Name,
              value: cg.CageId
            })
          
        );

        setTargetCages (targetItems);

        setTheCages (response);

        /*
        let targetItems = [];
        response.map(farm => 
             targetItems.push({
              label: farm.FarmName,
              value: farm.FarmID
            })
          
        );
        setTargetCages(targetItems);
*/

       

       // setStatusArray(response);
  
    })
      .catch((err) => {
        console.log ("Status error response: "+JSON.stringify (err))
      })

      setLoading(false);

  };






  

  const onSourceSelect = (dd) => {


  

    setSelectedSource (dd);





    
  


  }


  const onTargetSelect = (dd) => {


    if (!TargetCages || TargetCages.length == 0)
      return;

    

    if (dd.value == SelectedSource.value ){
      alert ("Selected source and target are the same !");
      setSelectedTarget (null);
      return;
    }

    setSelectedTarget (dd);

   
    setSaveMsg("");
    setShowModal(true);

  


  }


const SaveMove = (SaveCase, SrcPop, TrgPop, movedArray) => {




  let moved = {moved: movedArray };

  let TotalFishMoved = movedArray[0]+movedArray[1]+movedArray[2];

  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/movefish";





  if (TotalFishMoved > 0){
      if (SaveCase == 1)
        SetModifyDesc ("Population moved to cage "+TrgPop.CurrentCageId+ " from "+SrcPop.CurrentCageId)
      else 
        SetModifyDesc ("Moved fish between cages/populations  ("+SrcPop.CurrentCageId+ " to "+ TrgPop.CurrentCageId+")")
  }




  //let path = "/counters/feeding/testtest";
  let myInit = {
    // OPTIONAL
    headers: {
       
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
    
      Case: SaveCase, 
      Total: TotalFishMoved,
      FarmID:props.FarmID


    }  ,

    body: {

      U:props.userData.name,
      S: SrcPop,
      T: TrgPop,
      M: ModifyDesc,
      Moved: moved 

    }

  };




  API.post(apiName, path, myInit)
  .then ((response) => {
  
      setSaveMsg (
        <p>Registration saved successfully</p>
      )

       props.UpdateDash();
       ModifyDesc = "";

  })
  .catch ((er) => {
    console.log ("Catch AFTER movefish PUT er: "+JSON.stringify(er));
    let themsg = <div>
      <p>Error!  - registration not saved: </p>
   
      {er.message}
      
    </div>
    setSaveMsg (themsg);


  })


setSelectedSource(null);
setSelectedTarget (null);
setShowModal(false);

}



const ModalCallback = (SaveCase, SrcPop, TrgPop, movedArray) => {



    if (!movedArray || (movedArray[0]+ movedArray[1]+movedArray[2]) <=0){
        console.log ("No FishMoved");
        setShowModal(false);
        return;
    }

    if (!SaveCase || SaveCase <= 0){
      alert ("Error during Save operation")
      return;
    }

    setShowModal(false);


 


    //If we need to create new pop we need get new ID for it from database. Cases 2, 3 & 4 
/*
    if (SaveCase >= 3 && SaveCase <=4){
      let apiName = "vakicloudRdsAPI";
      let path = "/counters/feeding/simpleget";

  
 
  let myInit = {
    // OPTIONAL
    headers: {
       
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      FarmID:props.FarmID,
      
      What: "MAX(PopId) as PopId",
      Table: "tblPopulation" 

    }  ,

  };
console.log ("About to get simple: "+JSON.stringify(myInit));

  API.get(apiName, path, myInit)
  .then ((response) => {
      

      let newPopId = response[0].PopId;

      TrgPop.PopId = newPopId+1;
      SaveMove (NoUnknown, NoMales, NoFemales, SaveCase, SrcPop, TrgPop, movedArray);
      return;

  })
  .catch ((er) => {
    console.log ("Catch AFTER Simpleget PUT er: "+JSON.stringify(er));
    let themsg = <div>
      <p>Error (get)!  - registration not saved: </p>
   
      {er.message}
      
    </div>
    setSaveMsg (themsg);

    return;

  })




    }

*/



    SaveMove (SaveCase, SrcPop, TrgPop, movedArray);
}

  let TheModal = "";

 

  if (ShowModal){
      //find source
      let theSource = TheCages.find(r=>r.CageId === SelectedSource.value );
      let theTarget = TheCages.find (r=>r.CageId === SelectedTarget.value );

   
   

      TheModal = <div><MoveFishModal  SourceCage = {theSource} TargetCage = {theTarget}  hideMe= {ModalCallback}  FarmID={props.FarmID}  DashModified={props.DashModified} /></div>
  }


  
  

 return <div  className="mf-wrap" >


<h4>Register movement of fish from one cage to another</h4>
<br/>
<Form.Group controlId="InpFCR" as={Row}   >
              
              <Col sm="6">
                Source Cage
              <Select
        caption = {"ertert"}
          options={SourceCages}
         
          value={SelectedSource}
          onChange={onSourceSelect}
          ignoreAccents={false} // Má fjarlægja
          placeholder = {"Select cage to move from..."}
        />

              </Col>

              <Col sm="6">
              Target Cage
              <Select
              caption = {"ertert"}
          options={TargetCages}
        
          value={SelectedTarget}
          onChange={onTargetSelect}
          ignoreAccents={false} // Má fjarlægja
          placeholder = {"Select cage to move to..."}
        
          isDisabled = {!SelectedSource}
        />

              </Col>

            </Form.Group>

      {SaveMsg}


      {TheModal}

<div className="mf-close">
      <Button variant="outline-secondary" type="Button" onClick={props.HideMe}  >
              Close
    </Button>

    </div>




 </div>
}
