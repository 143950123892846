import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";
import ProfileColumn from "./ProfileColumn"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";



Amplify.configure(aws_exports, {});


 
let ModifyDesc = "";  //

const AddModifyDesc = (strDesc)=>{
  if (ModifyDesc.length >= 120)
      return;


    if (ModifyDesc.length > 0 )
      ModifyDesc+= ";";

    ModifyDesc += strDesc;

    if (ModifyDesc.length >= 120)
      ModifyDesc = ModifyDesc.substr(0,120);

}






   let eventKey = null; 
export default function Silos(props) {


 
 const [IsLoading, setLoading] = useState(true);

const [EdSilos, setEdSilos] = useState ([]);
const [SysIdx, setSysIdx] = useState(0)

const [IsModified, setIsModified] = useState (false);
const [SaveMessage, setSaveMessage] = useState ("");



 useEffect(() => {


   
  loadData();
}, [props.FarmID, SysIdx]);


const loadData = async () => {


        //We need an ID for new Population
        
        let apiName = "vakicloudRdsAPI";
        let path = "/counters/feeding/simpleget";
        
        // https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
        //Get ID for pop
        let myInit = {
        
        response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {
            FarmID:props.FarmID,
            
            What: "*",
            Table: "Dim_Silo" ,
            And: "And SysIdx = "+props.SysIdx
          }  
        
        };
     
        
        API.get(apiName, path, myInit)
        .then ((response) => {
        
          
         
         // TrgPop.PopId = newPopId+1;

            let len = response.length;
            setEdSilos ([]);
            let tmpList = [];
            for (let i = 0; i<len; i++){
                let tmpSil ={... response [i]};
                tmpSil["key"]=tmpSil["SiloId"];
                tmpList.push(tmpSil);

            }
            setEdSilos (tmpList);
        
         
        
        
        return;
        
        })
        .catch ((er) => {
        console.log ("Catch AFTER Simpleget silo: "+JSON.stringify(er));
        
        alert ("Error fetching silo data");
        return;
        
        });
        
          
        


}
















 let options = [ {value:"H01", label:'H01'},{value:"H02", label:'H02'},{value:"H03", label:'H03'},{value:"H04", label:'H04'}  ]
/*
 let Columns = [{
    dataField: 'PipeId',
    text: 'Pipe',
    name: "pipe",
    editable:false,
    //style: cellCagesFormatter,
    
    sort: true

  },

 {
    dataField: 'SecOfBlow',
    text: 'Blowtime (sec)',
    name: "blow",
 

    defaultValue:0,
    editor: {
      type: "number",
      onChange: handleEditBlow
      
    },
    
  },



  {
    dataField: 'Name',
    text: 'Cage',
    name: "cage",
    sort: true,
    editable: true,
    editor: {
      type: "select",
      options: AllCages,
      onChange:  handleCageSelect
    },
 
  }
 ];
*/

const handleEditMax =  (e)=>{

    let idx = e.target.parentNode.parentNode.rowIndex-1;
    let tmpSilo  = EdSilos.map ((pc) =>{return {...pc}});
  
    tmpSilo[idx].MaxKilos =  parseInt ( e.target.value);
  
    setIsModified(true);
    setEdSilos (tmpSilo);
    setSaveMessage ("");
  }


  const handleEditDesc =  (e)=>{

    let idx = e.target.parentNode.parentNode.rowIndex-1;
    let tmpSilo  = EdSilos.map ((pc) =>{return {...pc}});
  
    tmpSilo[idx].Description =   e.target.value;
  
    setIsModified(true);
    setEdSilos (tmpSilo);
    setSaveMessage ("");
  }


let Columns= [

    {
        dataField: 'SiloId',
        text: 'ID',
        name: "silo",
        editable:false,
        //style: cellCagesFormatter,
        
        sort: true
    
      },
      

      {
        dataField: 'Description',
        text: 'Description',
        name: "desc",
        editable:true,
        editor: {
            onChange: handleEditDesc
          
          },
        //style: cellCagesFormatter,
        
      },   


      {
        dataField: 'MaxKilos',
        text: 'Max Content',
        name: "max",
        editable:true,
        editor: {
            type: "number",
            onChange: handleEditMax
            
          },
        //style: cellCagesFormatter,
         
      },

 
      {
        dataField: 'KilosInSilo',
        text: 'Content (kg)',
        name: "content",
        editable:false
        //style: cellCagesFormatter,
         
      },      

      {
        dataField: 'GramsPrSec',
        text: 'g/sec',
        name: "flow",
        editable:false
        //style: cellCagesFormatter,
         
      }


];
 



const handleClose = ()=>{
  props.HideMe();
}



/*

"FarmID": 10003,
  "SysIdx": 0,
  "SiloId": 3,
  "Description": "Silo 3",
  "GramsPrSec": 150,
  "Throughput": 100,
  "KilosInSilo": 4000,
  "MaxKilos": 8000,
  "PlcIdx": 3,
  "CurrentFood": "8mm"


*/

const onAdd = () => {

    let newId = EdSilos.length+1;

    let tmpSilo  = EdSilos.map ((pc) =>{return {...pc}});

     let newSiloId = 10*props.SysIdx + newId;


    tmpSilo.push (
        {
        FarmID: props.FarmID,
  SysIdx: props.SysIdx,
  SiloId: newSiloId,
  Description: "",
  GramsPrSec: 0,
  Throughput: 100,
  KilosInSilo: 0,
  MaxKilos: null,
  PlcIdx: newId,
  CurrentFood: null

     }
    )

    setEdSilos(tmpSilo);
    setIsModified (true);
}
  

const onSave= () => {
alert ("save")
}

 return <div>

{< BootstrapTable
    hover
    keyField='SiloId'
    data={EdSilos}
    noDataIndication="No silos defined"            
    //data = {[{PipeId:1, key:100},{PipeId:2, key:101} ]}
    columns={Columns}
   // rowEvents={RowEvents}
    cellEdit={ cellEditFactory({ mode: 'click' }) }

  />}
<div>
<Button variant="outline-secondary" type="Button"  onClick={onAdd} disabled={EdSilos.length >= 9} >
              Add silo
    </Button>

    <Button variant="outline-secondary" type="Button" disabled={!IsModified}  onClick={onSave} >
              Save
    </Button>
</div>


 </div>


}

/*
{!IsLoading && < BootstrapTable
    hover
    keyField='PipeId'
    data={EdSilos}
    noDataIndication="No pipes defined"            
    //data = {[{PipeId:1, key:100},{PipeId:2, key:101} ]}
    columns={Columns}
   // rowEvents={RowEvents}
    cellEdit={ cellEditFactory({ mode: 'click' }) }

  />}
  */