import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";
import ProfileColumn from "./ProfileColumn"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl,Button, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";

import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";



Amplify.configure(aws_exports, {});



let ModifyDesc = "";  //

const SetModifyDesc = (strName)=>{
    if (ModifyDesc.indexOf(strName) >= 0)
        return;
    if (ModifyDesc.length > 0 )
      ModifyDesc+= "; ";

    ModifyDesc += strName;

    if (ModifyDesc.length >= 120)
      ModifyDesc = ModifyDesc.substr(0,120);

}

const EmptyProfile = {

    FarmID: 0,
    ProfileId:"", 
    StartHour: 0,
    EndHour: 23,
    Description:"",
    H00:4.16666667,    H01:4.16666667,    H02:4.16666667,    H03:4.16666667,    H04:4.16666667,    H05:4.16666667,    H06:4.16666667,    H07:4.16666667,    H08:4.16666667,    H09:4.16666667,
    H10:4.16666667,    H11:4.16666667,    H12:4.16666667,    H13:4.16666667,    H14:4.16666667,    H15:4.16666667,    H16:4.16666667,    H17:4.16666667,    H18:4.16666667,    H19:4.16666667,   
    H20:4.16666667,    H21:4.16666667,    H22:4.16666667,    H23:4.16666667,
    Hidden:0,
    altID:-1


}   

const lst24 = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23];




   let eventKey = null; 
export default function Profiles(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);
 

 const [SaveError, setSaveError ] = useState("");
 const [Modified, setModified] = useState (false)
 const [IsLoading, setLoading] = useState(true);
 const [ProfilesList, setProfilesList] = useState([]);
 const [SelectedProfileId, setSelectedProfileId ] = useState (null);




 const [TheProfile, setTheProfile] = useState(EmptyProfile);
 const [EditProfile, setEditProfile] = useState(EmptyProfile);


useEffect(() => {
  
    loadData();
  }, [props.FarmID]);


  const loadData = async () => {
    fetchTheProfiles()
  };

  const fetchTheProfiles = () => {
  
  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/profiles";
  let myInit = {

    // OPTIONAL
    headers: {}, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      FarmID: props.FarmID,
      Hidden:0


    }
  };




  API.get(apiName, path, myInit).then(response => {



    
   

    setProfilesList(response);
    setSelectedProfileId("");


    setLoading(false);


  })
    .catch((err) => {
   
      setLoading(false);
      setProfilesList([]);
      setSelectedProfileId("");

    })

  };




  let ProfileSelectList = ProfilesList.map ((pidObj, index) => { return{label: pidObj.ProfileId, value: pidObj.ProfileId}});


  const  UpdateProfile = async () => {


    let setString = "";


   

    let pnArr = Object.getOwnPropertyNames(EditProfile);
    let pnLen = pnArr.length;
    let theOriginalPop = ProfilesList.find (r=>r.ProfileId == EditProfile.ProfileId)
    for (var i=0; i<pnLen; i++){
      let pn = pnArr[i];
      if (theOriginalPop[pn] != EditProfile[pn])
        setString += ","+pn+"= '"+EditProfile[pn]+"'" ;
  
    }
  
    
  
  
  
    if (setString.length == 0 )
      return;
  
    if (setString[0] === ',' )
      setString = setString.substr(1);  
  
  
  
    
  
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/updateprofile";
    let myInit = {
  
      // OPTIONAL
      headers: {
        
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
   
       
        FarmID:EditProfile.FarmID,
        ProfileId: EditProfile.ProfileId
 
  
      }  ,
  
      body: {
          values: setString,
          M: "Edited profile: "+EditProfile.ProfileId,
          U: props.userData.name
        }


  
    };
  
    API.post(apiName, path, myInit)
    .then ((response) => {


        onSave (false );
        //setThePop(TheEditPop);
  
    })
    .catch ((er) => {
      onSave (false,"Error updating profile: "+ er.message );


  
    })
  
  
  }


  const  InsertProfile = async () => {


    let fieldString = "";
    let valueString = "";
    let Action = "update";
    if (EditProfile.new){
      delete EditProfile.new;
    }
  


    EditProfile["FarmID"] = props.FarmID;

    let pnArr = Object.getOwnPropertyNames(EditProfile);
    let pnLen = pnArr.length;
  
    for (var i=0; i<pnLen; i++){
      let pn = pnArr[i];

      if (pn == "altID")
        continue;  //Will be taken care of on the server
      fieldString+=pn;



      let val = EditProfile[pn];

      if ((typeof val).toUpperCase() == "STRING")
          valueString += "'"+val+"'"
      else
          valueString += val;
          
         
 
  
      if ((i+1) < pnLen){
        fieldString+= ", ";
        valueString += ", ";
      }

    }
  
  

  

  
  
  
    
  
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/addprofile";
    let myInit = {
  
      // OPTIONAL
      headers: {
        
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
   
        FarmID: props.FarmID
        
 
  
      }  ,
  
      
  
  
  
  
  
      body: {
  
        values: valueString,
        fields: fieldString,
        M: "Created profile: "+EditProfile.ProfileId,
        U: props.userData.name 


  
      }
      
      /*
      , 
      body : {values:  setString}
      */
  
       

  
    };
  
    API.post(apiName, path, myInit)
    .then ((response) => {
 
  
        onSave (true, null, response);
        //setThePop(TheEditPop);
  
    })
    .catch ((er) => {

      onSave (true,"Error adding new profile: "+ er.message  );

  
    })
  
  
  }


  const findFullColValue  = (profil) =>{
    if (!profil)
      return 100;

      //Count non-zero days
      let cnt = 0;
      let max = -1;
 
      for (var i=profil.StartHour; i<=profil.EndHour; i++ ){
        let val = profil["H"+ String(i).padStart(2, '0')];
         if (val > 0){
             if (max < val)
              max = val;    
         }
      }
      


      
      let fullColVal = 100;
      if (max < 7)
        fullColVal = 12;
      else if (max < 14)
        fullColVal = 20;  
      else if (max < 20)
        fullColVal = 27;         
      else if (max < 30)
         fullColVal = 40;
        
      else if (max < 50)
         fullColVal = 80;




      return fullColVal;   


  } 


  const handleSelectedProfile = (sel)=>{


    var prof = ProfilesList.find(r=>r.ProfileId == sel.value);


    if (prof){
      setTheProfile(prof);
      const tt = {...prof}
      setEditProfile(tt);
      //Count non-zero days
      


      
    }

    setModified(false);
     setSelectedProfileId (sel.value)
     setSaveError ("");

  }

const handleTimeChange = (e)=>{

  let prof = {...EditProfile};

  if (e.target.name == "start")
    prof.StartHour = parseInt( e.target.value);
  else 
    prof.EndHour   = parseInt( e.target.value);


    prof = RecalculatePercentages(prof);  

  setEditProfile(prof);  
  setModified(true);


}


const onSave = (newProfile, error, response)=>{
  if (error){
    setSaveError (error);
    return;
  }
  setSaveError ("");

  var profListCopy = [];

  let len = ProfilesList.length;
  for (var i=0; i<len; i++){
    profListCopy.push ({...ProfilesList[i]});
  }

  if (newProfile ){

    if (response)
      EditProfile.altID = response[0].altID;
    profListCopy.push ({...EditProfile});
    
  }else{


    let i = profListCopy.findIndex(r=>r.ProfileId == EditProfile.ProfileId);
  
    profListCopy[i] = {...EditProfile};

  }
  setProfilesList(profListCopy);
  setSelectedProfileId(null);
  setEditProfile (EmptyProfile);
  setModified(false);






}

const RecalculatePercentages = (prof) =>{
  let sum = 0;
  let cnt = 0;
    for (var i = prof.StartHour; i<=prof.EndHour; i++){
      sum+=prof["H"+String(i).padStart(2, '0')];
      cnt++;
    }

 
  
  
    for (var i = 0; i<24; i++){
      let field =  "H"+String(i).padStart(2, '0') ;

      if (i>= prof.StartHour && i<= prof.EndHour){
        if (sum > 0)
          prof[field] = 100*prof[field]/sum;
        else {
          prof[field] = 100/cnt;


        }

      }else 
        prof[field] = 0;  
    }

    return prof;

}

const ColMouseEvent = (idx,  fill) => {


    var prof = {...EditProfile};
    prof["H"+String(idx).padStart(2, '0')] = fill;

    prof = RecalculatePercentages(prof);

    setEditProfile(prof);
    setModified(true);

    //recalculate



}

const IdxName = (idx) => {
    return ("H"+ String(idx).padStart(2, '0'));
}
const handleStraightLineDist = ()=>{


    let prof = {...EditProfile}
    let startH = prof.StartHour; 
    let endH = prof.EndHour;
    let startV = prof [IdxName(startH)];
    let endV = prof [IdxName(endH)];

    if (startH > endH){
        return;
    }

    if (startV == endV){
      return handleEvenDist();
    }

     let dY = (endV-startV)/(endH-startH);

     for(var i = startH+1; i<endH; i++){
         prof[IdxName(i)] = startV + (i-startH)*dY; 
     }


     prof = RecalculatePercentages (prof);
     setEditProfile(prof);
     setModified(true);



}




const handleEvenDist = ()=>{

    let prof = {...EditProfile};

    for (var i = prof.StartHour ; i <= prof.EndHour; i++){
 
      prof ["H"+ String(i).padStart(2, '0')] = 10;
    }
    prof = RecalculatePercentages (prof);

    setEditProfile (prof);
    setModified(true);
}




const handleEditName = (e)=>{
    let prof = {...EditProfile};
    prof.ProfileId = e.target.value;
    setSelectedProfileId (null);
    setEditProfile (prof);
    setModified (true);
}

const handlEditDescription = (e)=>{
  let prof = {...EditProfile};
  prof.Description = e.target.value;

  setEditProfile (prof);
  setModified (true);
}


const handleCreateNew = () => {
    let prof = {...EmptyProfile};

    prof ["new"] = true;
    setSelectedProfileId (null);
    setEditProfile (prof);
    setSaveError ("");
    setModified(false);
}

const handleSave = () =>{


  
  if (EditProfile.new)
    InsertProfile();
  else
    UpdateProfile();


}


let MaxColHeight = 250;




let FullColumnValue = findFullColValue (EditProfile);

let TheCols = lst24.map ((obj, index) => { 

  return <ProfileColumn   
        MaxHeight={MaxColHeight}  
        FullColumn = {FullColumnValue}
        Enabled = { ((index <= EditProfile.EndHour) && (index >= EditProfile.StartHour )) }
        idx={index}  
        MouseUp={ColMouseEvent}  
        Percent={ EditProfile["H"+ String(index).padStart(2, '0')]}/>


});





let SaveDisabled = false;
let EditNamePlaceholder = "";

if ( !Modified || (EditProfile.ProfileId == null || EditProfile.ProfileId==""  || EditProfile.ProfileId.trim() == "")){
    SaveDisabled = true;
    if (EditProfile.new)
      EditNamePlaceholder = "***Must enter unique name!***"

} 


let SaveErrorEntry = "";

if (SaveError && SaveError != ""){
    SaveErrorEntry = <div style = {{color:'red'}}>{SaveError}</div>
}



return <div  className="prof-wrap">

{IsLoading && <Loading />}


<Form>

<Form.Group as={Row}>
    
<Form.Label    column sm="2">Profile:</Form.Label>

<Col sm = {"10"}>
<Select 

block
options={ProfileSelectList} 
value={ (!SelectedProfileId?null: {label:SelectedProfileId, value: SelectedProfileId })} 
onChange= {handleSelectedProfile } 
placeholder = {"Select ..."}
/>




</Col>
</Form.Group>


<Form.Group>

<Button variant="outline-secondary" type="Button"   block onClick = {handleCreateNew} >
                Create new profile
    </Button>

    
    </Form.Group>

    <Form.Group >
              <Form.Label>Profile name: </Form.Label>
              <Form.Control type="text"  placeholder={EditNamePlaceholder} style={{width:"100%"}} value={EditProfile.ProfileId} onChange={handleEditName}  name="name" autoComplete="off"  disabled={!(EditProfile.new)} />
            </Form.Group>

            <Form.Group >
                Description:
                <Form.Control as="textarea"   rows={1}  value= {EditProfile.Description} onChange = {handlEditDescription}   block="true"  />

              </Form.Group>





<Form.Group as={Row} >

<Form.Label      column sm="3"  >First feed hour: </Form.Label>

<Form.Control
column sm="3" 

          as="select"
          custom
          value={EditProfile.StartHour.toString()}
          name="start"
          onChange={handleTimeChange}
          className = "prof-select-time"
        >
          <option value="0">00:00</option>
          <option value="1">01:00</option>
          <option value="2">02:00</option>
          <option value="3">03:00</option>
          <option value="4">04:00</option>   
          <option value="5">05:00</option>        
          <option value="6">06:00</option>        
          <option value="7">07:00</option>        
          <option value="8">08:00</option>        
          <option value="9">09:00</option>        
          <option value="10">10:00</option>        
          <option value="11">11:00</option>        
          <option value="12">12:00</option>        
          <option value="13">13:00</option>        
          <option value="14">14:00</option>        
          <option value="15">15:00</option>        
          <option value="16">16:00</option>        
          <option value="17">17:00</option>        
          <option value="18">18:00</option>        
          <option value="19">19:00</option>        
          <option value="20">20:00</option>        
          <option value="21">21:00</option>        
          <option value="22">22:00</option>        
          <option value="23">23:00</option>        
            
                 
  
        </Form.Control>


<Form.Label   column sm="3"  >Last feed hour: </Form.Label>
<Form.Control
column sm="3" 
          as="select"
          custom
          value={EditProfile.EndHour.toString()}
          name="end"
          onChange={handleTimeChange}
          className = "prof-select-time"
        >
          <option value="0">00:00</option>
          <option value="1">01:00</option>
          <option value="2">02:00</option>
          <option value="3">03:00</option>
          <option value="4">04:00</option>   
          <option value="5">05:00</option>        
          <option value="6">06:00</option>        
          <option value="7">07:00</option>        
          <option value="8">08:00</option>        
          <option value="9">09:00</option>        
          <option value="10">10:00</option>        
          <option value="11">11:00</option>        
          <option value="12">12:00</option>        
          <option value="13">13:00</option>        
          <option value="14">14:00</option>        
          <option value="15">15:00</option>        
          <option value="16">16:00</option>        
          <option value="17">17:00</option>        
          <option value="18">18:00</option>        
          <option value="19">19:00</option>        
          <option value="20">20:00</option>        
          <option value="21">21:00</option>        
          <option value="22">22:00</option>        
          <option value="23">23:00</option>        
            
                 
  
        </Form.Control>
        </Form.Group>






        <Form.Group as={Row} >
<div className = "prof-columns">
{TheCols}
</div>
</Form.Group>
<Form.Group>
  
<Button variant="outline-secondary" type="Button" name = "even" block onClick = {handleEvenDist } >
                Create even distribution
    </Button>

    <Button variant="outline-secondary" type="Button" name = "line"  block onClick = {handleStraightLineDist } >
            Create straight line distribution from start to end
    </Button>
{SaveErrorEntry}
    <Button variant="outline-secondary" type="Button" name = "line"  block onClick = {handleSave }  disabled = {SaveDisabled} >
            Save
    </Button>

    
    </Form.Group>

</Form>



</div>


}