import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";










import aws_exports from "../../aws-exports";



Amplify.configure(aws_exports, {});




let MouseIsDown = false;



export default function ProfileColumn(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);
 



 
 const [DynHeight, setDynHeight] = useState(0);







useEffect(() => {
      if (props.Enabled)
        setDynHeight (props.MaxHeight*props.Percent/props.FullColumn );
      else
        setDynHeight (0);

  }, [props]);




 




const handleMouseEvent = (e) => {
  if (!props.Enabled)
    return;
  if (e.type === "mousedown") {
         MouseIsDown = true;
         setDynHeight ((props.MaxHeight-e.nativeEvent.offsetY));
        
     } else {
         if (MouseIsDown){
            MouseIsDown = false;
            var y = e.nativeEvent.offsetY;
            if (y<0)
              y=0;
            if (y>props.MaxHeight)
              y=props.MaxHeight;  

            props.MouseUp (props.idx, (props.MaxHeight-y)/props.MaxHeight*props.FullColumn)
         }
     }
 }

 const handleMouseMove = (e) => {

    if (MouseIsDown && props.Enabled){
        setDynHeight ((props.MaxHeight-e.nativeEvent.offsetY));

    }
 
 }

 


let dynStyle = {height:   (props.MaxHeight-DynHeight)+"px"};



let Percent = props.Percent.toFixed(1);

if (props.Percent > 9.5)
    Percent = props.Percent.toFixed(0);

 if (!props.Enabled)
    Percent = 0;   

    
  var colClasses = "prof-col-dyno "+ (props.Enabled? "bgc-lightgray": "bgc-white");


  



return  <div className = "prof-colitem-wrap" >
   <div className = "prof-col-text">{Percent}</div> 
<div className="prof-col-fixed"  style={{height: (props.MaxHeight+"px")}} onMouseDown= {handleMouseEvent} onMouseUp= {handleMouseEvent}  onMouseLeave={handleMouseEvent}   onMouseMove = {handleMouseMove}  >

  <div  className={colClasses}  style={dynStyle}></div>


</div>
<div className = "prof-col-text">{props.idx}</div> 
</div>


}