import React, { useState, useEffect, useRef } from "react";
import  Select from "react-select";

import Amplify, { graphqlOperation, API } from "aws-amplify";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ThemeContextProvider from './contexts/ThemeCtxt';
import TableView from "./TableView";
import CageView from "./CageView";
import Status from "./Status";

import Settings from "./Settings/Settings";
import Reports from "./Reports/Reports";
import Actions from "./Actions/Actions";
import { Loading } from "../Components/Loading";

import moment from "moment";


import "./Feeding.css";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from 'react-bootstrap';

import { listUsers, ListSimpleOrg } from "../customGQL/customQueries";


//import Users from "./Users";
//import Organisations from "./Organisations";
//import Farms from "./Farms";
//import LicenceGenerator from "./LicenceGenerator";
//import Populations from "./Populations";
//import Counter from "./Counter";
import aws_exports from "../aws-exports";


Amplify.configure(aws_exports, {});






let StatusTimer = null;





export default function Feeding(props) {

  const refFeed = useRef(null);

  const [FarmList, setFarmList] = useState([]);
  const [FarmSelected, setFarmSelected] = useState(null);
 
  const [ShowFarmSelect, setShowFarmSelect] = useState(false);
  const [ShowNoFeedFarmsMsg, setShowNoFeedFarmsMsg] = useState(false);
  const [IsLoading, setLoading] = useState(true);
  const [ErrorToShow, setErrorToShow] = useState("");

  const [FeedSystems, setFeedSystems] = useState([]);

  const [Preferences, setPreferences] = useState (null);

  const [DashModified, setDashModified ] = useState (0);

  const [SelectedTab, setSelectedTab ]= useState("");



  const UpdateDash = () =>{

      setTimeout( ()=>{
      let tmp =  DashModified +1;
      setDashModified(tmp);
      }, 1000
      );
  };
 






  useEffect(() => {

    loadData();

    setSelectedTab ("cages") //was tableview
   
     fetchStatus ();


    StatusTimer = setInterval (()=>{

      if (!document.hidden)
          fetchStatus();
 
    }, 30000);
  
   return () => { 
     
     if (StatusTimer){clearInterval(StatusTimer)}  }; 



  }, []);




  const fetchStatus = () => {

    


  }


  const onCognitoTest = () => {

    let apiName = "vakicloudRdsAPI";
    let path = "/counters/cognito/createuser";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL

        telephoneNumber: "+3548997159",
        confirmationCode: "1234",
        password: "passpass"

      }
    };

    API.get(apiName, path, myInit)
      .then(response => {

        console.log ("response: "+JSON.stringify (response,1,1))




      })
      .catch((err) => {
        console.log("GOT farmlisterror: " + err)
 
      })




  }





  const fetchFarmList = () => {


    let orgList = [];

    if (props.userData.admin) {
      orgList.push(10001);
      orgList.push(6);
    } else {
      let len = props.userData.organisations.items.length;
      for (var i = 0; i < len; i++)
        orgList.push(props.userData.organisations.items[i].organisation.orgID)
    }


    if (orgList.length == 0) {
      setShowNoFeedFarmsMsg(true);
      return;
    }





    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/farmlist";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL

        orgList: JSON.stringify({ items: orgList })

      }
    };

    API.get(apiName, path, myInit)
      .then(response => {


        if (response && response.length > 1) {
          let farmItems = [];
          response.map(farm =>
            farmItems.push({
              label: farm.FarmName,
              value: farm.FarmID
            })
          );
          setFarmList(farmItems);
          setShowFarmSelect(true);
          setFarmSelected(null);
        } else if (response && response.length === 1) {
          setFarmSelected({ label: response[0].FarmName, value: response[0].FarmID });
          setShowFarmSelect(false);
          setFarmList([]);
        } else {
          setShowNoFeedFarmsMsg(true);
          setFarmSelected(null);
          setShowFarmSelect(false);
        }

        setLoading (false)


      })
      .catch((err) => {
        console.log("GOT farmlisterror: " + err)
        setErrorToShow("Error retrieving farm info: " + err)
        setFarmList([]);
        setLoading (false)
      })

  };



  const onFarmSelect = (dd) => {




    setFarmSelected(dd);

    if (dd && dd.value)
      fetchFeedSystems (dd.value)
 


  }



const UpdateSystem = (SysIdx, EdRoundTime,  EdRoundsHour,  EdNumPipes,  EdEncoderSiz) =>{


  let sys = [];

  for (var i = 0; i<FeedSystems.length; i++)
    sys.push ( { ...FeedSystems[SysIdx]});
  

  sys[SysIdx].RoundTime = EdRoundTime;
  sys[SysIdx].RoundsPrHour = EdRoundsHour;
  sys[SysIdx].NoOfPipes= EdNumPipes;
  sys[SysIdx].EdEncoderSize = EdEncoderSiz;
  sys[SysIdx]["key"] = SysIdx;

  setFeedSystems (sys);

}


const fetchFeedSystems = (FarmID ) => {

  setLoading (true);
  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/feedsystems";
  let myInit = {

    // OPTIONAL
    headers: {}, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL

      FarmID: FarmID

    }
  };

  API.get(apiName, path, myInit)
    .then(response => {
 

 
      setFeedSystems(response.Sys);
      setPreferences (response.Pref[0]);

      setLoading (false)


    })
    .catch((err) => {
      console.log("GOT feedsyserr: " + err)
      setFeedSystems([]);
      setPreferences (null);
      setLoading (false)
    })

};


const update = (name, obj) => {

  if (name.toUpperCase() == "PREFERENCES"){
  


     setPreferences (obj);
  }



}


const handleTabClick = (ek)=>{
 
  setSelectedTab(ek);


}


  const loadData = async () => { fetchFarmList() }
  let TheFeedSys = "";
  let ErrorMsg = "";

  let TheFarmSelect = "";
  let TheNoFarmMsg = "";



  if (ShowNoFeedFarmsMsg) {

    let str = "User '" + props.userData.name + "' doesn't have access to any feeding system"
    TheNoFarmMsg = <div className="feed-ErrorMSG" > {str} </div>
  }



  if (ShowFarmSelect) {
    TheFarmSelect = (
      <div className ="feed-farmselectwrap">
     
        <Select
        caption = {"ertert"}
          options={FarmList}
          className={"feed-farm-select "}
          value={FarmSelected}
          onChange={onFarmSelect}
          ignoreAccents={false} // Má fjarlægja
          placeholder = {"Select farm..."}
        />
       
      </div>
    );
  }

  


  let TheStatus = "";
  if (FarmSelected) {



    TheFeedSys = (<div className="feed-farmselect-wrap"   >
      <Tabs defaultActiveKey="cages" id="uncontrolled-tab-example"  onSelect = {handleTabClick}>

      <Tab eventKey="cages" title="Cages"       mountOnEnter={true}
          unmountOnExit={true} >
          
          <CageView  TabSelected = {SelectedTab == "cages"} userData={props.userData} FarmID={FarmSelected.value} FeedSystems = {FeedSystems}  DashModified = {DashModified} UpdateDash={UpdateDash}  Preferences = {Preferences}/>
        </Tab>


        <Tab eventKey="tableview" title="Table View"      mountOnEnter={true}
          unmountOnExit={true} >
          
          <TableView  TabSelected = {SelectedTab == "tableview"} userData={props.userData} FarmID={FarmSelected.value} FeedSystems = {FeedSystems}  DashModified = {DashModified} UpdateDash={UpdateDash} />
        </Tab>



        <Tab eventKey="actions" title="Actions">
          <Actions  TabSelected = {SelectedTab == "actions"}  userData={props.userData} FarmID={FarmSelected.value}  FeedSystems = {FeedSystems}  UpdateSystem={UpdateSystem}  UpdateDash={UpdateDash}  DashModified = {DashModified}  />
        </Tab> 
        <Tab eventKey="reports" title="Reports" >
          <Reports TabSelected = {SelectedTab == "reports"} userData={props.userData} FarmID={FarmSelected.value}  FeedSystems = {FeedSystems}  UpdateSystem={UpdateSystem}  UpdateDash={UpdateDash} />
        </Tab>


         {   (props.userData.admin || ((props.userData.access & 2)==2))  &&  <Tab eventKey="settings" title="Settings"    > 

          <Settings TabSelected = {SelectedTab == "settings"} userData={props.userData} SysIdx = {0} FarmID={FarmSelected.value}  FeedSystems = {FeedSystems}  UpdateSystem={UpdateSystem}  UpdateDash={UpdateDash}  RefFeed={refFeed}   Preferences = {Preferences}  Update = {update} />
        </Tab>}

      </Tabs>
      </div>
    );

  
    TheStatus = <Status  TheFarm={FarmSelected.value} />

  }




  if (ErrorToShow.length > 0) {
    ErrorMsg = <div className="feed-ErrorMSG" > {ErrorToShow} </div>
  }


/*
  <Button variant="outline-secondary" type="Button" onClick={onCognitoTest}  >
  Cognito Test
</Button>
*/

  return (
  

      <div id="FeedWrap" ref = {refFeed} className="feed-container default-margin">
        <div className="feed-status-strip-wrap">

      
        <div className="page-main-text gray-text feed-title">
          <i className="fa fa-gears" /> Feeding
      </div>
      {IsLoading && <Loading />}
        {!IsLoading && TheFarmSelect}
        
        {TheStatus}
        </div>
        {TheNoFarmMsg}
        {ErrorMsg}
        {!IsLoading && TheFeedSys}




      </div>

  );
}
