import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker"
import {v4} from "uuid";

import { Loading } from "../Components/Loading";
import "react-datepicker/dist/react-datepicker.css";
import Amplify, {  API } from "aws-amplify";
import moment from "moment"
import ShareList from "./ShareList"
import aws_exports from "../aws-exports";

Amplify.configure(aws_exports, {});



require ("./ShareEdit.css")

require ("./Counters.css")





export default function ShareEdit (props) {

  const [startDate, setStartDate] = useState (null);
  const [endDate, setEndDate] = useState (null);
  const [expireDate, setExpireDate] = useState (null);
  const [selectedChecked, setSelectedChecked] = useState(false);
  const [selectedCount, setSelectedCount] = useState (null);
  const [description, setDescription] = useState ("");
  const [link, setLink] = useState (null);
  const [showList, setShowList] = useState(false);
  const [createCount, setCreateCount ] = useState (0);

    useEffect(() => {
        if (props.Visible){
        setLink(null);
        setExpireDate (null);
        setStartDate(null);
        setEndDate(null);
        setDescription ("")
        
        if (props.SelectedCount){
            setSelectedCount (props.SelectedCount);
            setSelectedChecked (true);
           
        }else{
            setSelectedCount (null);
            setSelectedChecked (false);

        }
    }else {
        setShowList (false);
    }
    
    
   }, [props.SelectedCount, props.Visible]);


   const handleCheckbox = (e) => {
    
    setSelectedChecked (e.target.checked)
   }


   let seClass = "SEareaInvisible";
   if (props.Visible)
    seClass = "SEarea";







const doSave = () => {

    let apiName = "vakicloudRdsAPI";
    let path = "/counters/share";


    let repId = null;





    let guid = v4();

    let dateNow = new Date();
    if (description.length > 255)
       description = description.substring (0,255)
 
//let newTime = moment(date).format("DD/MM/YYYY HH:mm");
    let myInit = {
      // OPTIONAL
      headers: {
       
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
 
      },
      

     

      body: {
        Organisation_ID: props.Organisation_ID,
        Counter_ID: props.Counter_ID,
        Report_ID: (selectedChecked? selectedCount.Report_ID : null),
        guid:guid,
        startDate: ( startDate==null?null:moment(startDate).format ("yyyy-MM-DD HH:mm:ss")),
        endDate: (endDate==null?null:moment(endDate).format ("yyyy-MM-DD HH:mm:ss")),
        expireDate: (expireDate==null?null:moment(expireDate).format ("yyyy-MM-DD HH:mm:ss")),
        disabled: false,
        Created: moment(dateNow).format ("yyyy-MM-DD HH:mm:ss"),
        Creator: props.userData.name,
        Description: description,
        newShare: true 
    
      }
      
    };
    
    API.post(apiName, path, myInit)
    .then ((response) => {
   
     
  
    let text = "https://dntracko6h8s9.cloudfront.net?A="+guid;
    setLink (text);

    setCreateCount (createCount+1);
    


  
    })
    .catch ((er) => {
  
      
        console.log ("catch: "+er)
  
    })
  
}

const saveDisabled = () => {

let dt = new Date();


    if (link != null && link.length > 0)
        return true;
 
    if (expireDate!= null && expireDate <= dt)
        return true;
    
    if (selectedChecked)
        return false;

    if (startDate == null)
        return true;    

  
        if (endDate == null)
            return false;
        if (endDate <= startDate)    
            return true;
    
        
        
    return false;
}


const copyLink = () => {

navigator.clipboard.writeText(link);
}

let disableSave = saveDisabled();

let TheLink = "";

if (link != null)
    TheLink = <div className="shareLink">
        <i>Copy link and send it to recipient:</i>
                <div>{link}<span onClick={copyLink}  className="copyLink">
                <i className="fa fa-copy fa-regular" title="Copy link"></i></span>
                </div>
            </div>;


let TheShareList = "";

if (showList)
    TheShareList = <div><hr/> <ShareList Counter_ID = {props.Counter_ID}  NewItems={createCount} Organisation_ID = {props.Organisation_ID} /> </div>;


/*  DatePicker examples: 
https://reactdatepicker.com/
*/


return <div id = "SEwrap">
<div className = {seClass}>
<div id="share-text"> Either share the selected count report or all counts during an indicated time period </div>
<Row>
    <Col>
    Selected count: {"   "} 
<input
              className="activeInput"
              name="activated"
              type="checkbox"
              checked={selectedChecked}
              onChange={handleCheckbox}
              disabled = {!selectedCount || link != null}
            ></input>
</Col>


    <Col>
    Start date:
        <DatePicker
          selected={startDate}
          onChange={(d)=>setStartDate (d)}
          showTimeSelect
          
          dateFormat = "yyyy-MM-dd HH:mm"
          disabled = {selectedChecked || link != null}
        />
 </Col>
 <Col>
End date (optional):
<DatePicker
          selected={endDate}
          onChange={(d)=>setEndDate (d)}
          showTimeSelect
          disabled = {selectedChecked || link!= null}
          dateFormat = "yyyy-MM-dd HH:mm"
        />
         </Col>

<Col>
         Share expire (optional):
<DatePicker
          selected={expireDate}
          onChange={(d)=>setExpireDate (d)}
          
         disabled = {link != null}
          dateFormat = "yyyy-MM-dd HH:mm"
        />
         </Col>        
</Row>


<div id = "divDesc"   className="mt-4">
Description (optional)
            <InputGroup className="mb-3">
                
        <Form.Control
          placeholder="Enter text here"
          aria-label="Description"
          aria-describedby="basic-addon1"
          disabled = {link != null}
          onChange={(e)=>setDescription (e.target.value)}
        />
      </InputGroup>
</div>
<div id="divSave">
<Button variant="outline-secondary" type="Button" block   onClick = {doSave}  disabled = {disableSave} >
    Save and create sharing link
    </Button>





</div>

    {TheLink}   
    <hr/>
    <Button variant="outline-secondary" type="Button"  onClick = {()=>setShowList (!showList)}   >
          Sharings list
    </Button>
    
    {TheShareList}     
    </div>

</div>


}