import React, { useState, useEffect } from "react";
//import { default as Select } from "react-select";

import Amplify, {  API } from "aws-amplify";

import "./Settings.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";
import { isNullableType } from "graphql";



Amplify.configure(aws_exports, {});



 
let ModifyDesc = "";  

const AddModifyDesc = (strDesc)=>{
  if (ModifyDesc.length >= 120)
      return;

    if (ModifyDesc.length > 0 )
      ModifyDesc+= ";";

    ModifyDesc += strDesc;

    if (ModifyDesc.length >= 120)
      ModifyDesc = ModifyDesc.substr(0,120);

}




   let eventKey = null; 
export default function PipesEncoder(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);
 let rArr = new Array(props.FeedSystems.length).fill(false);
 rArr[0] = true;
 const [IsLoading, setLoading] = useState(true);

 const [Pipes, setPipes] = useState([]);

 const [EdPipes, setEdPipes] = useState([]);
const [AllCages, setAllCages] = useState([]);
const [SysIdx, setSysIdx] = useState(0)
const [EdRadioSysIdx, setEdRadioSysIdx] = useState(rArr)

const [SaveMessage, setSaveMessage] = useState ("");

const [EdRoundTime, setEdRoundTime] = useState (30);
const [EdRoundsHour, setEdRoundsHour] = useState (6);

const [EdNumPipes, setEdNumPipes] = useState (36);
const [EdEncoderSiz, setEdEncoderSiz] = useState (4096);

const [EdEdNumPipes, setEdEdNumPipes] = useState (36);
const [EdEdEncoderSiz, setEdEdEncoderSiz] = useState (4096);

const [EdOffset, setEdOffset] = useState (0);


const [IsModified, setIsModified] = useState (false);

const [EnableApplyOffset, setEnableApplyOffset] = useState (false);

const [EnableApplyEncodPipes, setEnableApplyEncodPipes] = useState (false);

 useEffect(() => {

   setFields (SysIdx);

  loadData();
  ModifyDesc = "";
}, [props.FarmID, SysIdx]);


const loadData = async () => {


  fetchPipesCageData()


}



  const fetchPipesCageData = () => {
    setSaveMessage("");


    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/pipescages";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        SysIdx: SysIdx  //ATH  for farms with more feedingsystems


      }
    };


    setLoading(true);

    API.get(apiName, path, myInit).then(response => {



    
  
      
      setPipes(response.Pipes);
     
      let len = response.Pipes.length;
      let tmp = [];
      for (let i = 0; i< len; i++){
        tmp.push ({...response.Pipes[i]})


      }
  
      setEdPipes (tmp);


      


       let cageOptions = response.Cages.map ((pidObj, index) => { return{label: pidObj.Name, value: pidObj.CageId}});

       cageOptions.splice (0,0,{label: null, value: null})
       setAllCages (cageOptions);
       
 
    


      setLoading(false);
      setIsModified (false);



    })
      .catch((err) => {

        setSaveMessage ("Error loading: "+JSON.stringify (err.message))
        setLoading(false);

      })

  };










 let data = [ {Pipe:7, Cage:"H01"},  {Pipe:2, Cage:null}, {Pipe:5, Cage:"H03"}   ]


const handleCageSelect = (e)=>{

  let pipeIdx = e.target.parentNode.parentNode.rowIndex-1;
  let tmpPipes  = EdPipes.map ((pc) =>{return {...pc}});
  let oldCage = tmpPipes[pipeIdx].CageId;
  tmpPipes[pipeIdx].CageId =  AllCages[e.target.selectedIndex].value;
  tmpPipes[pipeIdx].Name = AllCages[e.target.selectedIndex].label;
  if (AllCages[e.target.selectedIndex].value == null)
    tmpPipes[pipeIdx].SecOfBlow = null;
  else if (!oldCage && !tmpPipes[pipeIdx].SecOfBlow)
    tmpPipes[pipeIdx].SecOfBlow = <div style={{color:"red"}}>***Enter number!*** </div>

    setIsModified (true);
  setEdPipes (tmpPipes);
  setSaveMessage ("");

}




const handleEditBlow =  (e)=>{

  let pipeIdx = e.target.parentNode.parentNode.rowIndex-1;
  let tmpPipes  = EdPipes.map ((pc) =>{return {...pc}});

  tmpPipes[pipeIdx].SecOfBlow =  parseInt ( e.target.value);

  setIsModified(true);
  setEdPipes (tmpPipes);
  setSaveMessage ("");
}


const handleEditEncoder =  (e)=>{

    let pipeIdx = e.target.parentNode.parentNode.rowIndex-1;
    let tmpPipes  = EdPipes.map ((pc) =>{return {...pc}});
  
    tmpPipes[pipeIdx].Location =  parseInt ( e.target.value);
  
    setIsModified(true);
    setEdPipes (tmpPipes);
    setSaveMessage ("");
  }


 const handleEditDescription=  (e)=>{

    let pipeIdx = e.target.parentNode.parentNode.rowIndex-1;
    let tmpPipes  = EdPipes.map ((pc) =>{return {...pc}});
  
    tmpPipes[pipeIdx].Description =   e.target.value;
  
    setIsModified(true);
    setEdPipes (tmpPipes);
    setSaveMessage ("");
  }




 let Columns = [{
    dataField: 'PipeId',
    text: 'Pipe',
    name: "pipe",
    editable:false,
    //style: cellCagesFormatter,
    
    sort: true

  },
{
  dataField: 'Description',
  text: 'Description',
  name: "description",
  editable:true
  //style: cellCagesFormatter,
  
    /*
  editor: {
    type: "text",
   
    onChange: handleEditDescription
    
  }
  */
/*
 formatter: (cell) => {
   if (cell == "null")
      return "";
       else
  return cell;}
*/
},


 {
    dataField: 'SecOfBlow',
    text: 'Time to empty (sec)',
    name: "blow",
    editable: false


/*  
    editor: {
      type: "number",
      onChange: handleEditBlow
      
    },
 */   
  },

  {
    dataField: 'Location',
    text: 'Encoder position',
    name: "encoder",
    sort:true,


  
    editor: {
      type: "number",
      onChange: handleEditEncoder
      
    }
    
  },



  {
    dataField: 'Name',
    text: 'Cage',
    name: "cage",
    sort: true,
    editable: false
    /*
    editable: true,
    editor: {
      type: "select",
      options: AllCages,
      onChange:  handleCageSelect
    },
    */
 
  }
 ];

 
 const isSaveDisabled = ()=>{

   if (!IsModified)
    return true;
  let el = EdPipes.find ((el) => { 
    return (el.CageId && el.CageId.length>0 && (isNaN(el.SecOfBlow) || el.SecOfBlow<=0 || !el.SecOfBlow));

  } );   

  return (el)

 }


const setFields = (idx)=>{
    setEdRoundTime (props.FeedSystems[idx].RoundTime);
    setEdRoundsHour (props.FeedSystems[idx].RoundsPrHour);
    setEdNumPipes (props.FeedSystems[idx].NoOfPipes);
    setEdEncoderSiz(props.FeedSystems[idx].EncoderSize);
    setEdEdNumPipes (props.FeedSystems[idx].NoOfPipes);
    setEdEdEncoderSiz(props.FeedSystems[idx].EncoderSize);  
     setEdOffset(0);


}

 const handleRadioSys = (e) => {

  let theOne = 0;
  var len = props.FeedSystems.length;
  var ll = new Array(len);
  for (var i=0; i<len; i++){
    if (i == parseInt (e.target.name) ){
      theOne = i;
      ll[i] = e.target.checked
    }else
      ll[i] = !e.target.checked;

  }
  setEdRadioSysIdx (ll);
  setSysIdx (theOne);
  setFields (theOne);
  ModifyDesc = "";




}

const handleApplyOffset =()=>{

    let tmpPipes = [];

    for (var i = 0; i< EdPipes.length; i++)
        tmpPipes.push ({...EdPipes[i]});

    for  (var i = 0; i< EdPipes.length; i++){
        tmpPipes[i].Location += EdOffset;
        if (tmpPipes[i].Location > EdEncoderSiz)
            tmpPipes[i].Location -= EdEncoderSiz;
        else  if (tmpPipes[i].Location < 0)
            tmpPipes[i].Location += EdEncoderSiz;
    }   

    setEdPipes (tmpPipes);
    setEdOffset (0);
    setEnableApplyOffset(false);
    setIsModified(true);
}

const  handleApplyEncodPipesChange = () => {

    if (EdEdNumPipes <= 0 || EdEdEncoderSiz <= 0)
        return;

    let len = EdPipes.length;
    
    let newPipes = false;
    if (len != EdEdNumPipes){
        newPipes = true;
        len = EdEdNumPipes;
    }
        
    let incr = EdEdEncoderSiz/EdEdNumPipes;

    //SELECT tblPipes.PipeId, tblPipes.Location, tblPipesCage.SecOfBlow, tblPipesCage.CageId, tblCages.Name


    let tmpPipes = [];

    if (newPipes){
        for (var i=0; i<len; i++)
            tmpPipes.push({
                PipeId: i+1, 
                Location: Math.round (i*incr), 
                SecOfBlow:0, 
                CageId:null,
                Name:null,
                Description: ""
            });
    }else {
        for (var i = 0; i< len; i++)
            tmpPipes.push ({...EdPipes[i]});
    
        if (tmpPipes[0].Location > 0 || tmpPipes[0].Location > EdEdEncoderSiz)
            tmpPipes[0].Location = 0;    
        let offset = tmpPipes[0].Location;    
        for  (var i = 1; i< EdPipes.length; i++){
            offset += incr;
            if (offset > EdEdEncoderSiz)
                offset -= EdEdEncoderSiz;
            tmpPipes[i].Location = Math.round(offset);
         } 
    }  
    setEdPipes (tmpPipes);
    setEdEncoderSiz (EdEdEncoderSiz);
    setEdNumPipes (EdEdNumPipes);
    setEnableApplyEncodPipes(false);
    setIsModified(true);

}




const handleClose = ()=>{
  props.HideMe();
}

const handleSave = ()=>{
    //fyrst check if system has been modified

   
    let sys = props.FeedSystems[SysIdx];

    let sysModified = (sys.RoundTime != EdRoundTime ||  sys.RoundsPrHour != EdRoundsHour || sys.NoOfPipes != EdNumPipes || sys.EncoderSize != EdEncoderSiz);

    let resetAllPipes = ( sys.NoOfPipes != EdNumPipes);

  
    let sysValues = "";
    if (sysModified){
        sysValues = " RoundTime = "+EdRoundTime+",  RoundsPrHour = "+EdRoundsHour+", NoOfPipes = "+EdNumPipes+", EncoderSize = "+EdEncoderSiz +" ";

      if (sys.NoOfPipes != EdNumPipes)  
        AddModifyDesc("NP"+EdEdNumPipes);  

      if (sys.EncoderSize != EdEncoderSiz)  
        AddModifyDesc( "ES"+EdEncoderSiz);          

      if (sys.RoundsPrHour != EdRoundsHour)  
        AddModifyDesc("RPH"+EdRoundsHour);  

      if (sys.RoundTime != EdRoundTime)  
        AddModifyDesc("RT"+EdRoundTime)  


    }

//tblPipes.PipeId, tblPipes.Location, tblPipesCage.SecOfBlow, tblPipesCage.CageId, tblCages.Name, tblPipes.Description



  let fields = "(FarmID, SettingsId, PipeId, Description, Location) ";

  let len = EdPipes.length;
  let cnt = 0;
  let values = "";
  let pipeIdList = "(";
  let cntUC = 0;




  for (var i = 0; i< len; i++){
  
        if (cnt > 0){
          values += ", ";
          pipeIdList += ", ";
        }
        pipeIdList += EdPipes[i].PipeId;
        values += "("+props.FarmID+", "+SysIdx+", "+EdPipes[i].PipeId+", "+ (EdPipes[i].Description == null?"": "'"+EdPipes[i].Description+"'")+", "+ EdPipes[i].Location+")";
        cnt++;

        //For the log:
        if (Pipes[i].Location != EdPipes[i].Location  || Pipes[i].Description != EdPipes[i].Description){
  
          let str = "p"+Pipes[i].PipeId;
          if (Pipes[i].Location != EdPipes[i].Location ){
            str+="#"+EdPipes[i].Location;
          }
 
          if(Pipes[i].Description != EdPipes[i].Description){
            str+="TXT";
          }
          AddModifyDesc(str);

        }


  }



  pipeIdList+= ")";






  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/savepipes";
  let myInit = {
    // OPTIONAL
    headers: {
     
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      FarmID: props.FarmID,
      SysIdx: SysIdx,
      Reset: resetAllPipes
    },
    body: {

        sysValues: sysValues,
      values: values,
      fields: fields,
      pipeIdList: pipeIdList,
      U:props.userData.name,
      M:ModifyDesc
    }
  };
  setSaveMessage ("");
  API.post(apiName, path, myInit)
  .then ((response) => {
 
      setIsModified (false);
      setPipes (EdPipes);
      setSaveMessage ("Save successful");
        if (sysValues.length > 0){
            props.UpdateSystem (SysIdx, EdRoundTime,  EdRoundsHour,  EdNumPipes,  EdEncoderSiz);
        }

        ModifyDesc = "";
        props.UpdateDash();



  })
  .catch ((er) => {

    setSaveMessage ("Error saving: "+JSON.stringify(er.message));


  })


  
}



  let FeedSysSelect = "";


  if (props.FeedSystems.length >1 ){
      FeedSysSelect = [];
      for (var i = 0; i< props.FeedSystems.length; i++)
        FeedSysSelect.push (
          <div className="ptc-radio" key={i}>
          <ToggleButton
          
          type="radio"
          variant="outline-secondary"
          name = {i.toString()} 
          block
          onChange =  {handleRadioSys}
         
          checked = {EdRadioSysIdx[i]}

        >
          {"("+ (i+1)+ ") "+props.FeedSystems[i].Name}
        </ToggleButton>
        </div>

        );
  }


  let SaveDisabled = isSaveDisabled();

  

 return <div className="penc-wrap">

{IsLoading && <Loading />}

<div className="penc-fields-wrap">
   <div className="penc-fields">
{FeedSysSelect}
<ButtonGroup  className="btn-group d-flex">
              <Button variant="outline-secondary" type="Button"  onClick = {handleClose } >
                Close
    </Button>
               <Button variant="outline-secondary" type="Button"   onClick = {handleSave } disabled={SaveDisabled} >
                Save
    </Button>
    
            </ButtonGroup>

{SaveMessage}
</div>
<div className="penc-fields">

<Form>
            <Form.Group as = {Row}>
              <Form.Label>Round Time: </Form.Label>
              <Form.Control type="number"  placeholder={"Enter number..."}  value={EdRoundTime}   name="roundtime" autoComplete="off" onChange={(e)=>{setEdRoundTime(e.target.value); setIsModified(true);}}    />
            </Form.Group>
            <Form.Group as = {Row}>
              <Form.Label>Rounds/Hour </Form.Label>
              <Form.Control type="number"   placeholder={"Enter number..."}  value={EdRoundsHour}   name="roundtime" autoComplete="off" onChange={(e)=>{setEdRoundsHour(e.target.value); setIsModified(true);}}    />
            </Form.Group>
         </Form>

</div>

<div className="penc-fields">
<Form>
<Form.Group as = {Row}>
              <Form.Label>Number of pipes: </Form.Label>
              <Form.Control type="number"  placeholder={"Enter number..."}  value={EdEdNumPipes} max={36}  name="roundtime" autoComplete="off" onChange={(e)=>{setEnableApplyEncodPipes(true);setEdEdNumPipes(e.target.value)}}    />
            </Form.Group>

            <Form.Group as = {Row}>
              <Form.Label>Encoder Size: </Form.Label>
              <Form.Control type="number"  placeholder={"Enter number..."}  value={EdEdEncoderSiz}   name="roundtime" autoComplete="off" onChange={(e)=>{setEnableApplyEncodPipes(true); setEdEdEncoderSiz(e.target.value)}}    />
            </Form.Group> 
    
            <Button variant="outline-secondary" type="Button"   block onClick = {handleApplyEncodPipesChange } disabled={!EnableApplyEncodPipes} >
                Apply changes
            </Button>
            <div className = "penc-texts">  Changes won't be saved unless they have been applied (and visible in the table below).  If the number of pipes is modified, cage connections and blowtimes are reset</div>
 </Form>  


</div>


<div className="penc-fields">

<Form>
            <Form.Group as = {Row}>
              <Form.Label>Offset: </Form.Label>
              <Form.Control type="number"  placeholder={"Enter number..."}  value={EdOffset}   name="roundtime" autoComplete="off" onChange={(e)=>{setEnableApplyOffset(true);setEdOffset(parseInt(e.target.value))}}    />
            </Form.Group>

            <Form.Group >
              
            <Button variant="outline-secondary" type="Button"   block onClick = {handleApplyOffset } disabled={!EnableApplyOffset} >
                Apply offset
            </Button>
            </Form.Group>
            <div className = "penc-texts">All stop/encoder positions displayed in table below will be moved in accordance to the entered offset when applied.  Change will become permanent when <i>Saved</i> </div>
 </Form>           




</div>
</div>

{!IsLoading && < BootstrapTable
            hover
            keyField='PipeId'
            data={EdPipes}
            noDataIndication="No pipes defined"            
            //data = {[{PipeId:1, key:100},{PipeId:2, key:101} ]}
            columns={Columns}
           /* rowEvents={RowEvents}*/
            cellEdit={ cellEditFactory({ mode: 'click' }) }

          />}

 </div>


}