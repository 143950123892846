import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";
import ProfileColumn from "./ProfileColumn"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";



Amplify.configure(aws_exports, {});


 
let ModifyDesc = "";  //

const AddModifyDesc = (strDesc)=>{
  if (ModifyDesc.length >= 120)
      return;


    if (ModifyDesc.length > 0 )
      ModifyDesc+= ";";

    ModifyDesc += strDesc;

    if (ModifyDesc.length >= 120)
      ModifyDesc = ModifyDesc.substr(0,120);

}






   let eventKey = null; 
export default function PipesToCages(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);
 let rArr = new Array(props.FeedSystems.length).fill(false);
 rArr[0] = true;
 
 const [IsLoading, setLoading] = useState(true);

 const [Pipes, setPipes] = useState([]);

 const [EdPipes, setEdPipes] = useState([]);
const [AllCages, setAllCages] = useState([]);
const [SysIdx, setSysIdx] = useState(0)
const [EdRadioSysIdx, setEdRadioSysIdx] = useState(rArr)
const [IsModified, setIsModified] = useState (false);
const [SaveMessage, setSaveMessage] = useState ("");



 useEffect(() => {

  loadData();
}, [props.FarmID, SysIdx]);


const loadData = async () => {


  fetchPipesCageData()


}

  const fetchPipesCageData = () => {
    setSaveMessage("");


    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/pipescages";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        SysIdx: SysIdx  //ATH  for farms with more feedingsystems


      }
    };


    setLoading(true);

    API.get(apiName, path, myInit).then(response => {



    
  
      
      setPipes(response.Pipes);
     
      setEdPipes (response.Pipes);



       let cageOptions = response.Cages.map ((pidObj, index) => { return{label: pidObj.Name, value: pidObj.CageId}});

       cageOptions.splice (0,0,{label: null, value: null})
       setAllCages (cageOptions);
       
 
    


      setLoading(false);
      setIsModified (false);



    })
      .catch((err) => {

        setSaveMessage ("Error loading: "+JSON.stringify (err.message))
        setLoading(false);

      })

  };










 let data = [ {Pipe:7, Cage:"H01"},  {Pipe:2, Cage:null}, {Pipe:5, Cage:"H03"}   ]


const handleCageSelect = (e)=>{

  let pipeIdx = e.target.parentNode.parentNode.rowIndex-1;
  let tmpPipes  = EdPipes.map ((pc) =>{return {...pc}});
  let oldCage = tmpPipes[pipeIdx].CageId;
  tmpPipes[pipeIdx].CageId =  AllCages[e.target.selectedIndex].value;



  tmpPipes[pipeIdx].Name = AllCages[e.target.selectedIndex].label;
  if (AllCages[e.target.selectedIndex].value == null)
    tmpPipes[pipeIdx].SecOfBlow = null;
  else if (!oldCage && !tmpPipes[pipeIdx].SecOfBlow)
    tmpPipes[pipeIdx].SecOfBlow = <div style={{color:"red"}}>***Enter number!*** </div>

    setIsModified (true);
  setEdPipes (tmpPipes);
  setSaveMessage ("");

}




const handleEditBlow =  (e)=>{

  let pipeIdx = e.target.parentNode.parentNode.rowIndex-1;
  let tmpPipes  = EdPipes.map ((pc) =>{return {...pc}});

  tmpPipes[pipeIdx].SecOfBlow =  parseInt ( e.target.value);

  setIsModified(true);
  setEdPipes (tmpPipes);
  setSaveMessage ("");
}

 let options = [ {value:"H01", label:'H01'},{value:"H02", label:'H02'},{value:"H03", label:'H03'},{value:"H04", label:'H04'}  ]

 let Columns = [{
    dataField: 'PipeId',
    text: 'Pipe',
    name: "pipe",
    editable:false,
    //style: cellCagesFormatter,
    
    sort: true

  },




 {
    dataField: 'SecOfBlow',
    text: 'Blowtime (sec)',
    name: "blow",
 

/*
    formatter: (cell) => {if (isNaN(cell))
                                return 22;
                                     else
                                return cell;},
*/
    //style: cellCagesFormatter,
    /*
    validator: (newValue, row, column) => {
      console.log ("NewVal: "+newValue, +"    row:"+row+"    col: "+column)
      if (isNaN(newValue)) {
        
        return {
          valid: false,
          message: "Price should be numeric"
        };
      }
 
      return true;
    },
    */
    defaultValue:0,
    editor: {
      type: "number",
      onChange: handleEditBlow
      
    },
    
  },



  {
    dataField: 'Name',
    text: 'Cage',
    name: "cage",
    sort: true,
    editable: true,
    editor: {
      type: "select",
      options: AllCages,
      onChange:  handleCageSelect
    },
 
  }
 ];

 
 const isSaveDisabled = ()=>{

   if (!IsModified)
    return true;
  let el = EdPipes.find ((el) => { 
    return (el.CageId && el.CageId.length>0 && (isNaN(el.SecOfBlow) || el.SecOfBlow<=0 || !el.SecOfBlow));

  } );   

  return (el)

 }

 const handleRadioSys = (e) => {

  let theOne = 0;
  var len = props.FeedSystems.length;
  var ll = new Array(len);
  for (var i=0; i<len; i++){
    if (i == parseInt (e.target.name) ){
      theOne = i;
      ll[i] = e.target.checked
    }else
      ll[i] = !e.target.checked;

  }
  setEdRadioSysIdx (ll);
  setSysIdx (theOne);

}

const handleClose = ()=>{
  props.HideMe();
}

const handleSave = ()=>{

  let fields = "(FarmID, SysIdx, PipeId, CageId, SecOfBlow) ";

  let len = EdPipes.length;
  let cnt = 0;
  let values = "";
  let unConnected = "(";
  let cntUC = 0;

  ModifyDesc = "";


  for (var i = 0; i< len; i++){

      if (EdPipes[i].CageId != Pipes[i].CageId   ||  EdPipes[i].SecOfBlow != Pipes[i].SecOfBlow ){
          let str = "p"+Pipes[i].PipeId;
          if (EdPipes[i].SecOfBlow != Pipes[i].SecOfBlow )
            str+= "#"+EdPipes[i].SecOfBlow;

          if (EdPipes[i].CageId != Pipes[i].CageId)
            str+="<"+ EdPipes[i].CageId+ ">";

          AddModifyDesc (str);  

      }
      if (EdPipes[i].CageId){
        if (cnt > 0)
          values += ", ";
        values += "("+props.FarmID+", "+SysIdx+", "+EdPipes[i].PipeId+", '"+EdPipes[i].CageId+"', "+ EdPipes[i].SecOfBlow+")";
        cnt++;
      } else{

        if (cntUC > 0)
          unConnected += ", ";
        unConnected += EdPipes[i].PipeId;  
      

        cntUC++;
      }
  }



  unConnected+= ")"




 




  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/savepipescages";
  let myInit = {
    // OPTIONAL
    headers: {
     
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      FarmID: props.FarmID,
      SysIdx: SysIdx
    },
    body: {
      values: values,
      fields: fields,
      M:ModifyDesc,
      U:props.userData.name,
      unConnected: unConnected
    }

  };
  setSaveMessage ("");
  API.post(apiName, path, myInit)
  .then ((response) => {
 
      setIsModified (false);
      setPipes (EdPipes);
      setSaveMessage ("Save successful");
      props.UpdateDash();
      ModifyDesc = "";


  })
  .catch ((er) => {

    setSaveMessage ("Error saving: "+JSON.stringify(er.message));


  })


  
}



  let FeedSysSelect = "";


  if (props.FeedSystems.length >1 ){
      FeedSysSelect = [];
      for (var i = 0; i< props.FeedSystems.length; i++)
        FeedSysSelect.push (
          <div className="ptc-radio"  key={i}>
          <ToggleButton
          key={i}
          type="radio"
          variant="outline-secondary"
          name = {i.toString()} 
          block
          onChange =  {handleRadioSys}
         
          checked = {EdRadioSysIdx[i]}

        >
          {"("+ (i+1)+ ") "+props.FeedSystems[i].Name}
        </ToggleButton>
        </div>

        );
  }


  let SaveDisabled = isSaveDisabled();

  

 return <div>

{IsLoading && <Loading />}
   <div className="ptc-buttons">
{FeedSysSelect}


<ButtonGroup  className="btn-group d-flex">
              <Button variant="outline-secondary" type="Button"  onClick = {handleClose } >
                Close
    </Button>
    
              <Button variant="outline-secondary" type="Button"   onClick = {handleSave } disabled={SaveDisabled} >
                Save
    </Button>
    
            </ButtonGroup>

{SaveMessage}
</div>
{!IsLoading && < BootstrapTable
            hover
            keyField='PipeId'
            data={EdPipes}
            noDataIndication="No pipes defined"            
            //data = {[{PipeId:1, key:100},{PipeId:2, key:101} ]}
            columns={Columns}
           /* rowEvents={RowEvents}*/
            cellEdit={ cellEditFactory({ mode: 'click' }) }

          />}

 </div>


}