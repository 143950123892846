

import React from 'react';


 
require('./Footer.css');





var COPYRIGHT = "© 2022 Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved.";

	export default function Footer() {
  
     
    	return (
		<div>
			<br/>
		<div className = "wrapper">
<hr/>
<a href="https://www.essentialaccessibility.com:443/msd">
    <img className = "accessibility-img " src="https://assets.msd-animal-health.com:443/brands/accessibility/img/accessibility-WhiteOnGray.png" alt="Accessibility button"/>
    </a>

   


    <a href="https://www.msd.com:443/our-commitment-to-accessibility-for-all" className = "accessibility-link ">Accessibility</a>




	
    <a href="https://www.msdprivacy.com/" className = "accessibility-link ">Privacy</a>
	
	<a href="https://www.msd.com/terms-of-use/" className = "accessibility-link ">Terms of use</a>

	<a href="https://vakiiceland.is/sales-and-service-agents/" className = "accessibility-link ">Contact</a>
	









	<div className = "cookie-button-div" ><button className="ot-sdk-show-settings"></button></div>

	<div className="copyRight" >{"© "}{(new Date().getFullYear())} {" Merck & Co., Inc., Rahway, NJ, USA and its affiliates. All rights reserved."} </div>


		</div>
		</div>
	);
}