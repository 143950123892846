import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker"
import {v4} from "uuid";
import BootstrapTable from "react-bootstrap-table-next";
import { Loading } from "../Components/Loading";
import "react-datepicker/dist/react-datepicker.css";
import Amplify, {  API } from "aws-amplify";
import moment from "moment"
import aws_exports from "../aws-exports";

Amplify.configure(aws_exports, {});



require ("./CounterLiveView.css")







export default function ShareList (props) {
const [list, setList] = useState (null)
    

    useEffect(() => {
        
 


    
    
   }, []);







return <div id="container">
  <iframe id = "responsive-iframe" src="https://smartflow.is/"></iframe>
</div>;



}