import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import BootstrapTable from "react-bootstrap-table-next";


import Amplify, { graphqlOperation, API } from "aws-amplify";

import Form from "react-bootstrap/Form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";



import { Loading } from "../Components/Loading";

import "./OrgInfo.css"





import moment from "moment";





import { listUsers, ListSimpleOrg } from "../customGQL/customQueries";


//import Users from "./Users";
//import Organisations from "./Organisations";
//import Farms from "./Farms";
//import LicenceGenerator from "./LicenceGenerator";
//import Populations from "./Populations";
//import Counter from "./Counter";
import aws_exports from "../aws-exports";


Amplify.configure(aws_exports, {});






export default function OrgInfo(props) {



  
  const [users, setUsers] = useState ([])
  const [orgInfo, setOrgInfo] = useState (null)



  
  

  useEffect(() => {
    if (!!props.Org){
        loadData();
    }
    
  }, [props.Org]);








  const loadData = async () => { 


    let apiName = "vakicloudRdsAPI";
    let path = "/counters/usersonorg";




    let myInit = {


      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {

        OrgID: props.Org.value


      }

    };

    API.get(apiName, path, myInit)
      .then(response => {

        


        let tmpU = [];
        for (let i = 0; i< response.Users.length; i++){
     
          tmpU.push (response.Users[i])
        }

        setUsers (tmpU)

        setOrgInfo(response.Org)




  






      })
      .catch((err) => {
        console.log("OrgInfo Error: " + JSON.stringify(err));
     
      })


   }








   //ProjectionExpression: 'firstName, email, lastName, #Name, createdAt, phoneNumber, admin',


   var columns = [
    { dataField: "name", text: "UserName"},
    { dataField: "firstName", text: "Name"},
    {
      dataField: "lastName",
      text: "Surname"
     
   
    },
 
    { dataField: "email", text: "Email" },
  

    {
      dataField: "createdAt",
      text: "Created"
  
    }

  ];
   




var dt = null;
if (orgInfo){
     dt = new Date (orgInfo.createdAt)


}




  return (





<div>

<div>
<label className="thetext" >{ orgInfo && orgInfo.orgName } </label>
Registered {orgInfo && dt.toString()}

</div>

      Registered users
              <BootstrapTable
            hover
            keyField='name'
            data={users}
            columns={columns}
 
           

          />
      
       </div>



  );
}
