import React, { useState, useEffect } from "react";

import Amplify, {  API } from "aws-amplify";

import "./Reports.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";






Amplify.configure(aws_exports, {});



   let eventKey = null; 
export default function PipesTimes(props) {

 const [PipeData, setPipeData] = useState([]);
 const [ProfilesList, setProfilesList] = useState([]);
 const [SiloList, setSiloList] = useState([]);
 const [IsLoading, setLoading] = useState(true);
const [RoundTime, setRoundTime] = useState(30);
const [RoundsPrHour, setRoundsPrHour] = useState(2);
const [NoOfPipes, setNoOfPipes] = useState(36);
const [TheData, setTheData] = useState([]);


 useEffect(() => {

    setRoundTime (props.FeedSystems[props.FeedSystems[0].SysIdx].RoundTime); 
    setRoundsPrHour (props.FeedSystems[props.FeedSystems[0].SysIdx].RoundsPrHour); 
    setNoOfPipes (props.FeedSystems[0].NoOfPipes);
  loadData();
}, [props.FarmID, props.FeedSystems[0].SysIdx]);





const loadData = async () => {


  fetchPipeTimes()


}


const getHourIdx = (hour) => {
    if (hour  > 9)
        return "H"+hour;
    return "H0"+hour;    
}

const loadTheTable = () => {
  
    let row = getEmptyTableRow (0);
    let dt = [];
    let numUsedPipes = PipeData.length;



    let hoursTotNet = [];
    for (let i=0; i<24; i++)
        hoursTotNet.push(0);

    let hoursTotTotNet = 0;    

  

 
  
    for (let i=0; i<numUsedPipes; i++){
        let r = {}; 
        if (!PipeData[i].Active)
            continue;

        //Check if more pipes into this pipe;
        let cntP = 0;
        for (let j=0; j<numUsedPipes; j++){
            if (PipeData[i].CageId == PipeData[j].CageId)
                cntP++;
        }

        r["PipeId"] = PipeData[i].PipeId;
        r["Cage"] = PipeData[i].Name;
        //Get total weight in grams for this pipe
        let totWeight = PipeData[i].FeedTotal*1000/cntP;
        //Sort out the silo(s)
        let siloArr = PipeData[i].SiloArray.split (",");
        let numSil = siloArr.length;
        let gramsPrSec = 0;
        for (let j=0; j<numSil; j++){
            let siloID = parseInt (siloArr[j]);
            let silo = SiloList.find ((r)=> {if (r.SiloId=siloID) return r; else return null;});

   
           
            gramsPrSec += silo.GramsPrSec; 
        }
        r["TotalTime"] = 0;


        if (gramsPrSec > 0)
            r.TotalTime = totWeight / gramsPrSec;

       
   
      
        let Profile = ProfilesList.find ((r) => {if (r.ProfileId = PipeData[i].ProfileId) return r; else return null;})  
        
    
        for (let j=0; j<24; j++){
            let strIdx = getHourIdx(j);
     
            let Perc = Profile [strIdx];

     
            let val = r.TotalTime*Perc/100/RoundsPrHour;
            r[strIdx] = val;
            hoursTotNet[j] += r.TotalTime*Perc/100;
            if (val>0){ //THen add blow after time
                hoursTotNet[j]+= PipeData[i].SecOfBlow*RoundsPrHour;
                hoursTotNet[j] += RoundsPrHour*RoundTime+2;
            }

            hoursTotTotNet +=  hoursTotNet[j] 
        }    

          


        dt.push(r);
      
    }

    let SumRow = {};
    SumRow ["PipeId"] = 100;
    SumRow ["Cage"] = "Total:";
    let mins = Math.round( hoursTotTotNet/60);
    let hours = Math.floor (mins/60);
    mins -= hours*60; 
    SumRow ["TotalTime"] = hours+"H "+mins+"m";
    for (let i = 0; i<24; i++)
        SumRow[getHourIdx(i)] = Math.round(hoursTotNet[i])+"s";
    


    dt.push(SumRow);

    setTheData (dt);
    


}



const getEmptyTableRow = (ID) =>{

  
    let row = [];
    row.push ({PipeId: ID});
    row.push ({Cage: ""});
    row.push ({TotalTime: 0});
    /*
    for (let i = 0; i<24; i++){
        let strIdx = "H";
        if (i<10)
            strIdx += "0"+i;
        else
            strIdx += i;
        let obj = {};
        obj[strIdx] = 0;

        row.push (obj);
    }
*/


    return row;

}






const  TheFormatter = (val) =>
{
    if (!val)
        return "";
    if ((typeof val).toUpperCase() == "NUMBER")
        return val.toFixed(1);
    
    return val;    
    



}

function cellStyler(cell, row, rowIndex, colIndex) {
 
    var style = { };

    if (rowIndex >= PipeData.length){
        style ["borderWidth"] = "1px" ;
        style ["borderTop"] = "solid";
    }


    return style;
  }




let columns = [{
    dataField: 'PipeId',
    text: ' ',
    formatter: (val) => {  if (val < 100) return  val; else return ""},
    style: cellStyler
 
  }, {
    dataField: 'Cage',
    text: 'Cage',
    style: cellStyler
 

  }, {
    dataField: 'TotalTime',
    text: 'Total (sec)',
    formatter: (val) => {  if ((typeof val).toUpperCase() == "NUMBER") return   val.toFixed(0)+"s"; else return val},
    style: cellStyler
 
  }, {
    dataField: 'H00',
    text: '0',
    formatter: TheFormatter,
    style: cellStyler
 
  }, {
    dataField: 'H01',
    text: '1',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H02',
    text: '2',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H03',
    text: '3',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H04',
    text: '4',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H05',
    text: '5',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H06',
    text: '6',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H07',
    text: '7',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H08',
    text: '8',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H09',
    text: '9',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H10',
    text: '10',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H11',
    text: '11',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H12',
    text: '12',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H13',
    text: '13',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H14',
    text: '14',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H15',
    text: '15',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H16',
    text: '16',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H17',
    text: '17',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H18',
    text: '18',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H19',
    text: '19',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H20',
    text: '20',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H21',
    text: '21',
    formatter: TheFormatter,
    style: cellStyler
 
  },  {
    dataField: 'H22',
    text: '22',
    formatter: TheFormatter,
    style: cellStyler
 
  },{
    dataField: 'H23',
    text: '23',
    formatter: TheFormatter,
    style: cellStyler
 
  },






  ];


const fetchTheProfiles = (ProfileIdList) => {
  
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/profiles";
    let myInit = {
  
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        Hidden:0,
        IdList: ProfileIdList
       }
    };

    API.get(apiName, path, myInit).then(response => {
        setProfilesList(response);

        return fetchTheSilos();

    

       setLoading(false);
     })
      .catch((err) => {
        setLoading(false);
        setProfilesList([]);
      })
  
    };

    const fetchTheSilos = () => {
  
        let apiName = "vakicloudRdsAPI";
        let path = "/counters/feeding/getsilos";
        let myInit = {
      
          // OPTIONAL
          headers: {}, // OPTIONAL
          response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {
            // OPTIONAL
            FarmID: props.FarmID
   
      
      
          }
        };
    
    
    
    
    
        API.get(apiName, path, myInit).then(response => {
            setSiloList(response);
       
            
    
    
           setLoading(false);
         })
          .catch((err) => {
            setLoading(false);
            setSiloList([]);
          })
      
        };





  const fetchPipeTimes = () => {

    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/pipefeedinginfo";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        SysIdx: props.FeedSystems[0].SysIdx  


      }
    };


    setLoading(true);

    API.get(apiName, path, myInit).then(response => {


        let len = response.length;
        let profIDs = []
        for (let i = 0; i<len; i++){
            let profID = response[i].ProfileId;

            if (profIDs.indexOf(profID) < 0){
                profIDs.push(profID);
            }
        }

        let strList = "(";
        len = profIDs.length;
        for (let i = 0; i<len; i++){
            if (i>0)
                strList+=", ";
            strList += "'"+profIDs[i]+"'";    
        }
        strList += ")";


        setPipeData (response);

     
        return fetchTheProfiles(strList);
       
 
    
       

      setLoading(false);
   



    })
      .catch((err) => {

        console.log ("PipeTimes error fetching: "+err)
        setLoading(false);

      })

  };





const handleClose = ()=>{
  props.HideMe();
}






    if (!IsLoading && TheData.length == 0 && PipeData.length != 0 && ProfilesList.length != 0 && SiloList.length != 0){
       
        loadTheTable();
    }


    let TheTable = ""; 
    if (!IsLoading && TheData != [] ){
        
            

        TheTable =             
        <BootstrapTable
        hover
        keyField='PipeId'
        data={TheData}
        columns={columns}
      
      />;
    }








  



  

 return <div>

{IsLoading && <Loading />}

{TheTable}


 </div>


}