import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";

import BootstrapTable from "react-bootstrap-table-next";

import {OverlayTrigger, Popover, Alert, Button, Row, Col, Card} from 'react-bootstrap';






import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";

require ("./Cage.css")

Amplify.configure(aws_exports, {});


const colorGreen = "#72F363";
//const colorYellow = "#F3E463";
const colorYellow = "#f3f362";
const colorGray = "#CDCDBF";

 







export default function Cage(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);

 const [IsLoading, setLoading] = useState(true);







 let CageSize= props.Preferences.DefaultCageSize;
 let GridSize = props.Preferences.SnapGrid;
 let PaneWidth =  props.PaneWidth;
 let PaneHeight = props.PaneHeight;






const getPopup = () => {


    let dateStr = props.Cage.TheDate;
    let n = dateStr.indexOf ("T");
    dateStr = dateStr.substr(0,n);

    let today = 0;

    if (!props.Cage.grams)
        today=0;
    else
        today=props.Cage.grams;

    if (today == 0)
        dateStr = "";


    let rows = [];

    rows.push ({
        key: 0,
        Desc: "Count",
        Value: props.Cage.NoOfFish
    })

    rows.push ({
        key: 1,
        Desc: "Awg.wgt",
        Value: props.Cage.AvarageWeight+"g"
    })

    rows.push ({
        key: 2,
        Desc: dateStr,
        Value: today+"g"
    })

    rows.push ({
        key: 3,
        Desc: "Temperature",
        Value: props.Cage.Temperature+"°C"
    })

    const columns = [{
        key:0,
        dataField: 'Desc',
        text: 'Desc',
          headerAttrs: {
            hidden: true
          }
        },{
            key:1,
            dataField: 'Value',
            text: 'Value',
              headerAttrs: {
                hidden: true
              }

        }];


        let errors = []
        if (props.Cage.errors && props.Cage.errors.length > 0){
            let len = props.Cage.errors.length; 
            for (let i = 0; i<len; i++){
                errors.push (<p key={i}> {props.Cage.errors[i] } </p>)
            }

        }
        

        return     <div id="PopupWrap">
          <BootstrapTable
          
        keyField='Desc'
        data={rows}
        columns={columns}
      
      />
      <div id="errors">
      {errors}  
      </div>
      </div>;





}


////////////////////////////////////////////////

 




let x = props.Cage.ImXPos;
let y = props.Cage.ImYPos;
let d = CageSize; 

    if (x <= 2 || x  >= 95 )
        x = 2*props.id+2;
        

    if (y <= 2 ||  y >= 95 )
         y = 2*props.id+2;   
         
   
    if (d<= 2 || d>20)
        d= 10;
        
/*
    if (CageSize > 0)
        d= CageSize;    
  */  

    if (!props.PanePoint){
        props.Cage.ImXPos =  x;  
        props.Cage.ImYPos = y;
    }else{

      
        props.Cage.ImXPos =  props.PanePoint.x;  
        props.Cage.ImYPos = props.PanePoint.y; 
    }
    CageSize = d;

 


   let ypix = ((props.Cage.ImYPos*PaneHeight)/100)

//   tp = Math.round (tp/ygrid);
 //  tp*=ygrid; 



   let xpix =  ((props.Cage.ImXPos*PaneWidth)/100)
 //  lt = Math.round (lt/xgrid);
 //  lt*=xgrid;

  






    let cageDimension = ((d*PaneWidth)/100);



    let bckColor = colorGreen;
    if (props.Cage.NoOfFish <= 0)
        bckColor = colorGray;
    else if (props.Cage.Active == 0)
        bckColor = colorYellow;    
    


    let sCage = {

        top: ypix+"px",
        left :  xpix+"px",
        width : cageDimension+"px",
        height : cageDimension+"px",
        fontSize: (Math.round(cageDimension*0.3))+ "px",
        backgroundImage: "linear-gradient(to bottom right, "+bckColor+", white)"
    
      }










///////////////////////////////////////////////////





//props.Style["fontSize"] = (Math.round(props.Width*0.3))+ "px";



  
let popStyle = {
    fontSize :     (Math.round(cageDimension*0.11))+ "px"
}


let classStatus =  "";

if (props.Cage.status.toUpperCase() == "W")
    classStatus = "fa  fa-warning fa-1x fa-fw  CageStatus Orange";

else if (props.Cage.status.toUpperCase() == "E")  
    classStatus = "fa  fa fa-exclamation-circle fa-1x fa-fw CageStatus Red";



   // <i class="fa-solid fa-face-meh"></i>




let statusStyle = {
    fontSize :     (Math.round(cageDimension*0.16))+ "px"

}










 return  <div>
 <OverlayTrigger delay={200} rootClose={true} placement='top' overlay={<Popover title={<center><b>{"PopOver"}</b></center>}  > {<div> {getPopup()} </div>} </Popover >}>
 
 <div   id={"Cage_"+props.id}  className = {"CageOutlines"}  style={sCage} onMouseDown={props.MouseDownHandler}  >

<div className="CageName"  id={"CageName_"+props.id}   >

    {props.Cage.Name} 
 </div>   

 <div className="Status"  id={"Status_"+props.id} style={statusStyle}
   className={classStatus} >

</div>  

 <div className="PopName"  id={"PopName_"+props.id} style={popStyle}  >

{props.Cage.PopName} 
</div>  

        </div>
          
       </ OverlayTrigger>

  
</div>;

}



