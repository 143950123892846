import React from 'react';
import { HighchartsChart, Chart, XAxis, YAxis, Title, Legend, LineSeries, Tooltip } from 'react-jsx-highcharts';
const ThroughputChart = (props) => (
	<div>
		<HighchartsChart>
			<Chart zoomType={props.zoom} height={props.ChartHeight} />
			<Legend layout="horizontal" align="center" verticalAlign="bottom"  enabled={!props.Minimalized} />

			<Title>{props.title}</Title>
			<XAxis id="datetime_troughputchart" type="datetime">
				<XAxis.Title>{(props.Minimalized?null:"Time")}</XAxis.Title>
			</XAxis>
			<YAxis id="fpm_number" visible={!props.Minimalized}>
				<YAxis.Title>{props.Minimalized ? null : 'Fish per minute'}</YAxis.Title>
				{props.dataPoints !== undefined ? (
					props.dataPoints.map((Lines, index) => (
						<LineSeries
							key={index}
							id={`Count ${index}`}
							name={props.channelNames ? props.channelNames[index] : null}
							data={Lines}
							color={props.seriesColors ? props.seriesColors[index] : null}
              
						/>
					))
				) : (
					''
				)}

				{props.dataPointsMax !== undefined ? (
					props.dataPointsMax.map((Lines, index) => (
						<LineSeries
							key={index}
							id={`Max ${index}`}
							name={`${props.channelNames[index]} max thoughput`}
							data={Lines}
           
						/>
					))
				) : (
					''
				)}
			</YAxis>
			<Tooltip padding={10} hideDelay={250} shape="square" valueSuffix=" Pr/min" />
		</HighchartsChart>
		<button onClick={props.exportToCSV}    hidden={props.Minimalized}>Export to CSV</button>
	</div>
);
export default ThroughputChart;
