/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:3921832b-82ad-4df5-b7f4-e6fc5ea48a40",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_jTkE9MSUU",
    "aws_user_pools_web_client_id": "2dk2gtqs4fo4rmd41noguk0qs5",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://e7mrwjdzzvbavcxtrvkc776fty.appsync-api.eu-west-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "vakicloudRdsAPI",
            "endpoint": "https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ],
    "aws_content_delivery_bucket": "vakicloud-20191015143157-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "eu-west-1",
    "aws_content_delivery_url": "https://dntracko6h8s9.cloudfront.net"
};


export default awsmobile;
