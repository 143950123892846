import React, { useState, useEffect } from "react";

import { Row, Col, Button } from "react-bootstrap";
import "./SessionMain.css";
import BootstrapTable from "react-bootstrap-table-next";

 /*
var MainObject = {

TotalBiomass:-1,
TotalAverageWeight:-1,
Lasting:-1,
DateTime:-1.0,
Companyname:"",
Nocommentlines:-1,
//logoimage=
ChannelC_Version:"",
pdfFilePath:"",
pdfFile:"",
NrCountingUnits:-1,
  CountingUnits:[]
};


blcFilePath: "",
   cntFilePath: "",
   mseFilePath: "",
   blcFile:"",
   cntFile:"",
   mseFile:"",
   Name: "",
  ID: -1,
  FileName:"",
  SizeSetting: "",
  Count:-1,
  Percent_Count:-1,
  Biomass:-1,
  Percent_Biomass:-1,
  Counter_type:"",
  Average_weight:""


  */


  export default function SessionMainTable(props) {

   // const [FarmList, setFarmList] = useState([]);


  const [tableData, setTableData] = useState([])



  useEffect(() => {
  
   
  
    




  }, [props]);



  let columns = [
    {
    dataField: 'NetName',
    text: 'Unit'

  },
  {
    dataField: 'Counter_type',
    text: 'Type'

  }, 
  {
    dataField: 'Count',
    text: 'Count',
    formatter: (v)=>{  return parseInt(v, 10).toLocaleString()} 

  }, 
  {
    dataField: 'Percent_Count',
    text: '% Count', 
    formatter: (v) => {return v+"%"}
  },, 
  {
    dataField: 'Biomass',
    text: 'Biomass',
    formatter: (v)=> {return biomassFormatter(v);}
  }, 
  {
    dataField: 'Percent_Biomass',
    text: '% Biomass',
    formatter: (v) => {return v+"%"}
  }, 
  {
    dataField: 'Average_weight',
    text: 'Avg. weight (g)',
    formatter: (v)=>{return v.toLocaleString()} 
  }
  



];
  





const biomassFormatter = (cell) => {

 

    let n = parseFloat (cell);

 
    if (isNaN(n))
        return "---";
    let str = "";
      
    if (n<1000){
        str = n+"g";
    } else if (n<10000){
        str= (n/1000).toFixed(2)+"kg";
     }
    else if (n<100000){
        str= (n/1000).toFixed(1)+"kg";
     }
    else if (n<1000000){
        str = (n/1000).toFixed(0)+"kg";
    }
    else if (n<10000000){
        str = (n/1000000).toFixed(2)+"t";
    }
     else if (n<100000000){
        str = (n/1000000).toFixed(1)+"t";
    }
    else if (n<1000000000){
        str = (n/1000000).toFixed(0)+"t";
    }
    else if (n<10000000000){
        str = (n/1000000000).toFixed(2)+"kt";
    }
    else if (n<100000000000){
        str = (n/1000000000).toFixed(1)+"kt";
    } else {
        str = (n/1000000000).toFixed(0)+"kt";
    } 

return str;

} 









//const SessionMain = props => (



  

    return(
  <div>
 
 <div className = "table-wrap"> 
     <BootstrapTable
              keyField='key'
              data={props.TableData}
              columns={columns}
          
            />
    
     </div>
  
  </div>
);
};

