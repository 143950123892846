import React from "react";
import { Row, Col } from "react-bootstrap";
import "./GeneraInfoTabs.css";

const Session = props => (
  <div>
    <Row className="Information-tab">
      <Col sm>
        <div>
          <h4>
            <span> Total Counted:</span>{" "}
            <span><b>{parseInt(props.totalCount, 10).toLocaleString()}</b></span>
          </h4>
        </div>
      </Col>
    </Row>
    <hr />
    <Row className="Information-tab">
      <Col xl={3} sm={6}>
        <div>
          <h4 className="inl">
            <span className="inl-front"> Session:</span>
            <span className="inl-back">{props.tank}</span>
          </h4>
          <h4 className="inl">
            <span className="inl-front"> From: </span>
            <span className="inl-back">{props.from}</span>
          </h4>
          <h4 className="inl">
            <span className="inl-front"> To: </span>
            <span className="inl-back">{props.to}</span>
          </h4>
          <h4 className="inl">
            <span className="inl-front"> Operator:</span>
            <span className="inl-back">{props.operator}</span>
          </h4>
        </div>
      </Col>
      <Col xl={3} sm={6}>
        <div>
          <h4 className="inl">
            <span className="inl-front"> Start Time:</span>
            <span className="inl-back">{props.start}</span>
          </h4>
          <h4 className="inl">
            <span className="inl-front"> End Time: </span>
            <span className="inl-back">{props.end}</span>
          </h4>
          <h4 className="inl">
            <span className="inl-front"> Duration:</span>
            <span className="inl-back">{props.duration}</span>
          </h4>
          <h4 className="inl">
            <span className="inl-front"> Overload:</span>
            <span className="inl-back">{props.overload}</span>
          </h4>
        </div>
      </Col>
      <Col sm={12} className="d-xl-none">
        <hr />
      </Col>
      <Col xl={3} sm={6}>
        <h4 className="inl">
          <span className="inl-front"> PC MAC Adress:</span>
          <span className="inl-back">{props.macAddress}</span>
        </h4>
        <h4 className="inl">
          <span className="inl-front"> Transfer Id:</span>
          <span className="inl-back">{props.dropxBox}</span>
        </h4>

        <h4 className="inl">
          <span className="inl-front"> Software Version:</span>
          <span className="inl-back">{props.softwareVersion}</span>
        </h4>

        <h4 className="inl">
          <span className="inl-front"> Camera Serial Number:</span>
          <span className="inl-back">{props.serialNumber}</span>
        </h4> 
      </Col>
      <Col xl={3} sm={6}>
       
        <h4 className="inl">
          <span className="inl-front"> Camera MAC Address:</span>
          <span className="inl-back">{props.cameraMacAddress}</span>
        </h4>
        <h4 className="inl">
          <span className="inl-front"> Scan Rate:</span>
          <span className="inl-back">{props.scanRate}</span>
        </h4>
        <h4 className="inl">
          <span className="inl-front"> Light Strength:</span>
          <span className="inl-back">{props.lightStrength}</span>
        </h4>
      </Col>
    </Row>
  </div>
);
export default Session;
