import React, { useState, useEffect } from "react";

import Amplify, {  API } from "aws-amplify";

import "./Reports.css";

import moment from "moment";
import { DateRangePicker } from "react-dates";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";
import ColumnSelect from "./ColumnSelect.js"

import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";




Amplify.configure(aws_exports, {});



   let eventKey = null; 
export default function GrowthReport(props) {


 const [IsLoading, setLoading] = useState(true);

 const [startDate, setStartDate] = useState(moment());
 const [endDate, setEndDate] = useState(moment());
 const [focusedInput, setFocusedInput] = useState(null);

 

 useEffect(() => {

 //   setRoundTime (props.FeedSystems[props.FeedSystems[0].SysIdx].RoundTime); 
 //   setRoundsPrHour (props.FeedSystems[props.FeedSystems[0].SysIdx].RoundsPrHour); 
 //   setNoOfPipes (props.FeedSystems[0].NoOfPipes);
  loadData();
}, [props.FarmID, props.FeedSystems[0].SysIdx]);





const loadData = async () => {





}





const getEmptyTableRow = (ID) =>{

  
    let row = [];
    row.push ({PipeId: ID});
    row.push ({Cage: ""});
    row.push ({TotalTime: 0});
    /*
    for (let i = 0; i<24; i++){
        let strIdx = "H";
        if (i<10)
            strIdx += "0"+i;
        else
            strIdx += i;
        let obj = {};
        obj[strIdx] = 0;

        row.push (obj);
    }
*/


    return row;

}






const  TheFormatter = (val) =>
{
    if (!val)
        return "";
    if ((typeof val).toUpperCase() == "NUMBER")
        return val.toFixed(1);
    
    return val;    
    



}

function cellStyler(cell, row, rowIndex, colIndex) {
 /*
    var style = { };

    if (rowIndex >= PipeData.length){
        style ["borderWidth"] = "1px" ;
        style ["borderTop"] = "solid";
    }


    return style;
    */
  }




let columns = [];



    





  const fetchReport = () => {

    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/pipefeedinginfo";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        SysIdx: props.FeedSystems[0].SysIdx  


      }
    };


    setLoading(true);

    API.get(apiName, path, myInit).then(response => {



     
       
       
 
    
       

      setLoading(false);
   



    })
      .catch((err) => {

        console.log ("PipeTimes error fetching: "+err)
        setLoading(false);

      })

  };





const handleClose = ()=>{
  props.HideMe();
}






 


    let TheTable = ""; 
    /*
    if (!IsLoading && TheData != [] ){
        
            

        TheTable =             
        <BootstrapTable
        hover
        keyField='PipeId'
        data={TheData}
        columns={columns}
      
      />;
    }
*/







  

console.log (startDate, endDate)

  
const dChange = (d) => {
  console.log ("-->>>>>>>>>>>>"+ JSON.stringify( d))

  setStartDate (moment(d.startDate));
  setEndDate (moment(d.endDate))
}


 return <div  className = "growthRep">

<div className= "menuWrap"> 
<div className = "dateRangeWrap">
<DateRangePicker
          startDateId={"startingDate"}
          startDate={startDate} // momentPropTypes.momentObj or null,
          endDateId={"endingDate"}
          endDate={endDate} // momentPropTypes.momentObj or null,
          onDatesChange= { dChange   //{({ sDate, eDate }) =>  {console.log (sDate+"------  "+eDate)} // {setStartDate(sDate); setEndDate (eDate)}
                    } // PropTypes.func.isRequired,
          displayFormat="DD-MM-YYYY"
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={foc => setFocusedInput(foc) } // PropTypes.func.isRequired,
          isOutsideRange={() => false} //allow any date
        />
</div>

<div className = "fetchbutton">
<Button variant='outline-secondary' >
  Sækja
</Button>
  </div>

<div className ="colSelect">
<ColumnSelect />
</div>
</div>
<div className = "divtest"/>


{IsLoading && <Loading />}





{TheTable}


 </div>


}