
import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";




import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'


import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../Components/Loading";





   
export default function CounterTypeSelect (props) {

   
    const [TypeList, SetTypeList] = useState([]);

    const [TheNumber, setTheNumber] = useState (0);
    
    const [TheCounterValue, setTheCounterValue] = useState (-1);
 




 useEffect(() => {

    let len = props.Types.length;
    for (let i = 0; i<len; i++)
        props.Types[i]["key"] = i;

    SetTypeList (props.Types)
    if (props.TheValue){
        if (props.TheValue==-1 && TheCounterValue >=0)
            setTheNumber (0);
        setTheCounterValue (props.TheValue)

    }
    
 
}, [props.Types, props.TheValue]);


var Options = [];


Options.push ( <option key={-1} value= {-1}> {"  "} </option>)



if (TypeList.length > 0){

    for (let i = 0; i<TypeList.length; i++){


        let opt = <option key={TypeList[i].Counter_Type_ID} value= {TypeList[i].Counter_Type_ID}> {TypeList[i].Counter_Name + "  Ch:"+TypeList[i].Channel_Count} </option>;
        Options.push(opt);
    }


}


const onCounterChange = (e) => {
    props.CounterCallBack (e.target.value)
    setTheCounterValue (e.target.value);
  
}

const onNumberChange = (e) => {
    let sn = "";

    if (e.target.value.trim() == ""){
        return;
    }

    let n = parseInt (e.target.value)


    if (n < 0) n = 0;
    if (n > 15) n=15;
    props.NumCallback(n);
    setTheNumber (n);

}


let checkClass = "";
if (props.Valid)
    checkClass = "fa fa-solid fa-check  fa-1x " 

return (<div>   
<div className="TypeAndNumber">
<div className = "DivSelect">   
<Form.Control as = "select" aria-label="Default select example"   onChange = {onCounterChange} value = {TheCounterValue} disabled = {props.Disabled} >
{Options}
    </Form.Control>
</div>
<div className = "DivNumber"> 
    <Form.Control type="number"  value={TheNumber}   onChange = {onNumberChange}  autoComplete="off" disabled={false}  disabled = {props.Disabled} />   
    </div>

    <div className = "DivChecked"> 
<i className={checkClass}  />
    </div>

</div>




</div>);


}