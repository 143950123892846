
import moment from "moment";



export function getTankName (string) {
    // Take in FileName and remove first 9 string and last 4
    // So that f.x. -> 20170118_ALI  GEZEN  LEV_0.MSE becomes -> ALI  GEZEN  LEV_0
    let newString = string.substring(9);
    let finalString = newString.substring(0, newString.length - 4);
    return finalString;
  };


  export function changeDateStamp (s) {
    // Change the date stamp from linux format to normal format
    var event = new Date(s);
    var newDate = moment(event).format("YYYY-MM-DD HH:mm");
    return newDate;
  };


  
  export function prepData(data)  {

    var ModifyProp = data.map((o, index) => ({
      ...o ,
      Timestamp: `${changeDateStamp(o.Timestamp)}`,
      Tank: `${getTankName(o.FileName)}`
      
  
    }));
  
    // ATH Hérna er ég að fyltera út allar talningar sem eru 0
    let filteredData = [];
    ModifyProp.forEach(e => {
      if (e.FishCount < 1) {
        // Skip
      } else {
        filteredData.push(e);
      }
    });
  
    return filteredData;
  };
