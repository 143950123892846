import React, { useState, useEffect, useRef } from 'react';
import Amplify, { API } from 'aws-amplify';
import './Viewer.css';
import { Navbar, Nav, NavItem, NavDropdown, Form, FormControl, Button } from 'react-bootstrap';
import Axios from 'axios';
import './LargeSpinner.css';
//import Zlib from "react-zlib-js";
import Pako from 'pako';
import AWS from 'aws-sdk';
import aws_exports from '../aws-exports';
//import { ScrollableCanvas } from "react-scrollable-canvas";

Amplify.configure(aws_exports, {});

AWS.config.update({
	accessKeyId: process.env.REACT_APP_ACCESS_ID,
	secretAccessKey: process.env.REACT_APP_ACCESS_KEY
});

const SliderHeight = 50;
const ViewerCanvas = (props) => {
	//export default function ViewerCanvas(props) {

	const { draw, Position, Counts, ...rest } = props;

	const ref = useRef(null);

	useEffect(
		() => {
			const canvas = ref.current;

			const context = canvas.getContext('2d');

			draw(context, Position, props.Counts);
		},
		[ props ]
	);

	return <canvas ref={ref} {...rest} />;
};

export default ViewerCanvas;
