import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton, Modal } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";

import "./Actions.css";
import "./../Feeding.css";




import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";
import { number } from "prop-types";



Amplify.configure(aws_exports, {});














let StatTime = new Date (2000, 1,1, 0,0,0,0);



 
export default function MoveFishModal(props) {


    const [IsLoading, setLoading] = useState(false);
    const [EdNoOfUnknown, setEdNoOfUnknown] = useState(0);
    const [EdNoOfMale, setEdNoOfMale] = useState(0);
    const [EdNoOfFemale, setEdNoOfFemale] = useState(0);
    const [EdTargetPopName, setEdTargetPopName] = useState(0);

    const [DisableEditNumber, setDisableEditNumber ] = useState(false);

 
   

    const [show, setShow] = useState(true);


    const handleShow = () => setShow(true);
    const [ShowModal, setShowModal] = useState (false);
    const [TheTitle, setTheTitle] = useState("");
    const [SourceCage, setSourceCage] = useState (null);
    const [TargetCage, setTargetCage] = useState (null);




const handleClose = () => props.hideMe ();

const CreatePop = (rec) => {

    return {

      PopId:rec.PopId,
      FarmID: props.FarmID,
      PopName: rec.PopName,
      CurrentCageId:rec.CageId,
      CurrentCageName:rec.Name,
      NoOfMale:rec. NoOfMale,
      NoOfFemale:rec.NoOfFemale,
      NoOfUnknown:rec.NoOfUnknown, 
      NoOfFish:rec.NoOfFish,
      Origin:rec.Origin,
      ParentPopulation:rec.ParentPopulation,
      AvarageWeight: rec.AvarageWeight,
      SGRId : rec.SGRId,
      ProfileId : rec.ProfileId
    }



}







const handleSave = () => {
  /******************************** 
  Options: 

  Move All checked:
    1: If Target has no population, the total pop is moved. Pop.CurrentCageID will change to target in the population
    2: Target has already population.  SrcPop.CurrentCageID->null and all fish mount to -> zero.  Both src and target updated 

  Move All Unchecked:  
  Target empty - no population there:
  3:  Move the total population from source to SrcPop.CurrentCageID = null  New pop created in Target 
  4:  Src Partly moved.  New pop Created in target - srcpop updated

  Target not Empty:
  5:  Move all from source -> source population.CurrentCageID=null and all fish count there to zero. Moved fish added to target (same as above)
  6:  Part of source moved -> both source nd target population updated accordingly
    
  In all cases we need to insert two entries into tblPopulationCage to describe the trasactions for both cages 

  Create new target populations in cases  3, 4
  Disconnect And Empty Source pop in cases 2, 3, 5
  Change CurrentCageId in srcPop to TargetCage in case 1

   
  */



  let theCase = 0;

  let TotalFishMoved = (EdNoOfUnknown + EdNoOfMale + EdNoOfFemale);
  if (TotalFishMoved <= 0){
    alert("No fish moved!"); 
    props.hideMe();
    return;
  }

  if (EdTargetPopName.length == 0 && !TargetCage.PopId && !DisableEditNumber){
    alert("Please enter name for the target population!");
    return; 
  }

  let src = {...SourceCage}
  let trg = {...TargetCage};

  let srcPop = null;
  let trgPop=null;


  
  if (DisableEditNumber){  //All moved

    if (!trg.PopId){
        theCase = 1;

        srcPop = CreatePop(src);

        src.CageId = trg.CageId;  //because src become trg
        trgPop = CreatePop (src); 


    }else {
        theCase = 2;
    }


  }else{

    if (!trg.PopId){
      if (TotalFishMoved >= src.NoOfFish)
        theCase = 3;
      else
        theCase = 4;
      

    }else{
      if (TotalFishMoved >= src.NoOfFish)
      theCase = 5;
    else
      theCase = 6;

    }



  }







  if (theCase != 1){

    if (trg.NoOfFish == 0)
        trg.AvarageWeight = src.AvarageWeight;
    else{

      if (trg.AvarageWeight != null && src.AvarageWeight != null){
        try{
          //Updt average in target
          let srcWgt = src.AvarageWeight*TotalFishMoved;
          let trgWgt = trg.AvarageWeight*trg.NoOfFish;
          trg.AvarageWeight = (srcWgt+trgWgt)/ (TotalFishMoved+trg.NoOfFish);
        }catch (e){
           console.log ("Exception 5834177");
        }
      }
    }



    src.NoOfUnknown -= EdNoOfUnknown;
    src.NoOfMale -= EdNoOfMale;
    src.NoOfFemale -= EdNoOfFemale;
    src.NoOfFish -= TotalFishMoved;
    trg.NoOfUnknown += EdNoOfUnknown;
    trg.NoOfMale += EdNoOfMale;
    trg.NoOfFemale += EdNoOfFemale;
    if (!trg.PopId)
       trg.PopName = EdTargetPopName;
    trg.NoOfFish += TotalFishMoved;

    switch (theCase){
      case 2:
      case 3:
      case 5:
        src.CageId = null;
      case 4:
      case 6:
        if (!trg.PopId){
          //New population
          if (src.Origin)
            trg.Origin = src.Origin;
          else
            trg.Origin = src.PopID;
          trg.ParentPopulation = src.ParentPopulation;
          trg.SGRId = src.SGRId;
          trg.ProfileId = src.ProfileId;
        }
  
        trgPop = CreatePop(trg);
        srcPop = CreatePop(src); 
        break;
    }
    


    

  }









  let theMoved = [];
  theMoved.push (EdNoOfMale);
  theMoved.push (EdNoOfFemale);
  theMoved.push (EdNoOfUnknown);



  props.hideMe (theCase, srcPop, trgPop, theMoved);



/*
  let updSrc = "UPDATE tblPopulation SET "
              +",PopName="+src.PopName
              +",CurrentCage="+src.CurrentCageId
              +",NoOfMale="+src.NoOfMale
              +",NoOfFemale="+src.NoOfFemale
              +",NoOfUnknown="+src.NoOfUnknown
              +",NoOfFish="+src.NoOfFish
              +",Origin="+src.Origin
              +",ParentPopulation="+src.ParentPopulation
              +" where FarmID="+props.FarmID+" AND PopId="+src.PopID;
*/






 // props.hideMe (EdNoOfUnknown, EdNoOfMale, EdNoOfFemale);
};



useEffect(() => {




 setSourceCage (props.SourceCage);
 setTargetCage (props.TargetCage);
 setEdNoOfUnknown(0);
 setEdNoOfMale (0);
 setEdNoOfFemale(0);

  setEdTargetPopName (props.TargetCage.PopName);


  setTheTitle ("Move fish from cage "+props.SourceCage.Name +" to cage "+props.TargetCage.Name);


  
  }, [props.SourceCage, props.TargetCage, props.DashModified]);



  const handleEditChange = e => {
    const {name, value, checked } = e.target;

    if (name == "popname"){
      setEdTargetPopName (value);
      return;
    }

    if (name == "moveall"){
     
      if (checked){

         setEdNoOfUnknown(SourceCage.NoOfUnknown);
         setEdNoOfMale(SourceCage.NoOfMale);
         setEdNoOfFemale(SourceCage.NoOfFemale);
         setEdTargetPopName (SourceCage.PopName);

      }else{
        setEdNoOfUnknown(0);
        setEdNoOfMale(0);
        setEdNoOfFemale(0);
        setEdTargetPopName ("target: "+TargetCage.PopName);
      }
      setDisableEditNumber(checked);
      return;
    }


    let num = 0;
    if (value)
      num=parseInt(value);

      if (num < 0)
         num = 0;
    switch (name){
      case 'male': 
        if (num > SourceCage.NoOfMale)
          num = SourceCage.NoOfMale;
        setEdNoOfMale(num);
      break;
      case 'female':
        if (num > SourceCage.NoOfFemale)
          num = SourceCage.NoOfFemale; 
        setEdNoOfFemale (num);
        break;
      case 'unknown':
        if (num > SourceCage.NoOfUnknown)
          num = SourceCage.NoOfUnknown;
        setEdNoOfUnknown(num);
        break;
      default:
      break;
    }

};





  let TheContent = "";





  if (SourceCage){



    TheContent = [];






TheContent.push(
  <Form.Group controlId="MoveAll" as={Row} >
    <Form.Label column sm="5"> </Form.Label>
     <Col sm="7">
    <Form.Check type="checkbox" label="Move all" name="moveall"  onChange = {handleEditChange}/>
    </Col>
  </Form.Group>
);

    if (SourceCage.NoOfMale > 0){
    
      TheContent.push(
      <Form.Group controlId="Males" as={Row}   >
      <Form.Label column sm="5">{"Male ("+SourceCage.NoOfMale+" )" }</Form.Label>
      <Col sm="7">
        <Form.Control type="number" value={EdNoOfMale} name="male" onChange= {handleEditChange} disabled={DisableEditNumber} />
      </Col>
       </Form.Group>
      );
    }

    if (SourceCage.NoOfFemale > 0){
 
      TheContent.push(
      <Form.Group controlId="Females" as={Row}   >
      <Form.Label column sm="5">{"Female ("+SourceCage.NoOfFemale+" )" }</Form.Label>
      <Col sm="7">
        <Form.Control type="number" value={EdNoOfFemale} name="female" onChange= {handleEditChange} disabled={DisableEditNumber} />
      </Col>
       </Form.Group>
      );
    }

    if (SourceCage.NoOfUnknown > 0){

      TheContent.push(
      <Form.Group controlId="Unknowns" as={Row}   >
      <Form.Label column sm="5">{"Any gender ("+SourceCage.NoOfUnknown+" )" }</Form.Label>
      <Col sm="7">
        <Form.Control type="number" value={EdNoOfUnknown} name="unknown" onChange= {handleEditChange} disabled={DisableEditNumber} />
      </Col>
       </Form.Group>
      );
    }

    if (!TargetCage.PopId  &&  !DisableEditNumber){  
      TheContent.push(
      <Form.Group controlId="PopName" as={Row}   >
      <Form.Label column sm="5">Name for target population </Form.Label>
      <Col sm="7">
        <Form.Control type="text" value={EdTargetPopName} name="popname" onChange= {handleEditChange} />
      </Col>
       </Form.Group>
      );
    }else if (!TargetCage.PopId  &&  DisableEditNumber){
      TheContent.push ( <p>The total population will be moved to cage {" "+TargetCage.Name}</p> )
    }
      









  }


    
    return (
      <>
        
        <Modal show={show}
         onHide={handleClose}
         backdrop="static"
       //  keyboard={false}
         >
  <Modal.Header closeButton>
    <Modal.Title>{TheTitle}</Modal.Title>
  </Modal.Header>
  <Modal.Body>
      <Form>

      {TheContent}
 

      </Form>

  </Modal.Body>


  <Modal.Footer>
  <Button variant="outline-secondary" onClick={handleClose}>
    Close
  </Button>
  <Button variant="outline-secondary" onClick={ handleSave  }>
    Save 
  </Button>
  </Modal.Footer>;



</Modal>

      </>
      
    );
  }
  
 


