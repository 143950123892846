
import React, { useState, useEffect } from "react";
import { Row, Col} from 'react-bootstrap';
import CountTable from "./CountTable";
import { Loading } from "../Components/Loading";
import {getTankName, changeDateStamp, prepData} from "./CounterUtils";
import convertMSE from "../Components/Utils/convertMSE";
import InformationTabContainer from "./InformationTabContainer";
import SessionMain from "./SessionMain";

require ("./SingleCounterView.css")

require ("./Counters.css")














export default function SingleCounterView(props) {

  
    const [isLoading, setLoading] = useState(false);

    const [newDataLoading, setNewDataLoading] = useState(false);
    const [counterRows, setCounterRows] = useState([]);

    const [informationRowData,   setInformationRowData] = useState( {});


    const [mainData, setMainData] = useState (null);
  
    const [newDataLoaded, setNewDataLoaded] = useState(false);
    const [blcFileURL,setBlcFileURL] = useState("");
    const [cntFileURL,setCntFileURL] = useState("");
    const [mseFileURL,setMseFileURL] = useState("");
    const [pdfFileURL,setPdfFileURL] = useState("");
    const [mseS3Path,setMseS3Path] = useState("");
    const [pdfFilePath, setPdfFilePath] = useState("");
    const [blcFilePath, setBlcFilePath] = useState("");
    const [cntFilePath, setCntFilePath] = useState("");
    const [selectedRow, setSelectedRow] = useState (null);

   /* 
    organizationList: [],
    selectedOrg: null,
    isSelectedOrgLoaded: false,
    countersList: [],
    selectedCounter: null,
    promiseResponse: [],
    fileUrls: [],
     selectedRow: null,
    countRecords: false,
    equipment: true
*/



    useEffect(() => {

        let pData = prepData(props.Data)

        setCounterRows (pData);

    
   }, []);



   const getMSEFile = async (mseS3Path, urls) => {

console.log ("Singlecounterview getMseFile  "+mseS3Path+     "      urls: "+JSON.stringify(urls))
    setInformationRowData([]);



    //console.log(mseS3Path, urls);
    let apiName = "vakicloudRdsAPI";
    let path = "/getFile";
    let myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        S3Path: mseS3Path
      }
    };




   // let theUrl = 'https://i84r4clg2d.execute-api.eu-west-1.amazonaws.com/dev/counts';



    let results=null;

    let theUrl = 'https://i84r4clg2d.execute-api.eu-west-1.amazonaws.com/dev/counts/getFile?S3Path='+mseS3Path;


   await fetch(theUrl , { 
        method: 'get', 
       // mode: 'no-cors',
        headers: new Headers({
          //  'authorizationToken': "'"+theGuid + "'"
 
          //  'Content-Type': 'application/x-www-form-urlencoded'
        }) 
      //  body: 'A=1&B=2'
    }).then((res) => {

           

           //results = res.json();
           return res.json();
           //return JSON.stringify(res);
       })
      .then((resJson) => {
    

          if (mseS3Path.toUpperCase().endsWith(".MAIN")){


            setMainData( resJson.success);
            setNewDataLoaded( true);
            setNewDataLoading (false);


          }else {
            let data = convertMSE(resJson.success);
            
              setMainData(null);
              setInformationRowData(data);
              setNewDataLoaded(true);
              setNewDataLoading(false);
              setBlcFileURL(urls.blcFile);
              setCntFileURL(urls.cntFile);
              setMseFileURL(urls.mseFile);
              setPdfFileURL(urls.pdfFile);
              setMseS3Path(mseS3Path);
              setPdfFilePath(urls.pdfFilePath);
              setBlcFilePath(urls.blcFilePath);
              setCntFilePath(urls.cntFilePath);
  

          }




         //  callback( resJson);
       }).catch ((er)=>{
        //callback (er);
        console.log ("Catch: "+er);
       })


  };


  const getSelectedRow = row => {
    

    let newData = true;
    if (!row || row.length == 0)
      newData = false;

    
  

    setSelectedRow (row);
    setNewDataLoading (newData);


  };
  const scrollToBottom = () => {
    let fakeD = document.getElementById("fakeDiv");
    fakeD.scrollIntoView({ behavior: "smooth" });
  };

let CountingInfo = "";

if (newDataLoaded ){
  if (!mainData){
    CountingInfo = <InformationTabContainer
    data={informationRowData}
    pdfFileURL={pdfFileURL}
    mseFileURL={mseFileURL}
    blcFileURL={blcFileURL}
    mseS3Path={mseS3Path}
    isLoading={newDataLoading}
    pdfFilePath={pdfFilePath}
    blcFilePath={blcFilePath}
    cntFilePath={cntFilePath}
    scrollToBottom={scrollToBottom}
    />;
  }else {
  
   
    let m= mainData;
    CountingInfo =  <SessionMain Data = {mainData} getMseFile = {getMSEFile}
  
  />
  }
}




return <div id = "SCwrap">

<Row>
  <Col sm={1}>
  <span id="logo"> VAKI </span> 
  </Col>
  <Col sm={10}>
  <div id="header">
<h5>Fish Counting Results</h5>
<span id="subtitle">
Below you should see the results from one or more more counting sessions from a given counter.  Check the box to the left for details.
</span>
</div>
  </Col>
  <Col sm={1}>
  </Col>
  </Row>
            <CountTable
                CountRows={counterRows}
                isLoading={isLoading}
                getMSEFile={getMSEFile}
                getSelectedRow={getSelectedRow}
              />
              {isLoading && <Loading />}
              {
                CountingInfo

              }
              {newDataLoading && (
                <div className="counters-container__loader">
                  <Loading />
                </div>
              )}

</div>


}