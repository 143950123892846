import React, { useState, useEffect } from "react";
import useFetch from "./useFetch";
import { CED } from "../Components/CED";
import Tables from "./Table";
import { FieldGroup } from "../Components/Forms";
import { Table } from "react-bootstrap";
import { useInput } from "./useInput";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import BootstrapTable from "react-bootstrap-table-next";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import "./CounterTypes.css";
//import awsList from "../sqlData/currentAwsList.json";
//import currentList from "../sqlData/counterID.json";

import aws_exports from "../aws-exports";

Amplify.configure(aws_exports, {});

/* Fyrsta skref Sækja Organisation List og byrta listan. */

var cntUpdates = 0;
var selectedRowIndex = -1;

var errTxt = ["All Fields Must Be Filled - submit cancelled!",  
              "Illegal ID Value - submit cancelled!"
];

export default function CounterTypes(props) {
 

  const [editState, setEditState] = useState(true);
  const [TheTypes, setTheTypes] = useState([]); 

  const [ErrorMsg, setErrorMsg] = useState("");


  const 
  edCounterTypeID = useInput(""),
  edCounterTypeName = useInput(""),
     edChannelCount= useInput(""),
    edCameraCount = useInput(""),
    edChannelWidth = useInput(""),
    edDistanceToCamera = useInput(""),
    edSizeCategoryHighest = useInput(""),
    edSizeCategoryLowest = useInput(""),
    edLineScanrate = useInput(""),
    edAllowSensitivity = useInput(false);

  /* Declare value for the inputs we need to create a new users */

  const createCounterTypeID  = useInput(""),
  createCounterTypeName = useInput(""),
    createChannelCount= useInput(""),
   createCameraCount = useInput(""),
   createChannelWidth = useInput(""),
   createDistanceToCamera = useInput(""),
   createSizeCategoryHighest = useInput(""),
   createSizeCategoryLowest = useInput(""),
   createLineScanrate = useInput(""),
   createAllowSensitivity = useInput("");



  

  /* Create  */
  useEffect(() => {
    //If we change the state remove the org
  //  if (editState === false) {
   //   setAddFarmsToOrg([]);
      //clearUserInputs();
   // }

   loadData()
   clearUserInputs();
  
  }, [cntUpdates]);





  const loadData = async () => {


    //We need an ID for new Population
    
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/getcountertypes";
    
    // https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
    //Get ID for pop
    let myInit = {
    
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
       

        
      }  
    
    };
   
    setErrorMsg("");
    API.get(apiName, path, myInit)
    .then ((response) => {
      setTheTypes (response.message);

    




     
        
     // TrgPop.PopId = newPopId+1;

    
     
    
    
    return;
    
    })
    .catch ((er) => {
      setErrorMsg ("Error fetching counter types "+JSON.stringify(er));
  
    return;
    
    });
    
      
    


};




  function handleRowSelect(obj) {
/*
    console.log ("handleRowSelect obj:" + JSON.stringify(obj) )
    let editOrgName = { target: { value: obj.name } },
      editOrgId = { target: { value: obj.id } },
      editOrgEmail = { target: { value: obj.email } },
      editOrgPhoneNumber = { target: { value: obj.phoneNumber } },
      editOrgAddress = { target: { value: obj.address } },
      editOrgIsActive = { target: { value: obj.isActive } };
    setAddCountersToList(obj.counters);

    editName.onChange(editOrgName);
    editId.onChange(editOrgId);
    editEmail.onChange(editOrgEmail);
    editPhoneNumber.onChange(editOrgPhoneNumber);
    editAddress.onChange(editOrgAddress);
    editIsActive.onChange(editOrgIsActive);

   */ 
    //Get the farms that are connected to the organisation
    /*         const simpleFarmsSelected = obj.farms.items.map( farm => {
            return {
                value: `${farm.farmID}`,
                label:  farm.farmName
            } 
        }); */
    // Add the farms to AddFarmsToOrg list which Select uses to show which farms are
    // added or remove
    //setAddFarmsToOrg(simpleFarmsSelected);
    // We use the originalFarmOrg to compare with the new list.
    //setOriginalFarmOrg(simpleFarmsSelected);
  }

function verify (o){



  if (o.Allow_Sensitivity == null){

    o.Allow_Sensitivity = 0;
  }


  
  if (!o.Counter_Name ){
     return false;
  }
 

  if (o.Counter_Type_ID == null || Number.isNaN (o.Counter_Type_ID))
    return 1;

  if (o.Channel_Count == null || Number.isNaN (o.Channel_Count))
    return 1;
 
  if (o.Camera_Count   == null || Number.isNaN (o.Camera_Count))
    return 1;         

  if (o.Channel_Width  == null || Number.isNaN (o.Channel_Width))
    return 1;          
  if (o.Distance_To_Camera == null  || Number.isNaN (o.Distance_To_Camera))
    return 1;           
  if (o.Size_Category_Highest  == null  || Number.isNaN (o.Size_Category_Highest))
    return 1;      
  if (o.Size_Category_Lowest  == null || Number.isNaN (o.Size_Category_Lowest))
    return 1;  
  if (o.Line_Scanrate   == null || Number.isNaN (o.Line_Scanrate))
    return 1; 
  if (o.Allow_Sensitivity  == null)
    return 1;


   if (o.Counter_Type_ID <0 || o.Counter_Type_ID > 127)
    return 2;

return 0;

}

  const handleSubmitCreate = async e => {
    e.preventDefault();

    
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/putcountertype";






    let myInit = {
      
      
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters:{},  

      body: {
        




        Counter_Type_ID : Math.round(parseFloat(createCounterTypeID.value)),
        Counter_Name  :  createCounterTypeName.value,               
        Channel_Count  : Math.round(parseFloat( createChannelCount.value )),           
        Camera_Count   : Math.round( parseFloat(createCameraCount.value )),            
        Channel_Width   : Math.round( parseFloat(createChannelWidth.value )),             
        Distance_To_Camera : Math.round( parseFloat(createDistanceToCamera.value)),           
        Size_Category_Highest  : Math.round(parseFloat(createSizeCategoryHighest.value)),         
        Size_Category_Lowest   : Math.round(  parseFloat(createSizeCategoryLowest.value )),   
        Line_Scanrate   : Math.round( parseFloat(createLineScanrate.value )),
        Allow_Sensitivity :  ((createAllowSensitivity.value == true) ? 1:0)

 



      }
    };

    let err = verify(myInit.body);
    if ( err != 0){

      setErrorMsg(errTxt[err-1]);
      return;
    }



    API.post(apiName, path, myInit)
    .then((response) => {
   

      if (!response.message.errno){
        clearUserInputs();
        loadData();
      }else{
         let  s = "Error inserting type ("+response.message.errno+"): "+response.message.code;
         console.log ("Error inserting type:"+ JSON.stringify(response));
         setErrorMsg (s);
      }



    })
    .catch ((er) => {
      setErrorMsg ("Error submitting type "+JSON.stringify(er));
    
  
    });


  
  };

  const handleSubmitEdit = async e => {
    e.preventDefault();

    let apiName = "vakicloudRdsAPI";
    let path = "/counters/updatecountertype";






    let myInit = {
      
      
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters:{},  

      body: {
        




        Counter_Type_ID : Math.round(parseFloat(edCounterTypeID.value)),
        Counter_Name  :  edCounterTypeName.value,               
        Channel_Count  : Math.round(parseFloat( edChannelCount.value )),           
        Camera_Count   : Math.round( parseFloat(edCameraCount.value )),            
        Channel_Width   : Math.round( parseFloat(edChannelWidth.value )),             
        Distance_To_Camera : Math.round( parseFloat(edDistanceToCamera.value)),           
        Size_Category_Highest  : Math.round(parseFloat(edSizeCategoryHighest.value)),         
        Size_Category_Lowest   : Math.round(  parseFloat(edSizeCategoryLowest.value )),   
        Line_Scanrate   : Math.round( parseFloat(edLineScanrate.value )),
        Allow_Sensitivity :  ((edAllowSensitivity.value == true) ? 1:0)

 



      }
    };


    let err = verify(myInit.body);
    if ( err != 0){
  
      setErrorMsg(errTxt[err-1]);
      return;
    }


    API.post(apiName, path, myInit)
    .then((response) => {
   

      if (!response.message.errno){
        clearUserInputs();
        loadData();
      }else{
         let s = "Error updating type ("+response.message.errno+"): "+response.message.code;
         console.log ("Error updating:"+ JSON.stringify(response));
         setErrorMsg (s);
      }


    })
    .catch ((er) => {
      setErrorMsg ("Error submitting type "+JSON.stringify(er));
    

  
    });


 
  };






  const clearUserInputs = () => {

    edCounterTypeID.reset();
    edCounterTypeName .reset();
    edChannelCount.reset();
   edCameraCount .reset();
   edChannelWidth .reset();
   edDistanceToCamera .reset();
   edSizeCategoryHighest .reset();
   edSizeCategoryLowest .reset();
   edLineScanrate .reset();
   edAllowSensitivity .reset(false);

 /* Declare value for the inputs we need to create a new users */
 createCounterTypeID.reset();
    createCounterTypeName .reset();
   createChannelCount.reset();
  createCameraCount .reset();
  createChannelWidth .reset();
  createDistanceToCamera .reset();
  createSizeCategoryHighest .reset();
  createSizeCategoryLowest .reset();
  createLineScanrate .reset();
  createAllowSensitivity .reset(false);
  setErrorMsg("")

  };

  const handleInput = () => {
    //editIsActive.onChange({ target: { value: !editIsActive.value } });
  };


  const rowEvents = {
    onClick: (e, row, rowIndex) => {
 
      let editOrgName = { target: { value: row.Counter_Name } },
      edOrgCounterTypeID  = { target: { value: row.Counter_Type_ID } },
      edOrgChannelCount= { target: { value: row.Channel_Count } },
     edOrgCameraCount = { target: { value: row.Camera_Count } },
     edOrgChannelWidth = { target: { value: row.Channel_Width } },
     edOrgDistanceToCamera = { target: { value: row.Distance_To_Camera } },
     edOrgSizeCategoryHighest = { target: { value: row.Size_Category_Highest } },
     edOrgSizeCategoryLowest = { target: { value: row.Size_Category_Lowest } },
     edOrgLineScanrate = { target: { value: row.Line_Scanrate } },
     edOrgAllowSensitivity = { target: { value: row.Allow_Sensitivity } };


     edCounterTypeID.onChange(edOrgCounterTypeID);
     edCounterTypeName.onChange(editOrgName);
     edChannelCount.onChange(edOrgChannelCount);
     edCameraCount .onChange(edOrgCameraCount);
     edChannelWidth .onChange(edOrgChannelWidth);
     edDistanceToCamera .onChange(edOrgDistanceToCamera);
     edSizeCategoryHighest .onChange(edOrgSizeCategoryHighest);
     edSizeCategoryLowest .onChange(edOrgSizeCategoryLowest );
     edLineScanrate .onChange(edOrgLineScanrate);
     edAllowSensitivity .onChange(edOrgAllowSensitivity);
     selectedRowIndex = rowIndex;

/*

     let editOrgName = { target: { value: obj.name } },
     editOrgId = { target: { value: obj.id } },
     editOrgEmail = { target: { value: obj.email } },
     editOrgPhoneNumber = { target: { value: obj.phoneNumber } },
     editOrgAddress = { target: { value: obj.address } },
     editOrgIsActive = { target: { value: obj.isActive } };
   setAddCountersToList(obj.counters);

   editName.onChange(editOrgName);
   editId.onChange(editOrgId);
   editEmail.onChange(editOrgEmail);
   editPhoneNumber.onChange(editOrgPhoneNumber);
   editAddress.onChange(editOrgAddress);
   editIsActive.onChange(editOrgIsActive);
*/



   
    }
  };


  const selectRow = {
    mode: "radio",
    hideSelectColumn: true,
    clickToSelect: true,
  //  bgColor: "lightgrey",
    onSelect: (row, isSelect, rowIndex, e) => {
      



    }


  };

  function handleRowSelect(obj) {
/*
    console.log ("handleRowSelect obj:" + JSON.stringify(obj) )
    let editOrgName = { target: { value: obj.name } },
      editOrgId = { target: { value: obj.id } },
      editOrgEmail = { target: { value: obj.email } },
      editOrgPhoneNumber = { target: { value: obj.phoneNumber } },
      editOrgAddress = { target: { value: obj.address } },
      editOrgIsActive = { target: { value: obj.isActive } };
    setAddCountersToList(obj.counters);

    editName.onChange(editOrgName);
    editId.onChange(editOrgId);
    editEmail.onChange(editOrgEmail);
    editPhoneNumber.onChange(editOrgPhoneNumber);
    editAddress.onChange(editOrgAddress);
    editIsActive.onChange(editOrgIsActive);
    */
    //Get the farms that are connected to the organisation
    /*         const simpleFarmsSelected = obj.farms.items.map( farm => {
            return {
                value: `${farm.farmID}`,
                label:  farm.farmName
            } 
        }); */
    // Add the farms to AddFarmsToOrg list which Select uses to show which farms are
    // added or remove
    //setAddFarmsToOrg(simpleFarmsSelected);
    // We use the originalFarmOrg to compare with the new list.
    //setOriginalFarmOrg(simpleFarmsSelected);
  }








  let columns = [
    {dataField: "Counter_Type_ID",
    text: "ID"  
  },
    {
    dataField: 'Counter_Name',
    text: 'Name'

  },
  {
    dataField: 'Channel_Count',
    text: 'Channels'

  }, 
  {
    dataField: 'Camera_Count',
    text: 'Cameras'
  
  }, 
  {
    dataField:  'Channel_Width',
    text: 'ChannelWidth'
  
  }, 
  {
    dataField:  'Distance_To_Camera',
    text: 'Dist. To Camera'
  
  }, 
  {
    dataField:  'Size_Category_Highest',
    text: 'Size Category Highest'
  
  }, 
  {
    dataField:  'Size_Category_Lowest',
    text: 'Size Category Lowest'
  
  }, 
  {
    dataField:  'Line_Scanrate',
    text: 'Scanrate'
  
  }, 
  {
    dataField:  'Allow_Sensitivity',
    text: 'Sensitivity'
  
  }



];


/*
    edCameraCount = useInput(""),
    edChannelWidth = useInput(""),
    edDistanceToCamera = useInput(""),
    edSizeCategoryHighest = useInput(""),
    edSizeCategoryLowest = useInput(""),
    edLineScanrate = useInput(""),
    edAllowSensitivity = useInput("");

*/






  return (
    <div className="flex-container ">
      <div className="admin-container__table">
        <div className="flex-container">
          <CED
            text="Create New"
            font="fa-user-plus"
            onClick={() => {setEditState(false); setErrorMsg ("")}}
          />
          <CED
            text="Edit Type"
            font="fa-edit"
            onClick={() => {setEditState(true);setErrorMsg ("")}}
          />
          
        </div>
        <div className="TableWrap">
        <BootstrapTable
            hover
            keyField='Counter_Type_ID'
            data={TheTypes}
            columns={columns}
            rowEvents={rowEvents}
            selectRow = {selectRow}
           
           

          />
          </div>
      </div>
  

      <form onSubmit={editState ? handleSubmitEdit : handleSubmitCreate}>
          <div className="flex-container">

          <FieldGroup
              id={"typeID"}
              type={"number"}
              label={"Counter's ID"}
          
              value={editState ? `${edCounterTypeID.value}` : `${createCounterTypeID.value}`}
              //validateion={null}
              placeholder="Counter's ID"
              onChange=  {editState ? edCounterTypeID.onChange : createCounterTypeID.onChange}
              isDisabled={editState ? true : false}
              
            />

            <FieldGroup
              id={"counterTypeName"}
              type={"text"}
              label={"Type Name"}
                

              className = {"FiledgroupClass"}

              value={editState ? edCounterTypeName.value : createCounterTypeName.value}
              //validateion={this.nameValidation()}
              placeholder="Enter Type Name"
              onChange={editState ? edCounterTypeName.onChange : createCounterTypeName.onChange}
             
            />
            <FieldGroup
              id={"channelCount"}
              type={"number"}
              label={"Number of channels"}
          
              value={editState ? `${edChannelCount.value}` : `${createChannelCount.value}`}
              //validateion={null}
              placeholder="Channel Count"
              onChange={editState ? edChannelCount.onChange : createChannelCount.onChange}
              
            />
           <FieldGroup
              id={"cameraount"}
              type={"number"}
              label={"Number Of Cameras"}
          
              value={editState ? `${edCameraCount.value}` : `${createCameraCount.value}`}
              //validateion={null}
              placeholder="Number Of Cameras"
              onChange={editState ? edCameraCount.onChange : createCameraCount.onChange}
              
            />

            <FieldGroup
              id={"channelWidth"}
              type={"number"}
              label={"Cahannel Width"}
             
              value={editState ? `${edChannelWidth.value}` : `${createChannelWidth.value}`}
              //validateion={null}
              placeholder="Enter Channel Width"
              onChange={editState ? edChannelWidth.onChange : createChannelWidth.onChange}
              
            />

            <FieldGroup
              id={"cameraDistance"}
              type={"number"}
              label={"Distance to Camera"}
             
              value={editState ? `${edDistanceToCamera.value}` : `${createDistanceToCamera.value}`}
              //validateion={null}
              placeholder="Enter Distance to camera"
              onChange={editState ? edDistanceToCamera.onChange : createDistanceToCamera.onChange}
              
            />


<FieldGroup
              id={"sizeCategoryHighest"}
              type={"number"}
              label={"Size Category Highest"}
             
              value={editState ? `${edSizeCategoryHighest.value}` : `${createSizeCategoryHighest.value}`}
              //validateion={null}
              placeholder="Size Category Highest"
              onChange={editState ? edSizeCategoryHighest.onChange : createSizeCategoryHighest.onChange}
              
            />
            
            <FieldGroup
              id={"sizeCategoryLowest"}
              type={"number"}
              label={"Size Category Lowest"}
             
              value={editState ? `${edSizeCategoryLowest.value}` : `${createSizeCategoryLowest.value}`}
              //validateion={null}
              placeholder="Size Category Lowest"
              onChange={editState ? edSizeCategoryLowest.onChange : createSizeCategoryLowest.onChange}
              
            />
            
            <FieldGroup
              id={"lineScanrate"}
              type={"number"}
              label={"Line Scanrate"}
             
              value={editState ? `${edLineScanrate.value}` : `${createLineScanrate.value}`}
              //validateion={null}
              placeholder="Enter Line Scanrate"
              onChange={editState ? edLineScanrate.onChange : createLineScanrate.onChange}
              
            />
            
        
            
            <FieldGroup
              id={"allowSensitivity"}
              type={"checkbox"}
              label={"Allow Sensitivity"}
              style = {"color:'red'; padding: '3em' "}
              
             
              value={editState ? `${edAllowSensitivity.value}` : `${createAllowSensitivity.value}`}
              //validateion={null}
               onChange={editState ? edAllowSensitivity.onChange : createAllowSensitivity.onChange}
              
            />


          </div>

        


          <div className = "error"> {ErrorMsg} </div>

     
          <input
            type="submit"
            value={editState ? "Submit Edited Type" : "Submit New Type"}
            
          />

          
        </form>

    </div>
  );
}




// Nota þetta til að importa sql data ef þarf
/*
import sqldata from "../sqlData/DIM.org.json";

const importSQLdata = async () => {
    await sqldata.map(
        org => {        
            API.graphql(graphqlOperation(createOrganisation, { input: org }))
        }
    );
}
<button onClick={importSQLdata}> Import SQL data </button> 
*/
/*
                    <div>
                <h5> <i className="fa fa-plus" /> Add/Remove Counters To/From Organisations </h5>
                <Select
                    className={"select-react-component"}
                    placeholder="Select Counters"
                    //value={addFarmsToOrg}
                    options={simpleCounterList.data.listCounters.items}
                    isMulti
                    isSearchable
                    onChange={addCounterConnection}
                    isClearable={false}
                />
            </div>
    
    */
