import React, { useState, useEffect } from "react";
import useFetch from "./useFetch";
import { CED } from "../Components/CED";
import Tables from "./Table";
import { FieldGroup } from "../Components/Forms";
import { Table, NumericInput } from "react-bootstrap";
import { useInput } from "./useInput";

import Row from "react-bootstrap/Row";
import Select from "react-select";
import moment from "moment";
import _ from "lodash";
import "./Threshold.css";
//import awsList from "../sqlData/currentAwsList.json";
//import currentList from "../sqlData/counterID.json";



export default function Threshold(props) {

    const [edValue, setEdValue] = useState("");
    const [edChecked, setEdChecked] = useState(false);

var title = props.Title;

useEffect(() => {

    setEdValue (props.Value);
    setEdChecked (props.Active);
    title = props.Title;

 
  
  }, [props]);


   props.Callback (title, edChecked, edValue)



   return (

    <div className="single-threshold">

<Row>
    <div  className="firstrow">
        <div id="label"> {title} </div>  
        <div id = "check">
        <input
                        
                        type="checkbox"
                        checked={edChecked}
                        onChange={(e)=>setEdChecked(e.target.checked)}
                        
                      />

             </div> 
        </div>
    </Row>
    <Row>
        <div id="value">
<input
 type="number"
 value = {edValue}
 onChange={(e)=>setEdValue(e.target.value)}
 className="thenumber"
 //style={{width: '10em'}}
    />

        </div>
        </Row>



        </div>


   ) 



}