import React, { useState, useEffect } from "react";

//import ReactDOM from 'react-dom';

import AppWithAuth from './AppWithAuth';

import Amplify, { API } from "aws-amplify";

import aws_exports from "./aws-exports";

import SingleCounterView from "./Containers/SingleCounterView"


import  "./Containers/LargeSpinner.css";

Amplify.configure(aws_exports, {});






const fetchit = async (params, callback) => {



  if (params.length == 0 || params[0] != "A"){
 
    callback( {
      statusCode: 200,
      msg: "Login"
    });
    return;
  }

  
  if (params.length != 38 || params[0] != "A"  ){

    callback( {
      statusCode: 400,
      msg: "Bad request"
    }
    );
    return;
  }

  

    let apiName = "SingleCounterAssess";
    let path = "/counts";

    let theGuid = params.substring(2);




    let theUrl = 'https://i84r4clg2d.execute-api.eu-west-1.amazonaws.com/dev/counts';


    let results=null;


   await fetch( theUrl+"?A="+theGuid, { 
        method: 'get', 
        //mode: 'no-cors',
        /*
        headers: new Headers({
          //  'authorizationToken': "'"+theGuid + "'"
 
          //  'Content-Type': 'application/x-www-form-urlencoded'
        })
        */ 
      //  body: 'A=1&B=2'
    }).then((res) => {

          //return res.text();
           //results = res.json();
           return res.json();
           //return JSON.stringify(res);
       })
      .then((resJson) => {
       
           callback( resJson);
       }).catch ((er)=>{
        console.log ("Catch: "+er);
        callback (er);
        
       })






}



export default function  AppLauncher(props) {
  const [IsLoading, setLoading] = useState(true);

  const [Data, setData] = useState(null);

    
  let TheApp = <AppWithAuth />;

  





useEffect(() => {
  var params = window.location.search.substring(1).trim();
  fetchit (params, callback);
}, []);






const callback = (results) => {
  setData (results);
  setLoading(false);
  
}





   if (IsLoading){
     TheApp = <div className="spinner-wrap">  <div className="loader"></div> </div>
   }else {

    
    if (Data && Data.statusCode > 200 ){
      TheApp = <div> {JSON.stringify (Data, 1, 1)}  </div>
    }else if (Data && Data.statusCode == 200 && Data.obj && Data.obj.length > 0){
      TheApp = <SingleCounterView  Data= {Data.obj} />
    }else
      TheApp = <AppWithAuth/>;
  }




//TheApp = <h3>{params} </h3>

    return  <div> {TheApp}  </div>



}