import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import Creatable from 'react-select/creatable'

//import Select from "react-select";

import Amplify, { graphqlOperation, API } from "aws-amplify";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ThemeContextProvider from './contexts/ThemeCtxt';

import { Loading } from "../Components/Loading";
import Form from "react-bootstrap/Form";



import moment from "moment";

import "./Feeding.css";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { listUsers, ListSimpleOrg } from "../customGQL/customQueries";


import aws_exports from "../aws-exports";
//import { defaultProps } from "react-select/src/Select";


Amplify.configure(aws_exports, {});



let ModifyDesc = "";  //

const SetModifyDesc = (strName)=>{
    if (ModifyDesc.indexOf(strName) >= 0)
        return;
    if (ModifyDesc.length > 0 )
      ModifyDesc+= "; ";

    ModifyDesc += strName;

    if (ModifyDesc.length >= 120)
      ModifyDesc = ModifyDesc.substr(0,120);

}


const EmptyPop = {

  FarmID: 0,
  PopId: -1,
  Active: 0,
  PopName: "",

  Description: "",
  ProfileId: "",
  SGRId: "",
  AutoUpdate: 0,
  FeedTotal: 0,
  NoOfFish: 0,
  AvarageWeight: 0,
  FCR: 1,
  CorrFactor: 1,
  CurrentCageId: "",
  ParentPopulation: 0,

  Origin: 0,
  NoOfMale: 0,
  NoOfFemale: 0,
  NoOfUnknown: 0,
  Temperature: 0

}




const AddFishOperation = {
  NoOfFish: 0,
  Gender:'U',
  Operation:  'A',  //Add, Harvest, Mortality 
  Note:""
}


const loadSiloUseMap = (props) => {

  let _SiloUseMap = [];
  for (var i = 0; i < props.Silos.length; i++)
    _SiloUseMap.push(false);




  if (props.CageInfo.SiloArray) {
    let strArr = props.CageInfo.SiloArray.split(',');

    for (var i = 0; i < strArr.length; i++) {
      try {
        var num = parseInt(strArr[i]);
  
        let idx = props.Silos.map(function(e) { return e.SiloId; }).indexOf(num);
   
 


        if (idx >= 0)
          _SiloUseMap[idx] = true;
      } catch (e) { ; }
    }
  }



  return _SiloUseMap;
}



export default function PopEdit(props) {


  const [AddFishOp, setAddFishOp] = useState(null);

  const [IsLoading, setLoading] = useState(true);
  const [ErrorToShow, setErrorToShow] = useState("");
  const [ShowMoveFish, setShowMoveFish] = useState(false);
  const [ShowSiloEdit, setShowSiloEdit] = useState(false);
  const [ShowProfileSelect, setShowProfileSelect] = useState(false);
  const [ShowEditPopName, setShowEditPopName] = useState(false);
  const [ShowSGRSelect, setShowSGRSelect] = useState(false);

  const [ThePop, setThePop] = useState(EmptyPop);
  const [TheEditPop, set_TheEditPop] = useState (EmptyPop);


  const[Ed_AddRemove, set_Ed_AddRemove] = useState(0);
  const[Ed_RadioAddRemove, set_Ed_RadioAddRemove] =  useState([true, false, false]); 
  const[Ed_NoteAddRemove, set_Ed_NoteAddRemove] = useState("");





  //let ProfileIdList = [];
  let ProfileIdList = props.ProfileList.map ((pidObj, index) => { return{label: pidObj.ProfileId, value: pidObj.ProfileId}});

  let SGRIdList = props.SGRIdList.map ((pidObj, index) => { return{label: pidObj.SGRId, value: pidObj.SGRId}});

  let PopNameList = props.PopNameList.map ((pidObj, index) => { return{label: pidObj.PopName, value: pidObj.PopName}});

  const [ed_SiloUseMap, setEdSiloUseMap] = useState([]);

  const [SiloUseMap, setSiloUseMap] = useState([]);




  const IsModified = () => {

    if (IsLoading)
      return false;

    var propertyNames = Object.getOwnPropertyNames(ThePop);
    var len = propertyNames.length;

    for (var i=0; i<len; i++){
      var pn = propertyNames[i]
      if (ThePop[pn]  != TheEditPop[pn]  ){
      
          return true;
      }
    }


    for (var i = 0; i < SiloUseMap.length; i++)
    
      if (SiloUseMap[i] != ed_SiloUseMap[i]){

        return true;
      }



    return false;

  }

  

  useEffect(() => {

//console.log ("PopEdit CageInfo: "+JSON.stringify(props.CageInfo, null, 2))
  
    let S = loadSiloUseMap(props);

    setSiloUseMap(S);
    setEdSiloUseMap(S);
    set_Ed_RadioAddRemove ([true, false, false]);
    set_Ed_AddRemove (0);
    setAddFishOp(null);

  

    loadData();

  }, [props.CageInfo.Name]);



/*
dæmi:
 CageInfo: {
  "CageId": "E1",
  "Name": "E1",
  "PopId": 352,
  "Active": 1,
  "PopName": "12-1 merktur",
  "SiloArray": "4",
  "NoOfFish": 9500,
  "AvarageWeight": 3.2739,
  "pipes": null,
  "TheDate": "2021-01-29T00:00:00.000Z",
  "grams": null
}
*/


/*

SELECT PopName  FROM feeding.tblPopulation
where NOT( (substr(PopName, 5,1) = '.' ) AND (substr(PopName, 8,1) = '.') AND (substr(PopName, 1,1) = '2'))
group by PopName
*/

function pad(num, size){     return ('000000000' + num).substr(-size); }

  const fetchPopDetails = () => {
    //<PopEdit CageName = {ShowCage.Name} PopId = {ShowCage.PopId}  FarmID={props.TheFarm} ></PopEdit>


    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/getpopulation";

    if (!props.CageInfo.PopId) {
      var tempPop = {...EmptyPop};
      tempPop.PopName = moment().format("YYYY.MM.DD")+ "-"+props.CageInfo.Name;
      tempPop.FarmID = props.FarmID; 
      tempPop.CurrentCageId = props.CageInfo.CageId;
      setThePop(tempPop);
      


      set_TheEditPop(tempPop);
      setLoading(false);
      return;
    }

    var today = new Date(); 
    var strDate = today. getFullYear()+'-'+ pad((today. getMonth()+1),  2 )+'-'+pad (today.getDate(), 2);

    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        PopID: props.CageInfo.PopId,
        Today: strDate,
        Pipes: props.CageInfo.pipes

      }

    };

    API.get(apiName, path, myInit)
      .then(response => {


        setThePop(response[0]);
        set_TheEditPop(response[0])  


     

        setLoading(false);



  






      })
      .catch((err) => {
        console.log("Not GOT THePop: " + JSON.stringify(err));
        setLoading(false)
      })

  };


 
 
  const handleCalculate = () =>{
    SetModifyDesc("CalculateFeeding");
    setLoading(true);

/*
     queryStringParameters: {
        // OPTIONAL
        SGR_id: TheEditPop.SGRId,
        AvgWgt: TheEditPop.AvarageWeight,
        Temperature: TheEditPop.Temperature

      }
*/

    //Fetch SGR value for the calculation
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/sgrvalue";

 
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        Wgt: Math.round (TheEditPop.AvarageWeight*1000),
        Temp: TheEditPop.Temperature, 
        SGRId: TheEditPop.SGRId
      }
    };

    API.get(apiName, path, myInit)
      .then(response => {


      setLoading(false);





      if (!response || !response[0] || !response[0][0] ||   (response[0][0].SGRValue == null)  ){
        alert ("Error occured accessing SGR table!")
         return;
      }


      if (response[0][0].SGRValue == 0){
        alert (" Can't get a SGR value for the calculations!  Check if temperature and the average weight fits for selected SGR table");
        return;
      }
      
         


      let sgr = response[0][0].SGRValue;
    

      let corrFactor = 1.0;
        
      if (TheEditPop.CorrFactor != null)
          corrFactor = TheEditPop.CorrFactor;  
    

      let feeding = (TheEditPop.AvarageWeight*TheEditPop.NoOfFish*(sgr /100.0) * TheEditPop.FCR * corrFactor);

     

      let tmpPop = {...TheEditPop};
      tmpPop.FeedTotal = feeding;

      set_TheEditPop(tmpPop);


        




        


  






      })
      .catch((err) => {
        console.log("SGR valueNot GOT THePop: " + JSON.stringify(err));
        setLoading(false)
      })







  }

  const handleRadioAddRemove = (e) => {

    
      var ll = new Array(3);
      for (var i=0; i<3; i++){
        if (i == parseInt (e.target.name) )
          ll[i] = e.target.checked
        else
          ll[i] = !e.target.checked;

      }


      set_Ed_RadioAddRemove (ll);

  }

  const handleAddRemoveClick  = (e) => {




      if (Ed_AddRemove == 0)
        return;
      let num = Ed_AddRemove;
      num = Math.round(num);
      if (e.target.name != "Add"){
        let nn = num;  

        //Prevent negative numbers of fish:
        if (Ed_RadioAddRemove [0]){
          if (nn > TheEditPop.NoOfUnknown)
             nn =  TheEditPop.NoOfUnknown;
        }else if (Ed_RadioAddRemove [1]){
          if (nn > TheEditPop.NoOfMale)
            nn =  TheEditPop.NoOfMale;
        } else if (Ed_RadioAddRemove [2]){  
          if (nn > TheEditPop.NoOfFemale)
            nn =  TheEditPop.NoOfFemale;
        }else
          return;
          

        if (nn != num){
          num=nn;
          set_Ed_AddRemove(num);
        }
        num *= -1;
      }
        
     
      var strType="";
      var strGender = "";
      var totalGender = num;
      if (Ed_RadioAddRemove [0]){
        strGender =  'NoOfUnknown';
        totalGender += TheEditPop.NoOfUnknown;
         strType = "U";
      }else if (Ed_RadioAddRemove [1]){
        strGender =  'NoOfMale';
        totalGender += TheEditPop.NoOfMale;
        strType = "M";
      } else if (Ed_RadioAddRemove [2]){  
        strGender =  'NoOfFemale';
        totalGender += TheEditPop.NoOfFemale;
        strType = "F";
      }else
        return;
 

    let tmpAddOp = {...AddFishOperation};
    
    tmpAddOp.NoOfFish = num;
    tmpAddOp.Gender = strType;
    tmpAddOp.Operation =  e.target.name.substr(0,1); 

    SetModifyDesc("NoOfFish");
    setAddFishOp (tmpAddOp);
    set_TheEditPop(prevState => ({...prevState, [strGender]: totalGender,  NoOfFish: TheEditPop.NoOfFish+num }) )

    //set_Ed_AddRemove (0);
    /*
    let note = Ed_NoteAddRemove;
    note += "{"+strType+","+e.target.name[0]+":"+Math.abs(num)+"}";
    set_Ed_NoteAddRemove (note); 
*/




  }


  const handleEditChange = e => {
    let {type, name, value, min } = e.target;

    if (min){
      if (value < min)
        value = min;
    }


    SetModifyDesc(name);
    set_TheEditPop (prevState => ({
        ...prevState,
        [name]:  (type == "number" ? parseFloat(value): value)
    }));
};



const onSave = () => {










    




    
    if (TheEditPop.NoOfFish <= 0){
       //Detach pop from cage
       TheEditPop.CurrentCageId = null;
    }


    if (!props.CageInfo.PopId)
      CreatePop();
    else
      PutPop ();  

    setSiloUseMap(ed_SiloUseMap)
    //Check if silo setting modified
    let siloModified = false;
    let siloArrStr = "";
    let siloBitMap = 0;
    var finish = false;
          var numSilos = ed_SiloUseMap.length;
          for (var k=0; k<numSilos; k++){
            if (ed_SiloUseMap[k]){
              if (siloArrStr.length > 0)
                siloArrStr += ","+ props.Silos[k].SiloId ;
              else 
                siloArrStr += props.Silos[k].SiloId ;

              siloBitMap |= 1<<k;  
            }
          }
          props.CageInfo.SiloArray = siloArrStr;
          
          PutCageSilo (siloArrStr, siloBitMap)
          siloModified = true

    const row = {...props.CageInfo};
    row.PopName = TheEditPop.PopName;
    row.NoOfFish = TheEditPop.NoOfFish;
    row.Active = TheEditPop.Active;
    row.AvarageWeight = TheEditPop.AvarageWeight;




    setAddFishOp (null);
    set_Ed_AddRemove(0);    
    set_Ed_NoteAddRemove ("");  

   // props.updateParent(row);


    props.UpdateDash();
   



  }


const  PutCageSilo = async (siloArrStr, siloBitMap)=>{


  let setString = "FarmID = "+props.FarmID;
  setString += ",SysIdx = 0"; // ATHATH BG  +props.CageInfo.SysIdx;
  setString += ",CageId = '"+ ThePop.CurrentCageId + "'";
  setString += ",SiloArray = '"+ siloArrStr+"'";
  setString += ",PlcSiloFlags = "+ siloBitMap;



  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/updatecagesilo";
  let myInit = {

    // OPTIONAL
    headers: {
      
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      /*
      Counter_ID: 9998,
      Organisation_ID: 6,
      Product_Serial:  "",
      Description: 'TestCounter7',
      Farm_ID: 0,
      Type_ID: 1,
      Process_Data: 1
      */
     
      FarmID:props.FarmID,
      PopID:props.CageInfo.PopId


    }  ,

    





    body: {

      values: setString

    }
   




  };



  API.post(apiName, path, myInit)
  .then ((response) => {
  

      setThePop(TheEditPop);

  })
  .catch ((er) => {
    console.log ("Catch AFTER cagesilo PUT er: "+JSON.stringify(er));

  })


}


const  PutPop = async () => {


  let setString = "";

  let modifiedTemp = null;



  let pnArr = Object.getOwnPropertyNames(ThePop);
  let pnLen = pnArr.length;

  for (var i=0; i<pnLen; i++){
    let pn = pnArr[i];
 
    if (ThePop[pn] != TheEditPop[pn]){
      if (pn != "Temperature") //Because it belongs to the cage
        setString += ","+pn+"= '"+TheEditPop[pn]+"'" ;
      else
        modifiedTemp =   TheEditPop[pn];
    }

  }





  if (setString.length == 0 &&  modifiedTemp == null)
    return;

  if (setString[0] === ',' )
    setString = setString.substr(1);  



  

  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/updatepopulation";
  let myInit = {

    // OPTIONAL
    headers: {
      
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      
      FarmID:props.FarmID,
      PopID:props.CageInfo.PopId,
      CageId: TheEditPop.CurrentCageId

    }  ,

    





    body: {

      values: setString,
      temp: modifiedTemp

    }
    
    /*
    , 
    body : {values:  setString}
    */




  };

  API.post(apiName, path, myInit)
  .then ((response) => {
      

      setThePop(TheEditPop);

  })
  .catch ((er) => {
    console.log ("Catch AFTER PUT er: "+JSON.stringify(er));

  })


}






const JustTest = () => {


 

  
  //We need an ID for new Population
  
  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/simpleget";
  //let path = "/counters";
  
  // https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
  //Get ID for pop
  let myInit = {
  
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      FarmID: 10003,
      What: "MAX(PopId) as PopId",
      Table: "tblPopulation" 
    }  
  
  };

  
  API.get(apiName, path, myInit)
  .then ((response) => {
  
    let newPopId = response[0].PopId +1;
   // TrgPop.PopId = newPopId+1;
 
  
  
  return;
  
  })
  .catch ((er) => {
  console.log ("Catch AFTER JustTest Simpleget PUT er: "+JSON.stringify(er));
  
  alert ("Error JustTest  - Not saved");
  return;
  
  });
  
    
  }


const CreatePop = () => {




//We need an ID for new Population

let apiName = "vakicloudRdsAPI";
let path = "/counters/feeding/simpleget";

// https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters
//Get ID for pop
let myInit = {

response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {
    FarmID:props.FarmID,
    What: "MAX(PopId) as PopId",
    Table: "tblPopulation" 
  }  

};


API.get(apiName, path, myInit)
.then ((response) => {

  let newPopId = response[0].PopId +1;
 // TrgPop.PopId = newPopId+1;

  InsertPop(newPopId)


return;

})
.catch ((er) => {
console.log ("Catch AFTER Simpleget PUT er: "+JSON.stringify(er));

alert ("Error Creating Population!  - Not saved");
return;

});

  
}

/*
const PostPop = () => {


console.log ("Kominn í postpop")



  let anyMove = false;
  

  if (AddFishOp != null  &&  AddFishOp.NoOfFish != 0){
      anyMove = true;
  }
    //no change in number of fish

  let moved =  [];
  for (let i = 0;i<3; i++){
      if (anyMove){
          if ((AddFishOp.Gender === 'M' && i == 0)   
              || (AddFishOp.Gender === 'F' && i == 1)
              || (AddFishOp.Gender === 'U' && i == 2)
            ){
              moved.push(AddFishOp.NoOfFish);
            } else {
              moved.push(0);
            }
      }else 
        moved.push(0)
  }



  let tmpPop = {...TheEditPop};


  //Find ambiguity in number of fish
  if (tmpPop.NoOfMale < 0)
    tmpPop.NoOfMale = 0;
  if (tmpPop.NoOfFemale < 0)
    tmpPop.NoOfFemale = 0;
  if (tmpPop.NoOfUnknown < 0)
    tmpPop.NoOfUnknown = 0;


  tmpPop.NoOfFish =  tmpPop.NoOfUnknown+ tmpPop.NoOfFemale+ tmpPop.NoOfMale;


  let CageId = tmpPop.CurrentCageId;
  let strNote = Ed_NoteAddRemove;


  if (tmpPop.NoOfFish === 0){
    tmpPop.CurrentCageId = null;
    if (!strNote || strNote.Length == 0)
      strNote= "Population reset and detached from cage";
  } else if (tmpPop.NoOfFish > 0 && tmpPop.PopId <= 0 && (!strNote || strNote.length == 0)){
      strNote = "Popelation Created"
  }




  set_TheEditPop(tmpPop);


  let newTemp = null;
  if (TheEditPop.Temperature != ThePop.Temperature)
    newTemp = TheEditPop.Temperature;


console.log ("Save tmpPop:  "+JSON.stringify(tmpPop, null, 1))

  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/movefish";

  


  //let path = "/counters/feeding/testtest";
  let myInit = {
    // OPTIONAL
    headers: {
       
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
   
      Case: 11,  
      Total: (anyMove ? AddFishOp.NoOfFish : 0),
      FarmID:props.FarmID,
      Gender: (anyMove? AddFishOp.Gender : null ),
      Type: (anyMove? AddFishOp.Operation : null ),
      Note: strNote,
      Cage: CageId


    },

    body: {

  
      T: {...tmpPop},
      Moved: {moved: moved },
      M: ModifyDesc,
      U: props.userData.name
      //Temp: newTemp

    }

  };

console.log ("About to post  EdTarg----: "+JSON.stringify(TheEditPop))

  API.post(apiName, path, myInit)
  .then ((response) => {
      console.log ("Then AFTER MovEFishPutEdit resp: "+JSON.stringify(response));
      ModifyDesc = "";
       props.UpdateDash();
  })
  .catch ((er) => {
    console.log ("Catch AFTER movefish PUT er: "+JSON.stringify(er));
    let themsg = <div>
      <p>Error!  - registration not saved: </p>
   
      {er.message}
      
    </div>



  })InsertPop


 
    
  }
*/


const InsertPop = (newPopId) =>{

  let strValues = ""
  let strFields = "";
  let tmpPop = {...TheEditPop};
  tmpPop.PopId = newPopId;




  let pnArr = Object.getOwnPropertyNames(ThePop);
  let pnLen = pnArr.length;
  
  for (var i=0; i<pnLen; i++){
    let pn = pnArr[i];
    if (pn != "PopId" && pn != "Temperature"){ //The Temperature belongs to the Cage
      if (i!=0 ){
        strFields+=", ";
        strValues+=", ";
      }
      strFields+=pn;
      if ((typeof tmpPop[pn]).toUpperCase() == "STRING"){
        strValues += "'"+tmpPop[pn]+"'";
   
      }else{
        strValues += tmpPop[pn];
       
      }
     
    }
  }
  
  
  


  let bNewTemp = false;
  

  if (ThePop.Temperature != TheEditPop.Tempereture){
    console.log ("Need to update the tamp as well: "+ ThePop.Temperature+ "  ->"+TheEditPop.Temperature );
    bNewTemp = true;
    
  }
  

  

  
  
  
  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/insertpopulation";
  let myInit = {
  
    // OPTIONAL
    headers: {
      
    }, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
  
      TheTime: moment.utc().format()
    
  
    }  ,
  
    
  
  
  
  
  
    body: {
  
      values: strValues,
      fields: strFields
  
    }




    
    /*
    , 
    body : {values:  setString}
    */
  
  
  
  
  };
  
  if (bNewTemp)
    myInit.body["ModifiedTemp"] = TheEditPop.Temperature;





  API.post(apiName, path, myInit)
  .then ((response) => {
   
      let newPopId = response[0].PopId;

      let pop = {...TheEditPop};

    
  
      pop.PopId = newPopId;
      set_TheEditPop(tmpPop); 
   
      setThePop(tmpPop);



      return;
  
  })
  .catch ((er) => {
    console.log ("Catch AFTER post er: "+JSON.stringify(er)+ "    "+path);

    alert ("Error (insert)!  - population not saved");
  
  })
  




}




  /*
  const handleChangeNum = (e) => {
 
    const nums = "0123456789";


    var strVerified = "";
    var len = e.target.value
    for (var i = 0; i < len; i++) {
      var c = e.target.value[i];
      if (nums.indexOf(c) >= 0)
        strVerified += c;

    }
console.log ("strVerified: "+strVerified)
    e.target.value = strVerified;




  }
*/



  const loadData = async () => { fetchPopDetails() }

  let ErrorMsg = "";


  let AddMsg = "";

  if (AddFishOp != null)
  AddMsg = <p className = "smallText colorGray">***Save to edit again*** </p>



  var NumFishButtonText = "Add/Remove Fish";
  var AddRemoveFish = "";  //Changing number of fish in population

  if (ShowMoveFish) {
    NumFishButtonText = "Close Edit"
    AddRemoveFish =
      (





          <div >
            <br/>
            <Form>
              <Form.Group controlId="dd">
                <Form.Control type="number" placeholder="Enter number of fish"  value={Ed_AddRemove} onChange={(e)=>{ set_Ed_AddRemove(e.target.value)}} autoComplete="off" disabled={AddFishOp != null}  />
                {AddMsg}
              </Form.Group>


              <div className = "ped-button-wrap">
              <ButtonGroup >

                <ToggleButton
                  key={1}
                  type="radio"
                  variant="outline-secondary"
                  name="0"
                  value={"1"}
                  onChange =  {handleRadioAddRemove}
                 
                  checked = {Ed_RadioAddRemove[0]}
                  disabled={AddFishOp != null}

                >
                  {"Unkn."}
                </ToggleButton>

                <ToggleButton
                  key={2}
                  type="radio"
                  variant="outline-secondary"
                  name="1"
                  value={"2"}
                  onChange =  {handleRadioAddRemove}
                 
                  checked = {Ed_RadioAddRemove[1]}

                  disabled={AddFishOp != null}

                >
                  {"Male"}
                </ToggleButton>

                <ToggleButton
                  key={3}
                  type="radio"
                  variant="outline-secondary"
                  name="2"
                  value={"3"}
                  onChange =  {handleRadioAddRemove}
                  
                  checked = {Ed_RadioAddRemove[2]}
                  disabled={AddFishOp != null}

                >
                  {"Female"}
                </ToggleButton>



              </ButtonGroup>
              </div >


              <Form.Group controlId="dd">
                Note:
                <Form.Control as="textarea" placeholder="" rows={2}  value= {Ed_NoteAddRemove} onChange = {(e)=>set_Ed_NoteAddRemove(e.target.value)}   />

              </Form.Group>



         


              <div className = "ped-button-wrap">
            <ButtonGroup vertical className="d-flex"    >
              <Button variant="outline-secondary" type="Button" name = "Add"  onClick = {handleAddRemoveClick }  disabled={AddFishOp != null}>
                Add (+)
    </Button>
              <Button variant="outline-secondary" type="Button"  name = "Harvest" onClick = {handleAddRemoveClick }  disabled={AddFishOp != null}>
                Harvest (-)
    </Button>
              <Button variant="outline-secondary" type="Button" name = "Mortality" onClick = {handleAddRemoveClick } disabled={AddFishOp != null}>
                Mortality (-)
    </Button>
            </ButtonGroup>
            </div>

            </Form> 

        </div>
      );
  }


  const handleOpenNumFishEdit = () => { setShowMoveFish(!ShowMoveFish) }
  const handleCancel = () => { setThePop(EmptyPop); props.HideMe(); }

    



  const handleChangeSiloSel = (e) => {
    var l = [];
    for (var i = 0; i < ed_SiloUseMap.length; i++)
      l.push(ed_SiloUseMap[i]);
 
    if (!e) return; 
  



    l[parseInt(e.target.name)] = !l[parseInt(e.target.name)];  
    
    setEdSiloUseMap(l);
    let strArr = "";
    for (var i = 0; i<l.length; i++){
      if (l[i]){
        if (strArr.Length > 0)
          strArr += ",";
        strArr += props.Silos[i].SiloId;  
      }
    }

    var popTmp = {...TheEditPop};
    popTmp.SiloArray = strArr;

    SetModifyDesc("Silo");
    set_TheEditPop (popTmp);

  }

 


  let NewPopNotice = "";
  var popName = ThePop.PopName;
  if (!props.CageInfo.PopId){
     NewPopNotice = <div className="ped-new-pop" >*New population </div>

     

  }

  






  var silArr = "";


  if (ShowSiloEdit){
    silArr = [];
    for (var j = 0; j < props.Silos.length; j++) {
      silArr.push(
        <ToggleButton
          key={j}
          type="checkbox"
          name={j}
          variant="outline-secondary"
          checked={ed_SiloUseMap[j]}
          onChange={handleChangeSiloSel}




        >
          {props.Silos[j].SiloId + ". " + props.Silos[j].Description}
        </ToggleButton>
      );
    }




  }
  let ProfileSelect = "";

  if (ShowProfileSelect) {
    ProfileSelect =
      <Select
        options={ProfileIdList}
        value={{label:TheEditPop.ProfileId}}
        onChange = {(sel)=> {set_TheEditPop (prevState => ({...prevState, ProfileId: sel.label })); SetModifyDesc("Profile");}}

      />
  }

  const handleCreate = (inputValue) => {
   

  
   // setTimeout(() => {
    
  
   
  

      PopNameList.push ({label:inputValue, value:inputValue})
      set_TheEditPop (prevState => ({...prevState, PopName: inputValue })) 
      /*
      this.setState({
        isLoading: false,
        options: [...options, newOption],
        value: newOption,
      });
      */
    //}, 1000);
  };

  let EditPopName = "";
  if (ShowEditPopName){
    
      EditPopName = 
      <div className="ped-popnamelist">
      <Creatable
     // isClearable
     onCreateOption = {handleCreate}
      value = {{label:TheEditPop.PopName, value:TheEditPop.PopName}}
      onChange={ (sel) =>{console.log ("SEL:  "+sel);  if (sel) set_TheEditPop (prevState => ({...prevState, PopName: sel.label })); SetModifyDesc("Name"); }}
       //onInputChange={  (newText) =>{console.log ("NEWTEXT:  "+newText);  set_TheEditPop (prevState => ({...prevState, PopName: newText }))}}
       options=    {PopNameList}
    />
</div>;


  }



  let SGRSelect = "";
  if (ShowSGRSelect) {
    SGRSelect =
      <Select
     
        options={SGRIdList}

        value={{label: TheEditPop.SGRId}}

        onChange={ (sel) =>{set_TheEditPop (prevState => ({...prevState, SGRId: sel.label }));  SetModifyDesc("SGR table"); }}




      />

  }




  let NoteModified = "";
  if (IsModified ())
    NoteModified =  (<div className="ped-modified"> *Modified! </div>);


 

  


  //<div className="ped-box-population-name"> <b>{ ThePop.PopName } </b></div>
  return (

    <div className="ped-container">
      {IsLoading && <Loading />}



      <div className="ped-box  ped-box-population">
        <div className="ped-box-title">

          {"Population in " + props.CageInfo.Name}

        </div>
        <div className="ped-box-content">
          <Form  >
            {NewPopNotice}
            <Form.Group  >
              <Button variant="outline-secondary" type="Button" block     onClick={() => { let l = ShowEditPopName; setShowEditPopName(!l) }}  >

              {(ShowEditPopName ? "Hide Edit Name" : (!ThePop.PopName || ThePop.PopName == "" ? "New" : <i> {ThePop.PopName}</i>)  )}
                {!ShowEditPopName && <div className="buttonSubText"> Click to change </div>}

                

              </Button>
            </Form.Group>

            {EditPopName}

            <Form.Group controlId="formBasicPassword">
              <Form.Label>Description</Form.Label>
              <Form.Control type="text" placeholder="Description" value={(!TheEditPop.Description?"":TheEditPop.Description ) } onChange={handleEditChange}  name="Description" autoComplete="off" block="true" />
            </Form.Group>

<div  className="ped-CBox-wrap">
            <Form.Group >
              <ButtonGroup >
                <ToggleButton
                  key={111}
                  type="checkbox"
                  variant="outline-secondary"
                  name="Active"
                  checked={TheEditPop.Active > 0}
                
                  onChange = {(e) => {set_TheEditPop (prevState => ({...prevState, Active: (e.target.checked?1:0)}));  SetModifyDesc("Active");} }
                  
                  
 
                >
                  Active
                </ToggleButton>

              </ButtonGroup >
            </Form.Group>

            </div>

            <Form.Group   >
              <Form.Label >Average Weight (kg) </Form.Label>

              <Form.Control type="number"  name="AvarageWeight" value={TheEditPop.AvarageWeight} onChange={handleEditChange} />



            </Form.Group>

            <Form.Group   >
              <Form.Label>   Total feed/day (kg)</Form.Label>

              <Form.Control type="number" name="FeedTotal" value={TheEditPop.FeedTotal} onChange={handleEditChange} />



            </Form.Group>


            <Form.Group   >




              <Button variant="outline-secondary" type="Button" block onClick={handleCalculate}  >
                Calculate feeding/day
  </Button>

            </Form.Group>


          </Form>
        </div>
        <div className="ped-box-footer">
          {NoteModified}
          <ButtonGroup className="d-flex">
            <Button variant="outline-secondary" type="Button" disabled={!IsModified()}  onClick={onSave} >
              Save
    </Button>
            <Button variant="outline-secondary" type="Button" onClick={handleCancel}  >
              Cancel
    </Button>

          </ButtonGroup>

        </div>

      </div>



  




      <div className="ped-box ">
        <div className="ped-box-title">
          Statistic
       </div>

        <div className="ped-box-content">
          <Form>





            <div className="ped-numfish-table-wrap">
              <table >
                <tbody>
                  <tr>
                    <td width="50%">
                      Males
  </td>
                    <td width="50%">
                      {TheEditPop.NoOfMale}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Females
  </td>
                    <td>
                      {TheEditPop.NoOfFemale}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Unknown
  </td>
                    <td>
                      {TheEditPop.NoOfUnknown}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>Total</b>
                    </td>
                    <td>
                      <b>{TheEditPop.NoOfFish}</b>
                    </td>
                  </tr>
                </tbody>
              </table>

              <p></p>
Feeding
<table >
                <tbody>
                  <tr>
                    <td width="50%">
                      Today
  </td>
                    <td width="50%">
                      {(!TheEditPop.SumToday?" ": TheEditPop.SumToday )}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Yesterday
  </td>
                    <td>
                      {(!TheEditPop.SumYesterday?" ":TheEditPop.SumYesterday)}
                    </td>
                  </tr>

                </tbody>
              </table>



            </div>



          </Form>

        </div>

        <div className="ped-biomass"> Total biomass: <i>{ Math.round(TheEditPop.NoOfFish*TheEditPop.AvarageWeight)}kg </i> </div>
        <div className = "ped-button-wrap">
          <Button variant="outline-secondary" type="Button" block onClick={handleOpenNumFishEdit}  >
            {NumFishButtonText}
          </Button>
          </div>
          {AddRemoveFish}




        
      </div>






      <div className="ped-box ">
        <div className="ped-box-title">

          Miscalenous
          
       </div>
        <div className="ped-box-content">
          <Form  >



      
 
          <div  className="ped-CBox-wrap">
            <Form.Group>
              <ButtonGroup >
                <ToggleButton

                  type="checkbox"
                  variant="outline-secondary"
                  name="AutoUpdate"


                  checked={TheEditPop.AutoUpdate > 0}
                  onClick = {(e) => {set_TheEditPop (prevState => ({...prevState, AutoUpdate: e.target.checked})); SetModifyDesc("AutoUpdate");}}

                >
                  Auto Update
                </ToggleButton>

              </ButtonGroup >
            </Form.Group>

            </div>
            <Form.Group controlId="InpFCR" as={Row}   >
              <Form.Label column sm="5">FCR </Form.Label>
              <Col sm="7">
                <Form.Control type="number" value={TheEditPop.FCR} step={0.01} name="FCR" min="1" onChange= {handleEditChange} />

              </Col>

            </Form.Group>

            <Form.Group controlId="InpCorrFactor" as={Row}   >
              <Form.Label column sm="5">Corr.Factor </Form.Label>
              <Col sm="7">
                <Form.Control type="number"  name="CorrFactor" value = {TheEditPop.CorrFactor}  step={0.01} min="0.5" onChange={handleEditChange} />

              </Col>

            </Form.Group>


            <Form.Group controlId="InpTemp" as={Row}   >
              <Form.Label column sm="5">Temp. °C  </Form.Label>
              <Col sm="7">
                <Form.Control type="number" value={TheEditPop.Temperature} name="Temperature" min="0" step={1} onChange= {handleEditChange} />
              </Col>
            </Form.Group>


            <Form.Group  >
              <Button variant="outline-secondary" type="Button" block onClick={() => { let l = ShowSiloEdit; setShowSiloEdit(!l) }} >

                {(ShowSiloEdit ? "Hide Silos" : <div>Silo(s):  <i> {props.CageInfo.SiloArray}</i></div>)}
                {!ShowSiloEdit && <div className="buttonSubText"> Click to change </div>}


              </Button>
            </Form.Group>



            <ButtonGroup vertical >

              <Form.Group  >
                {silArr}
              </Form.Group>
            </ButtonGroup>


            <Form.Group  >     
              <Button variant="outline-secondary" type="Button" block onClick={() => { let l = ShowProfileSelect; setShowProfileSelect(!l) }}>

              {(ShowProfileSelect ? "Hide Profile Select" :<div >Profile: <i>{TheEditPop.ProfileId  }</i></div>)}
              {!ShowProfileSelect && <div className="buttonSubText"> Click to change </div>}

            </Button>
            </Form.Group>

            <Form.Group  >
              {ProfileSelect}
            </Form.Group>




            <Form.Group  >     
              <Button variant="outline-secondary" type="Button" block onClick={() => { let l = ShowSGRSelect; setShowSGRSelect(!l) }}>

              {(ShowSGRSelect ? "Hide SGR Select" :<div >SGR table: <i>{TheEditPop.SGRId }</i></div>)}
              {!ShowSGRSelect && <div className="buttonSubText"> Click to change </div>}

            </Button>
            </Form.Group>

            <Form.Group  >
              {SGRSelect}
            </Form.Group>



          </Form>
        </div>
        <div className="ped-box-footer">


        </div>

      </div>







    </div>

  );
}
