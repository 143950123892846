import React, { useState, useEffect, useRef } from "react";
//import { default as Select } from "react-select";

import Amplify, {  API } from "aws-amplify";

import "./Settings.css";
import "./CageLayout.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import Slider from 'rc-slider';
import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";

import Cage from "../CagePane/Cage";

import aws_exports from "../../aws-exports";

import "./rc-slider_index.css"

Amplify.configure(aws_exports, {});




//const colorWarning= "#FFA500";
const colorWarning= "#f3ab62";
//const colorError = "#ff0000";
const colorError = "#f36262";

const SelColorGreen =    "rgba(114, 243, 99, 0.5)";//"#72F363";
const SelColorYellow = "#F3E463";
const SelColorGray = "#CDCDBF";

let TheTimer = null;

let TimerCounter =0;
let TimerLimit = 10;

var OrgData = null;

export default function CageView(props) {

    const refPane = useRef(null);
    const [IsLoading, setLoading] = useState(true);
  const [ErrorToShow, setErrorToShow] = useState("");
  const [EdCages, setEdCages] = useState([]);
  const [SaveSuccessMsg, setSaveSuccessMsg] = useState ("");
  const [PaneWidth, setPaneWidth] = useState(props.Preferences.CagePaneWidth);

const [PaneHeight, setPaneHeight] = useState(props.Preferences.CagePaneHeight);
const [SelCageIdx, setSelCageIdx] = useState(-1);  //Negativ -> nothing selected

const [TmpPos, setTmpPos] = useState (null);

const [CageSize, setCageSize ] = useState (props.Preferences.DefaultCageSize);

const [GridSize, setGridSize] = useState (props.Preferences.SnapGrid);




  useEffect(() => {
    /*
    console.log ("Prefs i useEffect:  "+JSON.stringify (props.Preferences))

    console.log ("width i useEffect:  "+props.Preferences.CagePaneWidth)

     setPaneWidth(props.Preferences.CagePaneWidth);

    setPaneHeight(props.Preferences.CagePaneHeight);

    
     setCageSize (props.Preferences.DefaultCageSize); 
    
     setGridSize (props.Preferences.SnapGrid);
    */

       
    loadData();


  }, [  props.FarmID, props.DashModified]);

  //let theUrl = "https://toajzvu4i7.execute-api.eu-west-1.amazonaws.com/dev/getdashboard";


  let theUrl = "https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters?organisationId=432&counterID=2249"


  const fetchDashboard = () => {



    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/dashboard";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID

      }
    };

    API.get(apiName, path, myInit).then(response => {

      let len = response.Cages.length;



      OrgData=[];
      for (let i=0; i<len; i++){
            OrgData.push ({...response.Cages[i]})
      };

      setEdCages (OrgData);

      OrgData = response.Cages;
      setLoading(false);

    })
      .catch((err) => {

        setLoading(false);

      })

  };



const resetPage = () => {


  OrgData = null;
setLoading (false);

  

 setErrorToShow ("");

 setEdCages([]);



  setPaneWidth(props.Preferences.CagePaneWidth);

 setPaneHeight(props.Preferences.CagePaneHeight);

 setSelCageIdx(-1);  
 setTmpPos (null);

 setCageSize  (props.Preferences.DefaultCageSize);

 setGridSize (props.Preferences.SnapGrid);
}


  const loadData = async () => {


    fetchDashboard()


  }


  let ErrorMsg = "";

let TheData = "";

  //console.log("orgList all", orgList);



const handleEvent = (event) => {


    if (SelCageIdx < 0 ){
     
        let idx = event.target.id.indexOf("_")
        if (idx<0)
          return;

        let str =   event.target.id.substring (idx+1);
        let id = parseInt (str);


        if (isNaN(id))
          return;

        setSelCageIdx (id);
        setTmpPos ({x:event.pageX, y:event.pageY })
    }


    /*
    if (event.type === "mousedown") {
           this.setState({ message: "Mouse Down"});
       } else {
           this.setState({ message: "Mouse Up"});
       }
       */
   }


   const handlePaneEvent = (event) => {
    if (SelCageIdx < 0)
        return;




    if (event.type === "mousemove") {
            //Todo, færa cage
    
//console.log ("clientY: "+event.clientY+",   screenY: "+event.screenY+",    pageY: "+event.pageY+"    movementY: "+event.movementY);
//console.log ("clientX: "+event.clientX+",   screenX: "+event.screenX+",    pageX: "+event.pageX+"    movementX: "+event.movementX);
            if (TmpPos){
              
                if (Math.abs(TmpPos.x - event.clientX) > 10 ||  Math.abs(TmpPos.y - event.clientY) > 10  ){
                  
                    setTmpPos ({x:event.pageX, y:event.pageY })
                  
                }
            }
    }


    if (event.type === "mouseup") {
        //Todo, færa cage
        let x=((event.pageX - RelPnt.x)*100)/PaneWidth;
        let y=((event.pageY - RelPnt.y)*100)/PaneHeight;


        let l = EdCages.length;
        let tmp = [];
        for (let i = 0; i<l; i++){
            tmp.push ({...EdCages[i]})
        }
   
        tmp[SelCageIdx].ImXPos = Math.round( x);
        tmp[SelCageIdx].ImYPos = Math.round(y);

        setEdCages (tmp);

     

        setSelCageIdx (-1)
    }
    if (event.type === "mouseleave") {
        //Todo, færa cage

        setSelCageIdx (-1)
    }


    /*
    if (event.type === "mouseup") {
           this.setState({ message: "Mouse Down"});
       } else {
           this.setState({ message: "Mouse Up"});
       }
      */ 
   }



let  sPane = {
    width: PaneWidth+"px",
    height: PaneHeight+"px"
  }



  let RelPnt = {x:0,y:0}
    
  if (refPane.current && props.RefFeed.current){
   //  console.log ("top: "+ refPane.current.offsetTop +"    left"+refPane.current.offsetLeft )
     RelPnt ={
         x:refPane.current.offsetLeft+ props.RefFeed.current.offsetLeft, 
         y:refPane.current.offsetTop + props.RefFeed.current.offsetTop
     };
 } 

  let TheCages = [];


  let len = EdCages.length;


let Positions = [];


const verifyPosition = (x, y) => {

    let found = false;
    do{
        found = false;
        let len = Positions.length;
        for (let i = 0; i<len; i++){
            if (  Positions[i].x == x  &&  Positions[i].y == y){
                x+= 1;
                y+=1;


                found = true;
                break;
            }
        }

    }while (found)

    Positions.push ({x:x, y:y});
    return {x:x, y:y};

}



  for (let i = 0; i<len; i++){
    /*
    if (i==1)
        console.log ("EdCages[1].ImXPos : "+EdCages[i].ImXPos)
   */

    let x = EdCages[i].ImXPos;

  

    if (x <= 2 || x  >= 95 )
        x = 2*i+2;
        
    let y = EdCages[i].ImYPos;
    if (y <= 2 ||  y >= 95 )
         y = 2*i+2;   
         
    let d = EdCages[i].CageSize;
    if (d>= 2 || d>20)
        d= 10;
        

    if (CageSize > 0)
        d= CageSize;    
    
if (isNaN (x)){
    console.log ("NanNan:  ",i,x,y,EdCages[i].Name)
    console.log ("EdCage "+JSON.stringify (EdCages[i],1,1))
}





if (GridSize > 1){
    let xx = Math.round (x/GridSize);
    x = xx*GridSize;

    let yy = Math.round (y/GridSize);
    y = yy*GridSize;
}




let pnt = verifyPosition (x, y);



    EdCages[i].ImXPos =  pnt.x;  
    EdCages[i].ImYPos = pnt.y;
    EdCages[i].CageSize = d;



 



   // let pnt = verifyPosition (lt, tp);




 
      let pPnt = null;
      if (SelCageIdx == i && TmpPos){




        pPnt = {
          y : (TmpPos.y-RelPnt.y)*100/PaneHeight,
          x : (TmpPos.x-RelPnt.x)*100/PaneWidth
       };

      }
     
      //Get modified preferences
      let tmpPref = {
         DefaultCageSize: CageSize,
         SnapGrid: GridSize
      };


      TheCages.push (
        <Cage key={i} id={i}  MouseDownHandler ={handleEvent}  Cage={EdCages[i]} 
           {...props}  PanePoint = {pPnt}  Preferences = {tmpPref}  PaneWidth={PaneWidth}  PaneHeight = {PaneHeight} />

         )


  }









let TmpCage = "";


const OnPaneWidth = value => setPaneWidth(value);
const OnPaneHeight = value => setPaneHeight(value);
const OnCageSize = value => setCageSize (value);
const onGridSize = (value) => {console.log ("onGridSize"); setGridSize (value);}


const doSave  = async () => {
  let len = EdCages.length;
  let cntMods = 0;
  let strList = "";
  let strColumns = "(FarmID, CageID, ImXPos, ImYPos, CageSize)";
  for (let i=0; i< len; i++){
    if ( OrgData[i].ImXPos != EdCages[i].ImXPos 
      || OrgData[i].ImYPos != EdCages[i].ImYPos
      || OrgData[i].CageSize != EdCages[i].CageSize){
        if (cntMods > 0){
          strList += ","
        }
          cntMods++;
          strList += "("+props.FarmID+", '"+ EdCages[i].CageId+"', "+EdCages[i].ImXPos+", "+EdCages[i].ImYPos+", "+EdCages[i].CageSize+") ";
      }
  }

  let Prefs = {};
  if (PaneWidth != props.Preferences.CagePaneWidth || PaneHeight != props.Preferences.CagePaneHeight
      || CageSize != props.Preferences.DefaultCageSize || GridSize != props.Preferences.SnapGrid)
      Prefs = {
         CagePaneWidth : PaneWidth,
         CagePaneHeight: PaneHeight,
         DefaultCageSize: CageSize,
         SnapGrid: GridSize
      }



  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/updatelayout";
  let myInit = {



      // OPTIONAL
      headers: { }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: { }  ,
  
      body: {
           FarmID : props.FarmID,
           Data: strList,
           Columns: strColumns,
           Prefs: Prefs
         

        }
    };

    var tt = API.post(apiName, path, myInit)
    .then ((response) => {

     
      if (Prefs != {})


         props.Update ("Preferences", Prefs)


      if (strList != ""){
     
          props.UpdateDash();
      }   
      /*
      if (response.statusCode){
          setError ("Error saving ("+response.statusCode+"): "+response.body);
      }else{
          resetBoard();
          console.log ("Success sending: "+ JSON.stringify(response))
             setMsg("Succes saving");
      }
*/




resetPage();

setSaveSuccessMsg ("Save successful!")
//fetchDashboard ();
        //setThePop(TheEditPop);
  
    })
    .catch ((er) => {
      //setError ("Error saving:  "+er)
      console.log ("Error sending: "+ er)
  
    })

}

const doCancel  = () => {

  resetPage();
  props.HideMe();
}




let ThePage = "";

if (SaveSuccessMsg == ""){

  ThePage = <div>
  <div id="LayoutSettings">

  <Form.Group as={Row}  className="m-3">
  <Col sm="6">
Width of cage board
  <Slider
  defaultValue = {PaneWidth}
onAfterChange={OnPaneWidth}  
min = {500}
max = {1500}
step = {50}


/>
<br/>
Height of cage board
<Slider
defaultValue = {PaneHeight}
onAfterChange={OnPaneHeight}  
min = {500}
max = {1500}
step = {50}
/>
</Col>
<Col sm="6">
Size of cage block as percentage of pane width
<Slider
defaultValue = {CageSize}
onAfterChange={OnCageSize}  
min = {8}
max = {20}
step = {1}
/>
<br/>
(Snap)Grid size
<Slider
defaultValue = {GridSize}
onAfterChange={onGridSize}  
min = {1}
max = {10}
step = {1}
/>
</Col>   

</Form.Group>

<Form.Group  as= {Row} className = "ml-3" >
<Button className = "m-3" variant="outline-secondary" type="Button"   onClick = {doCancel}  >
Cancel
</Button> 

<Button  className = "m-3"variant="outline-secondary" type="Button"   onClick = {doSave}   >
Save
</Button>    
</Form.Group>


      </div> 

  <div id="LayoutPane"   style = {sPane}  ref = {refPane}  onMouseMove={handlePaneEvent }  onMouseUp={handlePaneEvent }   onMouseLeave = {handlePaneEvent } >

      {TheCages}
    
      {TmpCage}
   </div>    
   
{IsLoading && <Loading />}
{ErrorMsg}
{!IsLoading && TheData}


</div>


}else {
  ThePage = <div><h3>  {SaveSuccessMsg}  </h3>
  <Button className = "m-3" variant="outline-secondary" type="Button"   onClick = {doCancel}  >
Close
</Button> </div>

}





  return (
    <div>
    {ThePage}
    </div>
  )


}

