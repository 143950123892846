import React from "react";
import "./DownloadTab.css";
import { Row, Col } from "react-bootstrap";
import Amplify, { API } from "aws-amplify"; //graphqlOperation
import aws_exports from "../aws-exports";
Amplify.configure(aws_exports, {});

/*
    A container that is used to download the relevant files. Renderd inside of InformationTab
*/

export default class DownloadTab extends React.PureComponent {
  constructor() {
    super();
    this.state = {
      index: 0,
      mseS3Path: "",
      pdfS3Path: "",
      blcS3Path: "",
      cntS3Path: ""
    };
  }

  componentDidUpdate() {
    /*     if(prevProps.index !== this.props.index){
      this.setState({
        index: this.props.index
      });
    } */
  }

  fetchCounterInfo = (S3Path, fileurl) => {
   
    let apiName = "vakicloudRdsAPI";
    let path = "/getFileBlob";
    let myInit = {
      // OPTIONAL
      headers: {
        "Content-Type": "binary/octet-stream"
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        S3Path: S3Path
      }
    };
    API.get(apiName, path, myInit).then(response => {
      if (response.failed === true) {
        alert("No File found");
      } else {
        var a = document.createElement("a");
        a.href = fileurl;
        a.download = true;
        a.click();
      }
    });
  };



  render() {
    let { pdfFileURL, mseFileURL, blcFileURL } = this.props;

    let ThePdf = "";

    if (pdfFileURL != null)
      ThePdf =  
      <Row>
      <Col className="dl-buttons">
      <a id="PDF-dl" href={pdfFileURL}> <i className="fa fa-download" aria-hidden="true"></i> Download PDF Report </a>
    </Col>;
      </Row>;

console.log("mseFileUrl:  "+mseFileURL)
console.log("pdfFileURL:  "+pdfFileURL)

console.log("blcFileURL:  "+blcFileURL)


    return (
      <div className="dl-buttons">
        <h5>Download Reports & Technical Files </h5>
        <Row>
          <Col className="dl-buttons">
            <a id="MSE-dl" href={mseFileURL} >
              <i className="fa fa-download" aria-hidden="true"></i> Download MSE
              File
            </a>
          </Col>
        </Row>
        {ThePdf}

        <Row>
          <Col className="dl-buttons">
            <a id="BLC-dl" href={blcFileURL}>
              <i className="fa fa-download" aria-hidden="true"></i> Download BLC
              File
            </a>
          </Col>
        </Row>
      </div>
    );
  }
}
