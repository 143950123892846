import React, { useState, useEffect } from "react";
import { Button, Row, Col, Form, InputGroup } from 'react-bootstrap';
import DatePicker from "react-datepicker"
import {v4} from "uuid";
import BootstrapTable from "react-bootstrap-table-next";
import { Loading } from "../Components/Loading";
import "react-datepicker/dist/react-datepicker.css";
import Amplify, {  API } from "aws-amplify";
import moment from "moment"
import aws_exports from "../aws-exports";

Amplify.configure(aws_exports, {});



require ("./ShareEdit.css")

require ("./Counters.css")





export default function ShareList (props) {
const [list, setList] = useState (null)
    

    useEffect(() => {
        
  
        fetchList();


    
    
   }, [props.Counter_ID, props.NewItems]);



const fetchList = () => {

//console.log(mseS3Path, urls);
let apiName = "vakicloudRdsAPI";
let path = "/counters/sharelist";
let myInit = {
  // OPTIONAL
  headers: {}, // OPTIONAL
  response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
  queryStringParameters: {
    // OPTIONAL
    Organisation_ID : props.Organisation_ID,
    Counter_ID : props.Counter_ID
  }
};




API.get(apiName, path, myInit).then(resp => {
//  let str  =  resp.success.toUpperCase();
   
   setList (resp);

}).  catch (err =>{
  console.log ("Error when fetching list: "+err)

});


}

const dateFormatter = (cell, row, rowIndex, colIndex) =>{
    
    if (cell==null)
        return cell;
    return moment(cell).format ("YYYY-MM-DD HH:mm");
}

const singleCountFormatter = (cell, row, rowIndex, colIndex) =>{
    
    if (cell==null)
        return cell;
    return moment(cell).format ("YYYY-MM-DD HH:mm")+" /"+list[rowIndex].FishCount
}


/*
function styleDelete(cell, row, rowIndex, colIndex) {
 
    var style = { backgroundColor: "white", color: "#232323"};

    if (rowIndex == SelectedSiloIdx){
      style.backgroundColor = "#787878";
      style.color = "white"


    }
    if (colIndex == 2 && row.MaxKilos > 0 && row.KilosInSilo / row.MaxKilos < 0.05) {
      // style.color = 'orangered';
      style.backgroundColor = "Yellow";
   
 
  }

    return style;
  }
*/

const handleDelete = (row, rowIndex)=>{
  
    if (window.confirm ("Delete sharing record created "+moment(row.Created).format ("yyyy-MM-DD HH:mm:ss")) == false)
        return;

        let tmpList = [];
        let len=list.length;
        for (let i=0; i<len; i++){
            if (row.guid != list[i].guid)
                tmpList.push (list[i]);

        }

        let apiName = "vakicloudRdsAPI";
        let path = "/counters/sharedelete";
        let myInit = {
          // OPTIONAL
          headers: {}, // OPTIONAL
          response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
          queryStringParameters: {
          
          },
          body: {guid : row.guid}
        };
        
        
        
        
        API.post(apiName, path, myInit).then(resp => {
        //  let str  =  resp.success.toUpperCase();
           
           setList (tmpList);
           return;
        
        }).  catch (err =>{
          console.log ("Error when  delete: "+err)
        
        });
        



    


}

   let Columns = [{
    dataField: 'Timestamp',
    text: 'SingleCount',
    formatter: singleCountFormatter,
    title: (cell, row, rowIndex, colIndex) => { return row.guid; }
   
  },
  {
    dataField: 'FirstDay',
    text: 'FirstDay',
    formatter: dateFormatter
   
  }, {
    dataField: 'LastDay',
    text: 'LastDay',
    formatter: dateFormatter
   

  }, {
    dataField:'ExpireDay',
    text: 'ExpireDay',
    formatter: dateFormatter
    
   

  }, {
    dataField:'Description',
    text: 'Description'
   

  }, {
    dataField:'Creator',
    text: 'Creator'
   

  }, {
    dataField:'Created',
    text: 'Created',
    formatter: dateFormatter
   

  }, {
    dataField: "remove",
    text: "Delete",
    style: {textAlign:"center"},
    formatter: (cell, row, rowIndex) => {
      return (

          <span  onClick={() => handleDelete(row, rowIndex)}
 > <i className="fa fa-trash-o  fa-2x color-red SLtrash" aria-hidden="true" ></i></span>

       
      );
    },
  }


  ];







return <div id = "SLwrap">



{list != null && < BootstrapTable
            hover
            keyField='Created'
            data={list}
            noDataIndication="No sharings on this counter"            
            //data = {[{PipeId:1, key:100},{PipeId:2, key:101} ]}
            columns={Columns}
           /* rowEvents={RowEvents}*/
            //cellEdit={ cellEditFactory({ mode: 'click' }) }

          />}



</div>


}