import React, { useState, useEffect } from "react";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Reports.css";

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl,Button } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import PipeTimes from "./PipeTimes.js"

import GrowthReport from "./GrowthReport.js"

import { Loading } from "../../Components/Loading";







import aws_exports from "../../aws-exports";

Amplify.configure(aws_exports, {});





/*

      <NavDropdown title="Dropdown" id="basic-nav-dropdown">
        <NavDropdown.Item href="#"  eventKey={5} name="Action" onClick = {handleClick }>Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown>


<Form inline>
<FormControl type="text" placeholder="Search" className="mr-sm-2" />
<Button variant="outline-success">Search</Button>
</Form>

*/

   let eventKey = null; 
export default function Reports(props) {

    const [MenuSelect, setMenuSelect] = useState(null);
 
    const [FeedSystems, setFeedSystems] = useState([]);



    useEffect(() => {

      setFeedSystems(props.FeedSystems)
   
    
   }, [props.FeedSystems]);



    const handleClick = (e ) => {
      
        setMenuSelect(e.target.name);
        
        }





      




const hideCallBack = ()=>{ setMenuSelect(null) }


let TheNavBar= 
<Navbar bg="light" expand="lg">
  {/*<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>*/}
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav" >


    <Nav className="mr-auto" >
      <Nav.Link href="#" name="PipeTimes" onClick = {handleClick }>Pipe Times</Nav.Link>

      <Nav.Link href="#" name="GrowthReport" onClick = {handleClick }>GrowthReport</Nav.Link>
    </Nav>

  </Navbar.Collapse>
</Navbar>




let TheContent = <div style={{height:"30em"}}></div>;


//userData={props.userData} FarmID={FarmSelected.value}  FeedSystems = {FeedSystems}  UpdateSystem={UpdateSystem}  UpdateDash={UpdateDash} 

switch (MenuSelect){
  case "PipeTimes":
    TheContent = <PipeTimes {...props} HideMe = {hideCallBack} />;
  break;
  case "GrowthReport":
    TheContent = <GrowthReport {...props} HideMe = {hideCallBack} />;
  break;
break;  
  default: break;

}






return <div className="rep-wrap">
{TheNavBar}

<div className = "rep-content-wrap">
{TheContent}


</div>


</div>


}