
import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";
import ProfileColumn from "./ProfileColumn"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";



Amplify.configure(aws_exports, {});


 




export default function CreateCages(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);


 const [NumCages, setNumCages] = useState (0);
 const [NumCagesFixed, setNumCagesFixed] = useState(false);
 const [TheData, setTheData] = useState ([]);

const[ErrorMsg, setErrorMsg] = useState ("");
const [Msg,  setMsg] = useState ("")






 useEffect(() => {

    setMsg ("");
    setErrorMsg ("");


}, [props.FarmID]);







const resetPage = () => {

}



 
 const isSaveDisabled = ()=>{
    return true;
    //return !IsModified;
 }



const handleClose = ()=>{
  props.HideMe();
}

const handleSave = async ()=>{
setErrorMsg ("");
setMsg ("")

    let cgs = "";
    for (let i = 0; i< NumCages; i++){
        if (i!= 0)
            cgs+=","

         cgs += "'"+TheData[i].Name+"'";   
    }



    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/createcages";
    let myInit = {
  
        // OPTIONAL
        headers: { }, // OPTIONAL
        response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: { }  ,
    
        body: {
            FarmID:props.FarmID,
             Names: cgs
  
          }
      };
    
   

      var tt = API.post(apiName, path, myInit)
      .then ((response) => {
 
        if (response.statusCode){

            setError ("Error saving ("+response.statusCode+"): "+response.body);
        }else{
            resetBoard();
          
               setMsg("Succes saving");
        }
  

          //setThePop(TheEditPop);
    
      })
      .catch ((er) => {
        setError ("Error saving:  "+er)
        console.log ("Error sending: "+ er)
     
  
  
    
      })


     

  
}




const handleNumCagesChange = (e) => {
    let val = e.target.value;

    setMsg ("");
    setErrorMsg("");


    if (isNaN(val))
        val = "";
    else {
        let n = parseInt (val);
        if (n< 0)
            n= (-1*n).toString();
        if (n> 30)
            n = 30;

            val = n.toString();    
    }    

    setNumCages(val);
}


const handleNext = () => { 
    setNumCagesFixed (true);

    for (let i = 0; i<NumCages; i++){
        
        TheData.push ( {
            key: i,
            Name: "",
            Error:false
        });
    }
}


  let SaveDisabled = isSaveDisabled();




  const handleEditName =  (e)=>{

    setErrorMsg ("");
    setMsg("");
    let row = e.target.parentNode.parentNode.rowIndex-1;
    let tmpData  = TheData.map ((pc) =>{return {...pc}});
    tmpData[row].Error = false;

    let newVal = e.target.value;
    if (newVal.length > 10){
        newVal = newVal.substr(0,10);
       setError ("Maximum 10 chacters for cage's name!")
    }


    
    
    for (let i = 0; i<NumCages; i++){
        if (i != row && tmpData[i].Name.trim() == newVal.trim()){
            tmpData[row].Error = true;
            break;
        }

    }
 


    tmpData[row].Name = newVal;

    setTheData (tmpData);

  }
 const handleCancel = () => {
    resetBoard();
    setMsg ("");
    setErrorMsg ("");
    props.HideMe()
 }



const nameStyle = (cell, row, rowIndex, colIndex) => {
 

    if (TheData[rowIndex].Error)
        return {color: "red"};
    return {color: "#232323"};

  }


  let Columns = [
 {
    dataField: 'Name',
    text: 'Name(s)',
    name: "name",
    width: "3rem",
 

/*
    formatter: (cell) => {if (isNaN(cell))
                                return 22;
                                     else
                                return cell;},
*/
    style: nameStyle,
    /*
    validator: (newValue, row, column) => {
      console.log ("NewVal: "+newValue, +"    row:"+row+"    col: "+column)
      if (isNaN(newValue)) {
        
        return {
          valid: false,
          message: "Price should be numeric"
        };
      }
 
      return true;
    },
    */
  
    editor: {
    //  type: "number",
      onChange: handleEditName
      
    },
    
  }


/*
  {
    dataField: 'Name',
    text: 'Cage',
    name: "cage",
    sort: true,
    editable: true,
    editor: {
      type: "select",
      options: AllCages,
      onChange:  handleCageSelect
    },
   }
*/
 ];

const resetBoard =() => {
    setNumCages (0);
    setTheData ([]);
    setNumCagesFixed(false);
}

 
 const disableSave = () => {



    for (let i = 0; i<NumCages; i++ ){

        if (TheData[i].Error || TheData[i].Name == "")
            return true;
    }

    return false;
 }



  let TheTable = "";
  let TheSaveButton = "";
  if (NumCagesFixed){
    TheTable =  <div id = "NewCagesTableWrap"> 
    Enter unique name for each cage:
    < BootstrapTable
    hover
    keyField='key'
    data={TheData}
             
  
    //data = {[{PipeId:1, key:100},{PipeId:2, key:101} ]}
    columns={Columns}
   /* rowEvents={RowEvents}*/
    cellEdit={ cellEditFactory({ mode: 'click' }) }

  />
</div>;
let doDisable = disableSave();
TheSaveButton = <Button className="ml-3 NewCagesNextButton" variant="outline-secondary" type="Button" onClick={ handleSave }  disabled = {doDisable}>
Save    
</Button>
  }



  const setError = (txt) => setErrorMsg (<div className="errormsg" >  {txt}  </div>)

 return <div>

<Form.Group as={Row}  className="m-3">
<Form.Label >  Number of cages to create: </Form.Label>

<Form.Control className = "ml-3" type="number" name="NumCages" value={NumCages} onChange={handleNumCagesChange}  disabled = {NumCagesFixed} />


<Button className="ml-3 NewCagesNextButton" variant="outline-secondary" type="Button" onClick={ handleNext }  disabled = {NumCagesFixed || !NumCages || NumCages == 0}>
              Next    
    </Button>
    <Col sm="3">
    {TheTable}
    </Col>
    <Col sm="1">
    {TheSaveButton}
    </Col>
    <Col>
    {ErrorMsg}
    {Msg}
    </Col>
    </Form.Group>


    <Button className="mt-3 ml-3 NewCagesNextButton" variant="outline-secondary" type="Button" onClick={ handleCancel }  >
              Cancel   
    </Button>

 </div>


}


    






