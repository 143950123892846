import React, { useState, useEffect, useRef } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Feeding.css";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl,Button, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";




import aws_exports from "../aws-exports";
import { Discovery } from "aws-sdk";





Amplify.configure(aws_exports, {});

export default function ProfilePreview(props) {
    const canvas = useRef();
    let ctx = null;
   
    const [ClientHeight, setClientHeight] = useState (0);

    // initialize the canvas context
    useEffect(() => {
      // dynamically assign the width and height to canvas
      const canvasEle = canvas.current;
      canvasEle.width = canvasEle.clientWidth;
      canvasEle.height = canvasEle.clientHeight;
   

      setClientHeight (canvasEle.clientHeight)

      // get context of the canvas
      ctx = canvasEle.getContext("2d");



    }, []);
   


    useEffect(() => {
        /*
      const r1Info = { x: 20, y: 30, w: 100, h: 50 };
      const r1Style = { borderColor: 'red', borderWidth: 10 };
      drawRect(r1Info, r1Style);
   
      const r2Info = { x: 100, y: 100, w: 80, h: 150 };
      drawRect(r2Info);
   
      const r3Info = { x: 250, y: 80, w: 80, h: 120 };
      drawFillRect(r3Info, { backgroundColor: 'green' });
   
      const r4Info = { x: 200, y: 220, w: 100, h: 50 };
      drawFillRect(r4Info);
*/


let P = props.TheProfile;

if (!P)
        return;

let max = -1;
for (var i=P.StartHour; i<=P.EndHour; i++ ){
    let idx = "H"+ String(i).padStart(2, '0');
    let val = P[idx];
 
     if (val > 0){
         if (max < val)
          max = val;    
     }
  }


  let fullColVal = 100;
  if (max < 8)
    fullColVal = 12;
  else if (max < 14)
    fullColVal = 17;  
  else if (max < 20)
    fullColVal = 23;         
  else if (max < 30)
     fullColVal = 35;
   else 
     fullColVal = max+2;








        drawLine({ x: 0, y: 21, x1: 70, y1: 21 }, { color: 'black', width: 1 });



  for (let i = P.StartHour; i<=P.EndHour; i++){

    let idx = "H"+ String(i).padStart(2, '0');
    let val = P[idx];
    val *= 20/fullColVal; 

    drawLine({ x: (3*i+1), y: 20, x1: (3*i+1), y1: 20-val }, { color: 'black', width: 1 });
      }

    



    }, []);
   
   /* 
    // draw rectangle
    const drawRect = (info, style = {}) => {
      const { x, y, w, h } = info;
      const { borderColor = 'black', borderWidth = 1 } = style;

      ctx.beginPath();
      ctx.strokeStyle = borderColor;
      ctx.lineWidth = borderWidth;
      ctx.rect(x, y, w, h);
      ctx.stroke();
    }
   
    // draw rectangle with background
    const drawFillRect = (info, style = {}) => {
      const { x, y, w, h } = info;
      const { backgroundColor = 'black', borderWidth = 1,  borderColor = 'black',} = style;
      
      ctx.beginPath();
      ctx.strokeStyle = borderColor;
      ctx.fillStyle = backgroundColor;
      ctx.lineWidth = borderWidth;
      ctx.fillRect(x, y, w, h);
    }
   */

// draw a line
const drawLine = (info, style = {}) => {
    const { x, y, x1, y1 } = info;
    const { color = 'black', width = 1 } = style;
   
    ctx.beginPath();
    ctx.moveTo(x, y);
    ctx.lineTo(x1, y1);
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
    ctx.stroke();



  }


    return (
      <div className="App">
        <canvas className={"profpreview"} ref={canvas}></canvas>
      </div>
    );

}

