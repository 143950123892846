import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../Components/Loading";




import aws_exports from "../aws-exports";
import { Discovery } from "aws-sdk";



Amplify.configure(aws_exports, {});

let StatusTimer = null;


 
const DefaultStatus = {
    SysIdx: 0,
    On: 0,
    WeighingActive: 0,
    EmergencyStop: 0,
    EmergencySensors: 0, //Bits
    AlarmCode:  0,  
    SuspiciousSilos: 0, //Bits. Deviation from calibration value too large - Only when weighing
    EmptySilos:0 //Bits. Silos blocket or empty- Only when weighing 



};


const AlarmString = [
    "",
    "Unknown"


];



let StatTime = new Date (2000, 1,1, 0,0,0,0);



   let eventKey = null; 
export default function Status(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);

 const [IsLoading, setLoading] = useState(true);
  const [Status, setStatus] = useState(null);




  const [StatusArray, setStatusArray] = useState([]);

 useEffect(() => {

  loadData();
  StatusTimer = setInterval (()=>{
 
    fetchStatus();
   }, 15000);

 return () => { 

   if (StatusTimer){clearInterval(StatusTimer)}  }; 



}, [props.TheFarm]);


const loadData = async () => {


  fetchStatus()


}



  const fetchStatus = () => {
 
 

    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/getstatus";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.TheFarm


      }
    };



    

    API.get(apiName, path, myInit).then(response => {


        setStatusArray(response);
  
    })
      .catch((err) => {
        console.log ("Status error response: "+JSON.stringify (err))
      })

  };



/*
fa-toggle-off"\f204"
 fa-toggle-on

fa-heartbeat

To increase icon sizes relative to their container, use the fa-lg (33% increase), fa-2x, fa-3x, fa-4x, or fa-5x classes.
<i class="fa fa-camera-retro fa-lg"></i> fa-lg
<i class="fa fa-camera-retro fa-2x"></i> fa-2x
<i class="fa fa-camera-retro fa-3x"></i> fa-3x
<i class="fa fa-camera-retro fa-4x"></i> fa-4x
<i class="fa fa-camera-retro fa-5x"></i> fa-5x


 Loading example (with fa-spinner icon) Loading (with fa-circle-o-notch icon) Loading example (with fa-refresh icon) Loading example (with fa-cog icon)Loading example (with fa-spinner icon)
Use the fa-spin class to get any icon to rotate, and use fa-pulse to have it rotate with 8 steps. Works well with fa-spinner, fa-refresh, and fa-cog.

<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
<span class="sr-only">Loading...</span>

<i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw"></i>
<span class="sr-only">Loading...</span>

<i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
<span class="sr-only">Loading...</span>

<i class="fa fa-cog fa-spin fa-3x fa-fw"></i>
<span class="sr-only">Loading...</span>

<i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i>
<span class="sr-only">Loading...</span>


*/

  let TheStuff = "";

  if (StatusArray.length > 0){
    TheStuff = [];
    let len = StatusArray.length;
    for (var i=0; i<len; i++ ){

        let SysId = "";
        if (len > 1)
            SysId = <div className="status-sysidx float-left"> {(StatusArray[i].SysIdx+1).toString()+": "}</div>;

        let classAutoOn = "fa fa-toggle-off fa-2x fa-fw";

        if (StatusArray[i].AutoOn > 0)
            classAutoOn= "fa fa-toggle-on fa-2x fa-fw";


        let classRevolv = "fa fa-cog fa-2x fa-fw"

  
        if (StatusArray[i].FeedingRoundOn > 0)
            classRevolv += " slow-spin";

        let classWeighing = "fa  fa-hourglass-half fa-2x fa-fw";
 
        if (StatusArray[i].WeighingOn> 0)
            classWeighing = "fa  fa-balance-scale fa-2x fa-fw"


        let classEmergencyStop = "";
        if (StatusArray[i].EmergencyStop> 0)
          classEmergencyStop = "fa fa-ban fa-2x fa-fw color-red "



        let classAlarms = ""; 
        let alarmText = "";


        if (StatusArray[i].Alarms && StatusArray[i].Alarms > 0){
            classAlarms = "fa fa-bell fa-2x fa-fw color-red back-gray";
            let bits = StatusArray[i].Alarms;
            let txt = "";


            if ((bits & (1<<0)) > 0){
              txt += "- Overload ";
             
            }
            if ((bits & (1<<1))>0){
              txt += "- Sensor ";
            }
            if ((bits & (1<<2)) > 0){
              txt += "- Loadcell 1 ";
            }
            if ((bits & (1<<3)) > 0){
              txt += "- LOadcell 2 ";
            }
            if ((bits & (1<<4))>0){
              txt += "- Weight transmitter 1 ";
            }
              
            if ((bits & (1<<5))>0){
              txt += "- Weight transmitter 2 ";
            }
            if ((bits & (1<<6))>0){
              txt += "- Aux.Input 5 ";
            }

            if ((bits & (1<<7))>0){
              txt += "- Aux.Input 6 ";
            }
            
            if ((bits & (1<<8))>0){
              txt += "- Aux.Input 7 ";
            }            

            if (txt[0] === "-")
              txt = txt.substring(2);

 
            if (txt === ""){
              txt = "Unknown ("+bits+")";
            }  

            alarmText = <div className="status-alarm-text back-yellow" >{txt}</div>
        }

//   /*     {SysId} */




        TheStuff.push(
           <div className = "status-single"  key = {i}>
<div className="float-left">
        <span className="fa-stack fa-1x">
  <i className="fa fa-square-o fa-stack-2x"></i>
  <strong className="fa-stack-1x"> {StatusArray[i].SysIdx+1}</strong>
</span>
</div>
           <div className="float-left"> <i className={classAutoOn} ></i></div>
           <div className="float-left"><i className={classRevolv} ></i></div>
            <div className="float-left"><i className={classWeighing} ></i></div>
            <div className="float-left"><i className={classEmergencyStop} ></i></div>
            <div className="status-alarm-entry"><i className={classAlarms}> </i>   {alarmText}</div>

           </div> 
        );
    }


  }



  

 return <div className="status-wrap" >
{TheStuff}


 </div>
}
