import React, { useState, useEffect, useRef } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import Cage from "./Cage"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Modal, Button, ButtonGroup, ToggleButton } from 'react-bootstrap';
import PopEditModal from "./PopEditModal";

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';





import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";

require ("./CagePane.css")

Amplify.configure(aws_exports, {});



export default function CagePane(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);
 const refPane = useRef(null);
 const [showModal, setShowModal] = useState (false);

 const [IsLoading, setLoading] = useState(true);
 const [PaneWidth, setPaneWidth] = useState(0);

const [PaneHeight, setPaneHeight] = useState(0);

const [PopEditID, setPopEditID] = useState (props.show);

 useEffect(() => {

 
    let aspRatio =  props.Preferences.CagePaneHeight / props.Preferences.CagePaneWidth;


    if (refPane.current && refPane.current.offsetWidth > 0){
        setPaneWidth (refPane.current.offsetWidth )
        setPaneHeight ( Math.round( refPane.current.offsetWidth*aspRatio) ) 
    }
}, [refPane.Current]);




const handleResize= () => {

    setPaneWidth (refPane?.current?.offsetWidth)
    setPaneHeight (refPane?.current?.offsetWidth*props.Preferences.CagePaneHeight / props.Preferences.CagePaneWidth)
  
}

 window.addEventListener('resize', handleResize)





const closeModal = () => setShowModal(false);



const handleMouseDown= (event) => {

   
    let n = event.target.id.indexOf ("_");
    let idx = parseInt (event.target.id.substr(n+1));

    setPopEditID (idx);
    setShowModal (true);
    

   }
 
let cages = []


if (PaneWidth > 0){

    let len = props.Cages.length;
for (let i = 0; i<len; i++){

    cages.push (<Cage  Cage={props.Cages[i]} key={i} id={i}  MouseDownHandler={handleMouseDown }  {...props}  
            PaneWidth= {PaneWidth}   PaneHeight = {PaneHeight}/>)
}
}









let CPStyle = {height:PaneHeight}


let edCage = null;
if (PopEditID >= 0){
     edCage = props.Cages[PopEditID];
  
     
}





 return <div id="cagepane" ref = {refPane}  style = {CPStyle}>
 {<PopEditModal Cage = {edCage}  show = {showModal}  Close = {closeModal}  {...props}    />}
{cages}

 </div>
}



    