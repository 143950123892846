import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";


import Amplify, { graphqlOperation, API } from "aws-amplify";

import Form from "react-bootstrap/Form";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import ThemeContextProvider from './contexts/ThemeCtxt';
import TableView from "./TableView";
import { Loading } from "../Components/Loading";







import moment from "moment";


import "./Feeding.css";
import BootstrapTable from "react-bootstrap-table-next";


import { listUsers, ListSimpleOrg } from "../customGQL/customQueries";


//import Users from "./Users";
//import Organisations from "./Organisations";
//import Farms from "./Farms";
//import LicenceGenerator from "./LicenceGenerator";
//import Populations from "./Populations";
//import Counter from "./Counter";
import aws_exports from "../aws-exports";


Amplify.configure(aws_exports, {});






export default function Feeding(props) {



  const [EdAmount, set_EdAmount] = useState(0);
  const [EdFeedType, set_EdFeedType] = useState (null);




  let FeedTypeList = props.FeedTypeList.map ((pidObj, index) => { return{label: pidObj.FeedId, value: pidObj.FeedId}});


  useEffect(() => {
    set_EdFeedType (props.SiloInfo.CurrentFood);
    set_EdAmount (0);
    loadData();
  }, [props.SiloInfo.SiloId]);








  const loadData = async () => { 


   }




   const onSubmit = () => {
 



     

    

     let Amount = parseFloat(EdAmount);


props.SiloInfo.KilosInSilo  +=  Amount;
props.SiloInfo.CurrentFood = EdFeedType;

const row = {...props.SiloInfo};

     props.updateParent(row);
     PostAddFeed();

   }
   
   
     const  PostAddFeed = async () => {

      let apiName = "vakicloudRdsAPI";
      let path = "/counters/feeding/addfood";

      let grams = 1000* parseFloat (EdAmount);
      grams=Math.round(grams);

      let myInit = {
    
        // OPTIONAL
        headers: {
          
        }, // OPTIONAL
        response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
        queryStringParameters: {

          FarmID:props.FarmID,
          SiloId:props.SiloInfo.SiloId

        }  ,
    
    
        
    
    
    
        body: {
          amount: grams.toString(),
          type: EdFeedType,
          datetime: moment().format()
    
        }
        
        /*
        , 
        body : {values:  setString}
        */
    
    
    
    
      };
    

     


      API.post(apiName, path, myInit)
      .then ((response) => {
        
    
          set_EdAmount(0);
    
      })
      .catch ((er) => {
        console.log ("Catch AFTER postaddfeed er: "+JSON.stringify(er));
    
      })
    
    
    }






   


   const onCancel = () => {
    set_EdAmount(0);
    set_EdFeedType(null);
    
    props.HideMe();
  }








  return (

    <div className="addfeed-container">

    <div  className="addfeed-box" >
    <div className="ped-box-title">

{"Add feed to silo " + props.SiloInfo.SiloId}
<div>
{"("+props.SiloInfo.KilosInSilo+" kg)"}
</div>
</div>

<div className="addfeed-content">

      <Form>

  

        <Form.Group  as= {Row} >
        <Form.Label >Select feed type </Form.Label>
        <Select
        className = {"addfeed-typeselect"}  
      
        options={FeedTypeList}
        value={{label:EdFeedType}}
        onChange = {(sel)=> set_EdFeedType (sel.label)}

      />
</Form.Group>
<Form.Group  as= {Row} >
              <Form.Label >Enter amount (kg) </Form.Label>

              <Form.Control type="number"  name="AddeWeight" value={EdAmount} onChange={(e)=>{ set_EdAmount(e.target.value)}} />



            </Form.Group>
        </Form>



        </div>

        <div className="addfeed-box-footer">
         
          <ButtonGroup className="d-flex">
            <Button variant="outline-secondary" type="Button" disabled={EdAmount == 0}  onClick={onSubmit} >
              Submit
    </Button>
            <Button variant="outline-secondary" type="Button" onClick={onCancel}  >
              Close
    </Button>

          </ButtonGroup>

        </div>

        </div>
    </div>

  );
}
