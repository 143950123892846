import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";
import ProfileColumn from "./ProfileColumn"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";



Amplify.configure(aws_exports, {});


 




   let eventKey = null; 
export default function HideShowCages(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);

 const [IsLoading, setLoading] = useState(true);
 const [IsModified, setIsModified] = useState (false);
 const [SaveMessage, setSaveMessage] = useState ("");

 const [EdCages, setEdCages] = useState([]);
const [AllCages, setAllCages] = useState([]);







 useEffect(() => {

  loadData();
}, [props.FarmID]);


const loadData = async () => {


  fetchCageData()


}



  const fetchCageData = () => {
    setSaveMessage("");


    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/gethideshowcages";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID



      }
    };


    setLoading(true);

    API.get(apiName, path, myInit).then(response => {


        setAllCages(response);

        let cg  = response.map ((pc) =>{return {...pc}});

        setEdCages(cg);
 
    


      setLoading(false);
      setIsModified (false);



    })
      .catch((err) => {

        setSaveMessage ("Error loading HideShow: "+JSON.stringify (err.message))
        setLoading(false);

      })

  };


















const handleEditHide =  (e)=>{

    var len = EdCages.length;
    //let tmpCages  = EdCages.map ((ec) =>{return {...ec}});
    let tmpCages = [];
    for (var i = 0; i<len; i++){
        tmpCages.push ({...EdCages[i]});
    }


    if (!e) return; 









    let nIdx = parseInt(e.target.name);
    let newVal = (tmpCages[nIdx].Hidden > 0? 0:1) ;
    tmpCages[nIdx].Hidden = newVal;  


    setIsModified(true);
    setEdCages (tmpCages);
    setSaveMessage ("");

  }
  





let TheStuff = "";

if (EdCages && EdCages.length > 0){
    TheStuff = [];
    let numCgs = EdCages.length;
 
    for (var i = 0; i <numCgs; i++){
        TheStuff.push(
             
           <div className = "hid-item" key={i} >
           <ToggleButton
           
           type="checkbox"
           name={i}
           variant="outline-secondary"
           checked={EdCages[i].Hidden > 0}
           onChange={handleEditHide}
  >
    
       <div className="hid-label" >

           {EdCages[i].Name}</div>

</ToggleButton>
</div> 
        )
       
    }

    TheStuff = 
    <div>
         Cages connected to a pipe can't be hidden. 
           Check/uncheck for hiding/unhiding
    <div className="hid-wrapitems">
                  
        {TheStuff} </div></div>;


}


 
 const isSaveDisabled = ()=>{


    return !IsModified;


 }



const handleClose = ()=>{
  props.HideMe();
}

const handleSave = ()=>{

    let fields = "(FarmID,  CageId, Hidden) ";

    let len = EdCages.length;
    let cnt = 0;
    let values = "";
 
    let cntUC = 0;
    for (var i = 0; i< len; i++){
         if (cnt > 0)
            values += ", ";
         values += "("+props.FarmID+", '"+EdCages[i].CageId+"', "+ EdCages[i].Hidden+")";
         cnt++;

    }
  
  
  
  
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/savehideshowcages";
    let myInit = {
      // OPTIONAL
      headers: {
       
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        FarmID: props.FarmID
  
      },
      body: {
        values: values,
        fields: fields
 
      }
  
    };
    setSaveMessage ("");
    API.post(apiName, path, myInit)
    .then ((response) => {
        setIsModified (false);
        setAllCages (EdCages);
        setSaveMessage ("Save successful");
  
  
    })
    .catch ((er) => {
  
      setSaveMessage ("Error saving: "+JSON.stringify(er.message));
  
  
    })

  
}








  let SaveDisabled = isSaveDisabled();

  

 return <div>

{IsLoading && <Loading />}


   <div className="ptc-buttons">



<ButtonGroup  className="btn-group d-flex">
              <Button variant="outline-secondary" type="Button"  onClick = {handleClose } >
                Close
    </Button>
    
              <Button variant="outline-secondary" type="Button"   onClick = {handleSave } disabled={SaveDisabled} >
                Save
    </Button>
    
            </ButtonGroup>

{SaveMessage}
</div>


{!IsLoading && TheStuff}



 </div>


}


    