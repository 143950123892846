import React, { useState, useEffect } from "react";

import { Row, Col, Button } from "react-bootstrap";
import "./SessionMain.css";
import  SessionMainTable from "./SessionMainTable.js";
import InformationTabContainer from "./InformationTabContainer";
import BootstrapTable from "react-bootstrap-table-next";
import  "./LargeSpinner.css";
import convertMSE from "../Components/Utils/convertMSE";
import aws_exports from "../aws-exports";
import Amplify, { API } from "aws-amplify";


Amplify.configure(aws_exports, {});

 /*
var MainObject = {

TotalBiomass:-1,
TotalAverageWeight:-1,
Lasting:-1,
DateTime:-1.0,
Companyname:"",
Nocommentlines:-1,
//logoimage=
ChannelC_Version:"",
pdfFilePath:"",
pdfFile:"",
NrCountingUnits:-1,
  CountingUnits:[]
};


blcFilePath: "",
   cntFilePath: "",
   mseFilePath: "",
   blcFile:"",
   cntFile:"",
   mseFile:"",
   Name: "",
  ID: -1,
  FileName:"",
  SizeSetting: "",
  Count:-1,
  Percent_Count:-1,
  Biomass:-1,
  Percent_Biomass:-1,
  Counter_type:"",
  Average_weight:""


  */

 var mseFetchingIndex = 0;

 var mseDetailData = [];


  export default function SessionMain(props) {

 

  const [tableData, setTableData] = useState([]);

  const [loadingMse, setLoadingMse] = useState(false);
  const [mseLoaded, setMseLoaded] = useState(false);  

  




  useEffect(() => {


    let d = props.Data;
    let len = d.NrCountingUnits;


    let cu = [];
 




    let sessNameLen = d.SessionDescription.length;
    sessNameLen += 8+2;   //Add Length of DateStamp and the 2x'_'
    let numUnits = d.NrCountingUnits;

    for (let i=0; i<numUnits; i++){


        d.CountingUnits[i].NetName = d.CountingUnits[i].FileName.substring(sessNameLen,  d.CountingUnits[i].FileName.toUpperCase().indexOf(".MSE") );
        cu.push({
            NetName : d.CountingUnits[i].NetName,
            Counter_type : d.CountingUnits[i].Counter_type,
            Count : d.CountingUnits[i].Count,
            Percent_Count : d.CountingUnits[i].Percent_Count,
            Biomass : d.CountingUnits[i].Biomass,
            Percent_Biomass : d.CountingUnits[i].Percent_Biomass,
            Average_weight : d.CountingUnits[i].Average_weight,

        key: i+1
  
        });
   
   
    }
  


    mseFetchingIndex = 0;

    setTableData (cu);    
  
    setLoadingMse(false);
    setMseLoaded (false);
    




  }, []);



  







 const  getDurationString = (days) => {
        if (isNaN( days) )
            return "";
        let h = Math.floor (days*24);
        let m = Math.floor( days*24*60 - h*60);
        let s = Math.round(days*24*60*60 - h*60*60 - m*60 );
      
        return <p> <span className="siz-h"> { h+"h "}</span>  <span className="siz-m" >{m+"m "} </span> <span className="siz-s"> {s+"s"} </span> </p>
        
        
      
   
    
    

  }









//const SessionMain = props => (


const MseDetailsNotify = (idx) => {


    let len = props.Data.NrCountingUnits;
    let tmp = [];
    for (let i = 0; i<len; i++){
        tmp.push({...tableData[i], key:i})
    }
    tmp [idx]. activeButton = false;
    setTableData(tmp);

};


const getMseFile = async (s3Path, urls) => {




//    s3Path = s3Path.replace("999999/", "3153/")
 





    let apiName = "vakicloudRdsAPI";
    let path =  "/getFile"; 
    let myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
          S3Path: s3Path
      }
    };




    API.get(apiName, path, myInit).then(resp => {
    //  let str  =  resp.success.toUpperCase();
   
      

      let data = null;
        if (mseFetchingIndex==0)
            mseDetailData = [];
        data = convertMSE(resp.success);
        mseDetailData.push({
          mainData:null,
          informationRowData: data,
          newDataLoaded: true,
          newDataLoading: false,
          blcFileURL: props.Data.CountingUnits[mseFetchingIndex].blcFileUrl,
          cntFileURL: props.Data.CountingUnits[mseFetchingIndex].cntFile,
          mseFileURL: props.Data.CountingUnits[mseFetchingIndex].mseFileUrl,
          pdfFileURL: null,
          mseS3Path: props.Data.CountingUnits[mseFetchingIndex].mseS3Path,
          pdfFilePath: null,
          blcFilePath: props.Data.CountingUnits[mseFetchingIndex].blcFilePath,
          cntFilePath: props.Data.CountingUnits[mseFetchingIndex].cntFilePath,
          key:mseFetchingIndex
        });
       

        mseFetchingIndex++;
        if (mseFetchingIndex < props.Data.NrCountingUnits){
            setTimeout (callGetMseFile, 100, mseFetchingIndex);
        }else{
            setLoadingMse(false);
            setMseLoaded (true);
        }


        



    }).  catch (err =>{
      console.log ("Error when fetching msefile ("+mseFetchingIndex+") : "+err)

    });
   
  };


const  callGetMseFile = async (idx) =>
{

    getMseFile (props.Data.CountingUnits[idx].mseFilePath);
}  





const handleShowMseClick = () => {
  
    setLoadingMse (true);

    mseFetchingIndex = 0;
    mseDetailData = [];

    //TestTest ();


    getMseFile( props.Data.CountingUnits[0].mseFilePath, {}  );
}


const scrollToBottom = () => {
    let fakeD = document.getElementById("fakeDiv");
    fakeD.scrollIntoView({ behavior: "smooth" });
  };


var MseArea = "";

if (loadingMse){
    MseArea = <div className="spinner-wrap">  <div className="loader"></div> </div>

} else if (!mseLoaded){
    MseArea=     <Button variant="outline-secondary" type="Button" block name = "getmse"  onClick = {handleShowMseClick}  >
    Fetch units details
    </Button>;
    
}else {
    MseArea = [];
    let len = mseDetailData.length;


    for (let i=0; i<len; i++){



        MseArea.push(
     <div className = "unit-wrap">       
        <h4> {"Unit: "+props.Data.CountingUnits[i].NetName }</h4>
    <InformationTabContainer

    key={i}    
    data= {mseDetailData[i].informationRowData}
    blcFileURL = {props.Data.CountingUnits[i].blcFileUrl}
//cntFileURL: props.Data.CountingUnits[mseFetchingIndex].cntFile,
    mseFileURL = {props.Data.CountingUnits[i].mseFileUrl}
    pdfFileURL={null}
    mseS3Path = {props.Data.CountingUnits[i].mseS3Path}
    pdfFilePath={null}
    blcFilePath = {props.Data.CountingUnits[i].blcFilePath}
    cntFilePath= {props.Data.CountingUnits[i].cntFilePath}
    scrollToBottom= {null} //{scrollToBottom}
        />
        </div>
        );

    }
    //display details
}

let TheDuration = getDurationString (props.Data.Lasting)

let pdfFileUrl = props.Data.pdfFile; //.replace("999999", "3153")


    return(
  <div>
    <Row className="Information-tab">
      <Col sm>
        <div>
          <h4>
            <span> Total Counted:</span>{" "}
            <span><b>{props.Data.FishCount.toLocaleString()}</b></span>
          </h4>
        </div>
      </Col>
    </Row>
    <hr />
    <Row className="Information-tab">
      <Col xl={2} sm={6}>
        <div>
          <h4 className="inl">
            <span className="inl-front"> Session:</span>
            <span className="inl-back">{props.Data.SessionDescription}</span>
          </h4>


 
        </div>
      </Col>
      <Col xl={2} sm={6}>
        <div>
        <h4 className="inl">
            <span className="inl-front"> From: </span>
            <span className="inl-back">{props.Data.Origin}</span>
          </h4>
        </div>
      </Col>

      <Col xl={2} sm={6}>
      <h4 className="inl">
            <span className="inl-front"> To: </span>
            <span className="inl-back">{props.Data.Destination}</span>
          </h4>
      </Col>
      <Col xl={2} sm={6}>
      <h4 className="inl">
            <span className="inl-front"> Operator:</span>
            <span className="inl-back">{props.Data.Personincharge}</span>
          </h4>
      </Col>
      <Col xl={2} sm={6}>
      <h4 className="inl">
            <span className="inl-front"> Duration:</span>
            {TheDuration}
            </h4>
      </Col>
    
    
    <Col xl={2} sm={6}  className="dl-buttons">
    <a id="PDF-dl" href={pdfFileUrl}> <i className="fa fa-download" aria-hidden="true"></i> Download PDF Report  </a>
      </Col>
    </Row>

    <SessionMainTable TableData={tableData}    MseDetailsNotify = {MseDetailsNotify} />
    <div id="mse-wrap">
        
        {MseArea}
    </div>
  </div>
);
};

