import React, { useState, useEffect } from "react";
import { default as Select } from "react-select";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";
import ProfileColumn from "./ProfileColumn"
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";


import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl,Button,ButtonGroup, FormGroup } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";

import { Loading } from "../../Components/Loading";
import cellEditFactory from 'react-bootstrap-table2-editor'

import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";




Amplify.configure(aws_exports, {});


 







export default function SGRtables(props) {

 //   const [MenuSelect, setMenuSelect] = useState(null);
 

 const [SaveError, setSaveError ] = useState("");
 const [Modified, setModified] = useState (false)
 const [IsLoading, setLoading] = useState(true);
 const [SGRnameList, setSGRnameList] = useState([]);
 const [SelectedSGR, setSelectedSGR ] = useState (null);
 const [EdSGRdata, setEdSGRdata] = useState([]);
 const [SGRdata, setSGRdata] = useState([]);
 const [Columns, setColumns] = useState([]);
 const [EdAddEntry, setEdAddentry] = useState ("");
 const [EdWeightsSorted, setEdWeightsSorted] = useState ([]);
 const [EdTempSorted, setEdTempSorted] = useState ([]);
 const [IsModified, setIsModified] = useState (false);

 const [SaveMessage, setSaveMessage] = useState ("");




useEffect(() => {
  
    loadData();
  }, [props.FarmID]);


  const loadData = async () => {
    fetchTheNames()
  };

  const fetchTheNames = () => {


  let apiName = "vakicloudRdsAPI";
  let path = "/counters/feeding/sgrnames";
  let myInit = {

    // OPTIONAL
    headers: {}, // OPTIONAL
    response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    queryStringParameters: {
      // OPTIONAL
      FarmID: props.FarmID



    }
  };




  API.get(apiName, path, myInit).then(response => {

    setSGRnameList(response);
    setSelectedSGR("");

    setLoading(false);


  })
    .catch((err) => {
   
      setLoading(false);
      setSGRnameList([]);
      setSelectedSGR("");

    })

  };



  const handleEdit = (e) => {
        console.log ("handleEdit row: "+e.target.parentNode.parentNode.rowIndex+ "       cell:"+e.target.parentNode.cellIndex+"    val: "+e.target.value+"   name: "+e.target.parentNode.name);

  };

  const handleEditTemp = (e, row, rowIndex) => {
    console.log ("handleEditTemp ");

};




  let SGRSelectList = SGRnameList.map ((pidObj, index) => { return{label: pidObj.SGRId+ "  ("+pidObj.Description+")" , value: pidObj.SGRId}});
  const handleSelectedSGR = (sel)=>{
    setSelectedSGR (sel.value);

    setLoading (true);
    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/sgrtable";
    let myInit = {
  
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID,
        SGRId: sel.value
  
  
  
      }
    };
  
  
  
  
    API.get(apiName, path, myInit).then(response => {
  


        let sgrVals = response.Table;
        let Weights = response.Weights;
        let Temps = response.Temps;

        let numRows = Weights[0].Count;
        let numCols = Temps[0].Count;
      
        let tempSorted = []; 
        let weightSorted = [];

        for (var i=0; i<Weights.length; i++ )
            weightSorted.push(Weights[i].WeightGrams);

        for (var i=0; i<Temps.length; i++ )
            tempSorted.push(Temps[i].Temperature);


        let columns = createColumns (weightSorted);
        
        /*
        [
                {
                    dataField: 'Temp',
                    text: 'grams/ Celsius',
                    name: "temp",
                    editable:false,
                    style: { minWidth:"4em", maxWidth:"8em" },
                    formatter: (cell) =>{return cell+"°C"} ,
   
                    sort: false
                }];

        for (var i = 0; i<numCols; i++){
            
            columns.push({
                dataField: Weights[i].WeightGrams.toString(),
                text: Weights[i].WeightGrams.toString()+"g",
                name: Weights[i].WeightGrams.toString(),
                editable:true,
                style: { minWidth:"7em"},
                editor: {
                    type: "number",
                    onChange: handleEdit
                    
                  },
            });
        }
  */
        let cntVals = 0;
        let theData = [];
        for (var t=0; t<numRows; t++){
            let rw = {Temp:Temps[t].Temperature}
            for(var w = 0; w<numCols; w++){
                rw[Weights[w].WeightGrams.toString()] = sgrVals[cntVals++].SGRvalue;
            }
            theData.push (rw);
        }



  
     
        setEdTempSorted (tempSorted);
        setEdWeightsSorted(weightSorted);
        setColumns(columns);
        setEdSGRdata (theData);
        setSGRdata(theData);
    
  
      setLoading(false);
  
  
    })
      .catch((err) => {
     
        setLoading(false);
        setColumns([]);
        setEdSGRdata ([]);
        setSGRdata([]);
  
  
      })




    setModified(false);
    setSaveError ("");

  }



const createColumns = (weights) => {

    let numCols = weights.length;

    let columns = [
        {
            dataField: 'Temp',
            text: 'grams/ Celsius',
            name: "temp",
            editable:true,
            style: { minWidth:"4em", maxWidth:"8em" },
            formatter: (cell) =>{return cell+"°C"} ,
            editor: {
                type: "number",
                onChange: handleEditTemp
            
            },

            sort: false
        }];

    for (var i = 0; i<numCols; i++){
 
        columns.push({
            dataField: weights[i].toString(),
            text: weights[i].toString()+"g",
            name: weights[i].toString(),
            editable:true,
            style: { minWidth:"7em"},
            editor: {
                type: "number",
                onChange: handleEdit
            
            }
        });
    }

    return columns;

}



const handleCreateNew = () => {

 
}

const handleSave = () =>{

    let numRows = EdTempSorted.length;
    let numCols = EdWeightsSorted.length;
    let fields = "(FarmID, SGRid, WeightGrams, Temperature, SGRvalue)";
    let values = "";
    let usedTemps = "(";
    let usedWeights = "(";

    for (var r=0; r<numRows; r++){
        if (r>0)
            usedTemps+=", ";
        usedTemps += EdTempSorted[r];    
         for (var c=0; c<numCols; c++){

            let str = "("+props.FarmID+", '"+SelectedSGR+"', "+EdWeightsSorted[c]+", "+EdTempSorted[r]+", "+EdSGRdata[r][EdWeightsSorted[c].toString()]+")";

             if (r==0 && c==0)
                ;
             else
                values+=", ";
               
            values+=str;    
         }   
    }

    for (var c=0; c<numCols; c++){
        if (c>0)
            usedWeights+=", ";
        usedWeights += EdWeightsSorted[c]; 
    }

 
    usedWeights+=")";
    usedTemps+=")";




    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/savesgr";
    let myInit = {
      // OPTIONAL
      headers: {
       
      }, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        FarmID: props.FarmID,
        SGRid: SelectedSGR
      },
      body: {
        values: values,
        fields: fields,
        usedWeights: usedWeights,
        usedTemps: usedTemps,
        U: props.userData.name 
      }
  
    };
    setSaveMessage ("");
    API.post(apiName, path, myInit)
    .then ((response) => {
   
        setIsModified (false);

        setSaveMessage ("Save successful");
  
  
    })
    .catch ((er) => {
  
      setSaveMessage ("Error saving: "+JSON.stringify(er.message));
  
  
    });





}

const handleClose = () =>{
props.HideMe();


}


const getIndex = (newEntry, refArray) => {
    let len = refArray.length;
    let newIdx = -1;
    for (let i=0; i<len; i++){
        if (newEntry == refArray[i])
            return;
        if (i==0 && newEntry < refArray[i]){
            newIdx = 0;
            break;
        }else if (i== (len-1)){
            newIdx = len;
            break;
        }else if (newEntry > refArray[i] && newEntry<refArray[i+1]){
            newIdx = i+1;
            break;
        }
    }
    return newIdx;
}

const insertInSortedArray = (theValue, theIndex, theArray ) => {
    let tmpArr = [];
    for (var i=0; i<theArray.length; i++){
        if (i==theIndex)
            tmpArr.push (theValue);
        tmpArr.push(theArray[i]);    
    }

    if (tmpArr.length == theArray.length)
        tmpArr.push (theValue);

    return tmpArr;    

}

const handleClickAdd = (e)=>{


    if (EdAddEntry === "")
        return;
       
    let newEntry = parseFloat (EdAddEntry);

    
    if (e.target.name == "addTemp"){
        
        let newIdx = getIndex(newEntry, EdTempSorted);

        setEdTempSorted(insertInSortedArray(newEntry, newIdx, EdTempSorted)); 



        //new row
        let NewRow = {Temp:newEntry};
      
        for(var w = 0; w<EdWeightsSorted.length; w++){
            NewRow[EdWeightsSorted[w].toString()] = 0;
        }

        let newData = [];
        for (var i = 0; i<EdSGRdata.length; i++)
            newData.push ({...EdSGRdata[i]})

        newData.splice (newIdx, 0, NewRow);
        setEdSGRdata(newData);

         

    }  else {

        let newIdx = getIndex(newEntry, EdWeightsSorted);
        let newWeightArray = insertInSortedArray(newEntry, newIdx, EdWeightsSorted);
   
        setColumns(createColumns( newWeightArray));
        setEdWeightsSorted(newWeightArray);
        let newData = [];
        for (var i = 0; i<EdSGRdata.length; i++){
            newData.push ({...EdSGRdata[i]})
            newData[i][newEntry.toString()] = 0;
        }
        setEdSGRdata (newData);
    }  
  
    setEdAddentry ("");

}










let SaveDisabled = false;
let EditNamePlaceholder = "";




let SaveErrorEntry = "";

if (SaveError && SaveError != ""){
    SaveErrorEntry = <div style = {{color:'red'}}>{SaveError}</div>
}


let DoTable = (Columns.length > 0) && !IsLoading;



return <div  className="sgr-wrap"  >
<table>

    <tr>

<div className="sgr-fields-wrap"   >




{IsLoading && <Loading />}


<Form>

<Form.Group as={Row}>
    
<Form.Label    column sm="3">SGR ID:</Form.Label>

<Col sm = {"9"}>
<Select 

block
options={SGRSelectList} 
value={ (!SelectedSGR?null: {label:SelectedSGR, value: SelectedSGR })} 
onChange= {handleSelectedSGR } 
placeholder = {"Select ..."}
/>




</Col>
</Form.Group>


<Form.Group>

<Button variant="outline-secondary" type="Button"   block onClick = {handleCreateNew} >
                Create new table
    </Button>

    
    </Form.Group>

    <Form.Group >
              <Form.Label>SGR ID: </Form.Label>
              <Form.Control type="text"  placeholder={EditNamePlaceholder} style={{width:"100%"}} value={SelectedSGR}   name="name" autoComplete="off"   />
            </Form.Group>


</Form>

<ButtonGroup  className="btn-group d-flex">
              <Button variant="outline-secondary" type="Button"  onClick = {handleClose } >
                Close
    </Button>
    
              <Button variant="outline-secondary" type="Button"   onClick = {handleSave } disabled={SaveDisabled} >
                Save
    </Button>
    
            </ButtonGroup>

{SaveMessage}

</div>

<div className="sgr-fields-wrap">

<Form>

<Form.Group as = {Row}>
              <Form.Label><div>Enter Weight or Temperature value: </div></Form.Label>
              <Form.Control type="number"  placeholder={"Enter number..."}  value={EdAddEntry}   name="headerval" autoComplete="off" onChange={(e)=>setEdAddentry(e.target.value)}    />
            </Form.Group>


<Form.Group>

<Button variant="outline-secondary" type="Button"  name="addTemp" block onClick = {handleClickAdd} >
                Add Row (Temperature value) 
    </Button>

    <Button variant="outline-secondary" type="Button"  name="addWeight" block onClick = {handleClickAdd} >
                Add Column (Weight value)
    </Button>
    
    </Form.Group>

 


</Form>


</div>
</tr>
</table>
<div className="sgr-table-wrap">
{DoTable && < BootstrapTable
            hover
            keyField='Temp'
            data={EdSGRdata}
            noDataIndication="SGR table empty"            
            //data = {[{PipeId:1, key:100},{PipeId:2, key:101} ]}
            columns={Columns}
           /* rowEvents={RowEvents}*/
            cellEdit={ cellEditFactory({ mode: 'click' }) }

          />}
</div>


</div>


}