import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import PopEdit from "./../PopEdit";
require ("./CagePane.css")


export default function PopEditModal (props) {
  const [show, setShow] = useState(props.show);

  const handleClose = () => props.Close();
  



let Title = "";
if (props.Cage)
    Title=props.Cage.Name;

  return (
    <>



      <Modal
        className = "PopEditModal"
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title  >{Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body  >
        <PopEdit   {...props} CageInfo={props.Cage}   HideMe = {handleClose} 
         />
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
    </>
  );
}

