/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      name
      admin
      consent
      email
      firstName
      lastName
      phoneNumber
      role
      BiomassDaily
      Counters
      InspectionStation
      Spotlice
      organisation {
        items {
          id
          orgName
          orgID
          email
          address
          phoneNumber
          timezone
          isActive
        }
        nextToken
      }
      organisations {
        items {
          id
        }
        nextToken
      }
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        admin
        consent
        email
        firstName
        lastName
        phoneNumber
        role
        BiomassDaily
        Counters
        InspectionStation
        Spotlice
        organisation {
          nextToken
        }
        organisations {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      orgName
      orgID
      email
      address
      phoneNumber
      timezone
      isActive
      users {
        items {
          id
        }
        nextToken
      }
      farms {
        items {
          id
          farmName
          farmID
          email
          phoneNumber
          timezone
          lat
          long
          isActive
        }
        nextToken
      }
      counters {
        items {
          id
          counterID
          counterName
          organisationId
          organisationName
          farmId
          description
          counterType
          processData
          product
          location
        }
        nextToken
      }
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgName
        orgID
        email
        address
        phoneNumber
        timezone
        isActive
        users {
          nextToken
        }
        farms {
          nextToken
        }
        counters  {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getFarm = /* GraphQL */ `
  query GetFarm($id: ID!) {
    getFarm(id: $id) {
      id
      farmName
      farmID
      email
      phoneNumber
      timezone
      lat
      long
      isActive
      population {
        items {
          id
          popName
          popID
          species_id
          isActive
          population_GUID
        }
        nextToken
      }
    }
  }
`;
export const listFarms = /* GraphQL */ `
  query ListFarms(
    $filter: ModelFarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        farmName
        farmID
        email
        phoneNumber
        timezone
        lat
        long
        isActive
        population {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getPopulation = /* GraphQL */ `
  query GetPopulation($id: ID!) {
    getPopulation(id: $id) {
      id
      popName
      popID
      species_id
      isActive
      population_GUID
    }
  }
`;
export const listPopulations = /* GraphQL */ `
  query ListPopulations(
    $filter: ModelPopulationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPopulations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        popName
        popID
        species_id
        isActive
        population_GUID
      }
      nextToken
    }
  }
`;

export const getCounter = /* GraphQL */ `
  query GetCounter($id: ID!) {
    getCounter(id: $id) {
      id
      counterID
      counterName
      organisationId
      organisationName
      farmId
      description
      counterType
      processData
      product
      location
    }
  }
`;

export const listCounters = /* GraphQL */ `
  query ListCounters(
    $filter: ModelCounterFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCounters(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        counterID
        counterName
        organisationId
        organisationName
        farmId
        description
        counterType
        processData
        product
        location
      }
      nextToken
    }
  }
`;






export const getLicence = /* GraphQL */ `
  query GetLicence($id: ID!) {
    getLicence(id: $id) {
      id
      licence
      counterID
      createdBy
      createdDate
      expireDate
      magicNumber
      proVersion
      cameraSerialNumber
      counterType
      productType
      comment
      CounterDef
    }
  }
`;


export const listLicences = /* GraphQL */ `
  query ListLicences(
    $filter: ModelLicenceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLicences(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        licence
        counterID
        createdBy
        createdDate
        expireDate
        magicNumber
        proVersion
        cameraSerialNumber
        counterType
        productType
        comment
        CounterDef
      }
      nextToken
    }
  }
`;
