import React, { useState, useEffect,useRef } from "react";



import "./Reports.css";

import moment from "moment";
import { DateRangePicker } from "react-dates";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button, ButtonGroup, ToggleButton } from 'react-bootstrap';

import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl, FormGroup,  OverlayTrigger, Popover,Overlay } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from 'react-bootstrap-table2-editor'
import { Type } from 'react-bootstrap-table2-editor';
import { Loading } from "../../Components/Loading";


import aws_exports from "../../aws-exports";
import { Discovery } from "aws-sdk";

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";




export default function ColumnSelect(props) {

const [hideCols, setHideCols] = useState (false);

const [show,setShow] = useState (false)
const target = useRef(null);

 useEffect(() => {

 //   setRoundTime (props.FeedSystems[props.FeedSystems[0].SysIdx].RoundTime); 
 //   setRoundsPrHour (props.FeedSystems[props.FeedSystems[0].SysIdx].RoundsPrHour); 
 //   setNoOfPipes (props.FeedSystems[0].NoOfPipes);

}, []);





const handleClose = ()=>{
  props.HideMe();
}










const onColClick = () => {setHideCols (!hideCols)}

  
 let ColDivClass = "Cols mb-3"

 if (hideCols)
 ColDivClass = "ColsInvisible"

let place ="bottom"


let TheContent = <Form>

        <div key={1} className={ColDivClass}>
          <Form.Check // prettier-ignore
            type="checkbox"
            id= "Total_feeding_pr/fish_(gr)"
            label= "Total feeding pr/fish (gr)"
          />

          <Form.Check
            
            type={"checkbox"}
            label="Total feeding (kg)" 
            id="Total_feeding_(kg)" 
          />
        </div>
    </Form>

let buttonText = "Select Columns";

if (show)
  buttonText = "Hide Selection"



return  <>

<Button variant='outline-secondary' ref={target} onClick={() => setShow(!show)}>
  {buttonText}
</Button>


<Overlay target={target.current} show={show} placement="bottom">
  
  {({
    placement: place,
   // arrowProps: _arrowProps,
    show: show,
    //popper: _popper,
    //hasDoneInitialMeasure: _hasDoneInitialMeasure,
    ...props
  }) => (
    <div
      {...props}
      className="olClass"

    >
     
{TheContent}

    </div>
  )}
</Overlay>



</>
}




/*
let TheContent =  <div  className = "colSelectWrap">

<Form>
<Button variant="primary"  onClick = { onColClick} >
        Columns
      </Button>

        <div key={1} className={ColDivClass}>
          <Form.Check // prettier-ignore
            type="checkbox"
            id= "Total_feeding_pr/fish_(gr)"
            label= "Total feeding pr/fish (gr)"
          />

          <Form.Check
            disabled
            type={"checkbox"}
            label="Total feeding (kg)" 
            id="Total_feeding_(kg)" 
          />
        </div>
    </Form>
 </div>


*/