import { useState } from "react";

export const useInput = (initialValue) => {
  const [value, setValue ] = useState(initialValue);
  
  function handleChange(e){

    if ((typeof e) == "string" ||  (typeof e) == "number")
      setValue (e.toString())
    else if (e.target.type === "checkbox")
      setValue(e.target.checked);
    else
      setValue(e.target.value);
  }
  return {
      value,
      onChange: handleChange,
      reset: () => setValue(""),
                   
  }
};