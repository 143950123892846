import React, { useState, useEffect } from "react";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";

import MoveFish from "./MoveFish";
import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl,Button } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";

import { Loading } from "../../Components/Loading";







import aws_exports from "../../aws-exports";

Amplify.configure(aws_exports, {});






   let eventKey = null; 
export default function Actions(props) {






    const [MenuSelect, setMenuSelect] = useState(null);
 
    const [FeedSystems, setFeedSystems] = useState([]);



    useEffect(() => {

      setFeedSystems(props.FeedSystems)
   
    
   }, [props.FeedSystems]);



    const handleClick = (e ) => {
      
        setMenuSelect(e.target.name);
        
        }









const hideCallBack = ()=>{ setMenuSelect(null) }


let TheNavBar= 
<Navbar bg="light" expand="lg">
 {/* <Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand >*/}
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav" >


    <Nav className="mr-auto" >
      <Nav.Link href="#" name="MoveFish" onClick = {handleClick }>MoveFish</Nav.Link>
      <Nav.Link href="#" name="SGR" onClick = {handleClick }>unused </Nav.Link>
      <NavDropdown title="Unused dropdown" id="basic-nav-dropdown">
        <NavDropdown.Item href="#" name="pipestocages" onClick = {handleClick }>unused</NavDropdown.Item>
        <NavDropdown.Item href="#" name="pipesencoder" onClick = {handleClick } >unused</NavDropdown.Item>
        <NavDropdown.Item href="#" name="hideshow" onClick = {handleClick } >unused</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#">Separated link</NavDropdown.Item>
      </NavDropdown>
    </Nav>

  </Navbar.Collapse>
</Navbar>




let TheContent = <div style={{height:"30em"}}></div>;


//userData={props.userData} FarmID={FarmSelected.value}  FeedSystems = {FeedSystems}  UpdateSystem={UpdateSystem}  UpdateDash={UpdateDash} 

switch (MenuSelect){
  case "MoveFish":
    TheContent = <MoveFish  HideMe = {hideCallBack}  {...props} />;
    //TheContent = <MoveFish FarmID={props.FarmID} HideMe = {hideCallBack}   UpdateDash = {props.UpdateDash}   />;
  break;
  /*
  case "SGR" : 
    TheContent = <SGRtables FarmID={props.FarmID}   HideMe = {hideCallBack} />;
    break;
  case "pipestocages" : 
    TheContent = <PipesToCages FarmID={props.FarmID}  FeedSystems= {FeedSystems}  HideMe = {hideCallBack} />;
  break;
  case "pipesencoder" : 

    TheContent = <PipesEncoder FarmID={props.FarmID}  FeedSystems= {FeedSystems}  HideMe = {hideCallBack}  UpdateSystem = {props.UpdateSystem}  />;
  break;  
  case "hideshow" : 

  TheContent = <HideShowCages FarmID={props.FarmID}   HideMe = {hideCallBack}  UpdateSystem = {props.UpdateSystem}  />;
break; 
*/
  default: break;

}






return <div className="act-wrap">
{TheNavBar}

<div className = "act-content-wrap">
{TheContent}


</div>


</div>


}