import React, { useState, useEffect } from "react";
import moment from "moment";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Settings.css";
import Profiles from "./Profiles";
import SGRtables from "./SGRtables";
import PipesToCages from "./PipesToCages";
import PipesEncoder from "./PipesEncoder";
import HideShowCages from "./HideShowCages";
import CageLayout from "./CageLayout";
import CreateCages from "./CreateCages";
import Silos from "./Silos";
import { Navbar,Nav,NavItem, NavDropdown,Form,FormControl,Button } from 'react-bootstrap'

import BootstrapTable from "react-bootstrap-table-next";

import { Loading } from "../../Components/Loading";







import aws_exports from "../../aws-exports";

Amplify.configure(aws_exports, {});





/*

      <NavDropdown title="Dropdown" id="basic-nav-dropdown">
        <NavDropdown.Item href="#"  eventKey={5} name="Action" onClick = {handleClick }>Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown>


<Form inline>
<FormControl type="text" placeholder="Search" className="mr-sm-2" />
<Button variant="outline-success">Search</Button>
</Form>

*/

   let eventKey = null; 
export default function Settings(props) {

    const [MenuSelect, setMenuSelect] = useState(null);
 
    const [FeedSystems, setFeedSystems] = useState([]);



    useEffect(() => {

      setFeedSystems(props.FeedSystems)
   
    
   }, [props.FeedSystems]);



    const handleClick = (e ) => {
      
        setMenuSelect(e.target.name);
        
        }





      




const hideCallBack = ()=>{ setMenuSelect(null) }


let TheNavBar= 
<Navbar bg="light" expand="lg">
  {/*<Navbar.Brand href="#home">React-Bootstrap</Navbar.Brand>*/}
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse id="basic-navbar-nav" >


    <Nav className="mr-auto" >
      <Nav.Link href="#" name="Profiles" onClick = {handleClick }>Profiles</Nav.Link>
      <Nav.Link href="#" name="SGR" onClick = {handleClick }>SGR tables</Nav.Link>
      <NavDropdown title="Hardware config" id="basic-nav-dropdown">
        <NavDropdown.Item href="#" name="pipestocages" onClick = {handleClick }>Pipes to cages</NavDropdown.Item>
        <NavDropdown.Item href="#" name="pipesencoder" onClick = {handleClick } >Encoder and pipes</NavDropdown.Item>
        <NavDropdown.Item href="#" name="CreateCages" onClick = {handleClick } >Create cages</NavDropdown.Item>
        <NavDropdown.Item href="#" name="Silos" onClick = {handleClick } >Silos</NavDropdown.Item>
        <NavDropdown.Item href="#" name="hideshow" onClick = {handleClick } >Hide/show cages</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#">Separated link</NavDropdown.Item>
      </NavDropdown>
      <Nav.Link href="#" name="Layout" onClick = {handleClick }>Cage layout</Nav.Link>
    </Nav>

  </Navbar.Collapse>
</Navbar>




let TheContent = <div style={{height:"30em"}}></div>;


//userData={props.userData} FarmID={FarmSelected.value}  FeedSystems = {FeedSystems}  UpdateSystem={UpdateSystem}  UpdateDash={UpdateDash} 

switch (MenuSelect){
  case "Profiles":
    TheContent = <Profiles {...props} HideMe = {hideCallBack} />;
  break;
  case "SGR" : 
    TheContent = <SGRtables {...props}   HideMe = {hideCallBack} />;
    break;
  case "pipestocages" : 
    TheContent = <PipesToCages {...props}  HideMe = {hideCallBack}  />;
  break;
  case "pipesencoder" : 

    TheContent = <PipesEncoder {...props} HideMe = {hideCallBack} />;
  break;  
  case "hideshow" : 

  TheContent = <HideShowCages {...props}  HideMe = {hideCallBack}    />;
break;
  case "Layout" : 

  TheContent = <CageLayout {...props}  HideMe = {hideCallBack}    />;
break;  

case "CreateCages" : 

  TheContent = <CreateCages {...props}  HideMe = {hideCallBack}    />;

case "Silos" : 

  TheContent = <Silos {...props}  HideMe = {hideCallBack}    />;  
break;  
  default: break;

}






return <div className="set-wrap">
{TheNavBar}

<div className = "set-content-wrap">
{TheContent}


</div>


</div>


}