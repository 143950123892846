import React, { useState, useEffect } from "react";
import moment from "moment";
import PopEdit from "./PopEdit";
import AddFeed from "./AddFeed";
import Amplify, { graphqlOperation, API } from "aws-amplify";
import axios from "axios";
import "./Feeding.css";
import BootstrapTable from "react-bootstrap-table-next";
import { Button, OverlayTrigger, Popover } from 'react-bootstrap';
import { Loading } from "../Components/Loading";
import { listUsers, ListSimpleOrg } from "../customGQL/customQueries";

import SiloIcon from  "./image/3228336_silo_icon.ico";

//"./image/3228336_silo_icon(32).png";

import ScaleIcon from   "./image/2770324_equipment_kitchen_scale_scales_weight_icon.ico";

import SumIcon from  "./image/7266786_sigma_sum_total_greek_icon.ico";


import ProfilePreview from "./ProfilePreview"

import aws_exports from "../aws-exports";

Amplify.configure(aws_exports, {});



const colorGreen = "#72F363";
//const colorYellow = "#F3E463";
const colorYellow = "#f3f362"
const colorGray = "#CDCDBF";
//const colorWarning= "#FFA500";
const colorWarning= "#f3ab62";
//const colorError = "#ff0000";
const colorError = "#f36262";

const SelColorGreen =    "rgba(114, 243, 99, 0.5)";//"#72F363";
const SelColorYellow = "#F3E463";
const SelColorGray = "#CDCDBF";

let TheTimer = null;

let TimerCounter =0;
let TimerLimit = 10;

export default function TableView(props) {



  
  const [Cages, setCages] = useState([]);
  const [Silos, setSilos] = useState([]);
const [ProfileList, setProfileList] = useState([]);
const [SGRIdList, setSGRIdList] = useState([]);
const [PopNameList, setPopNameList] = useState([]);
const [FeedTypeList, setFeedTypeList] = useState([]);

  const [DateOnSite, setDateOnSite] = useState('');  //Date of the latest feeding records in localtime
  const [DayToday, setDayToday] = useState (true); //TRue if the DateOnSite is the same as the current date in browser

  const [ShowSilo, setShowSilo] = useState(null);
  const [ShowCage, setShowCage] = useState(null);

  const [ShowData, setShowData] = useState(false);

  const [IsLoading, setLoading] = useState(true);
  
  const [SelectedCageIdx, setSelectedCageIdx] = useState(-1);
  const [SelectedSiloIdx, setSelectedSiloIdx] = useState(-1);

  const [ErrorToShow, setErrorToShow] = useState("");

  



  useEffect(() => {
 


    loadData();

    if(TheTimer) {
      clearInterval(TheTimer);
  }

    TheTimer = setInterval (()=>{
        TimerCounter++;


        if (TimerCounter >= TimerLimit){
          TimerLimit = 10;
          TimerCounter = 0;
        fetchDashboard();
          

        }

      }, 2000);
    
     return () => { 
     
       if (TheTimer){clearInterval(TheTimer)}  };   

  }, [props.FarmID, props.DashModified]);

  //let theUrl = "https://toajzvu4i7.execute-api.eu-west-1.amazonaws.com/dev/getdashboard";


  let theUrl = "https://h93jh4d41f.execute-api.eu-west-1.amazonaws.com/dev/counters?organisationId=432&counterID=2249"


  //"https://ta344ydjbc.execute-api.eu-west-1.amazonaws.com/dev"

  //"https://3at3vl601e.execute-api.eu-west-1.amazonaws.com/dev/Notify"










  const fetchDashboard = () => {



    let apiName = "vakicloudRdsAPI";
    let path = "/counters/feeding/dashboard";
    let myInit = {

      // OPTIONAL
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        FarmID: props.FarmID


      }
    };




    API.get(apiName, path, myInit).then(response => {






     // setTimeout (fetchDashboard, 30000);

     


      var vv = response.Cages.find(r => r.TheDate);
      


      if (!vv)

   
        setDateOnSite('')
      else {
        var dt = moment(vv.TheDate).utc().format('MMM. D YYYY');

         setDayToday (dt == moment (new Date()).utc().format('MMM. D YYYY'));
      
        setDateOnSite (dt);
      }



/*
      let len = response.Cages.length;
      for (let i = 0; i< len; i++)
          response.Cages [i]["key"] = i;

          len = response.Profiles.length;
          for (let i = 0; i< len; i++)
              response.Profiles [i]["key"] = i;

              len = response.Silos.length;
              for (let i = 0; i< len; i++)
                  response.Silos [i]["key"] = i;              

                  len = response.PopNameList.length;
                  for (let i = 0; i< len; i++)
                      response.PopNameList [i]["key"] = i;  
                      
                      len = response.FeedTypes.length;
                      for (let i = 0; i< len; i++)
                          response.FeedTypes [i]["key"] = i;  
*/
      setSilos(response.Silos);
      setCages(response.Cages);


      setProfileList(response.Profiles);

      setSGRIdList(response.SGR);

      setPopNameList(response.PopNameList);

    
      setFeedTypeList (response.FeedTypes);


      
      setShowData(true);
      setErrorToShow("");
      setLoading(false);



    })
      .catch((err) => {
        console.log ("FetchDashboard fail: "+JSON.stringify(err))
        setSilos([]);
        setCages([]);
        setErrorToShow(err.message)
        setShowData(false);
        setLoading(false);

      })

  };






  const loadData = async () => {


    fetchDashboard()


  }






  //console.log("orgList all", orgList);










  function cellCagesStyle(cell, row, rowIndex, colIndex) {
    var style = { backgroundColor: colorGreen, color: "#232323"};






    if (rowIndex == SelectedCageIdx){
        
      style.backgroundColor = "#787878"
      if (row.NoOfFish === 0)
        style.color = colorGray;
      else if (!row.Active )
        style.color = colorYellow;
      else
        style.color = colorGreen;

    }else{
      if (row.NoOfFish === 0)
        style.backgroundColor = colorGray;
      else if (!row.Active )
        style.backgroundColor = colorYellow;
    }

    if (colIndex == 5){
          style['color'] = (DayToday?'#212529'  :  "#0000ff" );
    }
      
    return style;
  }

  /*
  function statusStyle(cell, row, rowIndex, colIndex) {
    var style = { backgroundColor: colorGreen, color: "#232323", textAlign:"center" };
    
        

      if (row.status == "E" )
        //style.backgroundColor = colorError;
        style.color = colorError;
       
      else if (row.status == "()")
        style.backgroundColor = colorGray;
        
 
    return style;
  }

*/

function statusFormatter (cell, row, rowIndex, colIndex){



    let toShow = "Status OK";

    let textStyle = {};
   let classStatus = "fa far  fa-check-circle-o fa-1x fa-fw ";
   if (row.status == "E"){
      classStatus = "fa  fa-exclamation-circle fa-1x fa-fw";
      textStyle = {color:'red'};
      let ii = 0;
      toShow = row.errors.map(er=>{
        ii++;
        return <li key={ii}>{er}</li>
      });

      toShow = <ul>{toShow} </ul>;

   } else   if (row.status == "W"){
    classStatus = "fa  fa-warning fa-1x fa-fw";
    textStyle = {color:'orange'};
    let ii = 0;
    toShow = row.errors.map(er=>{
      ii++;
      return <li key ={ii}>{er}</li>
    });

    toShow = <div><p>Warning! </p>  <ul>{toShow} </ul></div>;

   }else   if (row.status == "()"){
      classStatus = "fa  fa-circle-o fa-1xfa-fw";

      toShow = "Empty cage";

   }

      return <OverlayTrigger delay={200} rootClose={true} placement='top' overlay={<Popover name = 'StatusPopover' > {toShow} </Popover >}>
      <div  style= {textStyle} >
      <i className={classStatus}> </i>
          </div>
      </OverlayTrigger >

}

function gramsFormatter (cell, row, rowIndex, colIndex){

  let toShow = "Total feeding today";

  let textStyle =  {color:'#212529' };
  

  if (!DayToday){
    textStyle =  {color:'#0000ff' };
    toShow = "Total feed the last day of feeding ("+DateOnSite+")";
  }

  let theWgt = cell/1000;
 

  return <OverlayTrigger delay={200} rootClose={true} placement='top' overlay={<Popover name = 'GramsPopover' > {toShow} </Popover >}>
      <div  style= {textStyle} >
           {cell/1000}
          </div>
      </OverlayTrigger >

}



const  AvgWgtFormatter = (cell, row, rowIndex, colIndex) =>
{

  let num = 0;

  if (cell >= 10){
      num = Math.round(cell*100)/100;
    
  }else{
    num = Math.round(cell*1000)/1000;

  }
  
  


  return num;

}



  function cellSilosFormatter(cell, row, rowIndex, colIndex) {
 
    var style = { backgroundColor: "white", color: "#232323"};

    if (rowIndex == SelectedSiloIdx){
      style.backgroundColor = "#787878";
      style.color = "white"


    }
    if (colIndex == 2 && row.MaxKilos > 0 && row.KilosInSilo / row.MaxKilos < 0.05) {
      // style.color = 'orangered';
      style.backgroundColor = "Yellow";
   
 
  }

    return style;
  }


  function  kilosInSiloFormatter (val){
      var x = Math.abs(val);

      if (x > 100)
        return Math.round (val);

      return  Math.round( val*10 )/10 ;
    


    
  }

function profileFormatter (val){
  if (!val || val =="")
    return "";

  let  profile = ProfileList.find(p=>p.ProfileId == val);

  return <div className = "smallText">
  
  <ProfilePreview  TheProfile={profile}/>
  {val}
  </div>;  


}





const HeaderStyle = (col, colIdx) =>{

    let style = {textAlign:'center'};

  //  console.log (colIdx+"   DATAFIELD: "+col.dataField);

/*
    if (col.dataField == 'grams')
      style['color'] = (DayToday?'#212529'  :  "#F4C70E" );
*/
    return style;  
} 


const HeaderFormatter = (col, colIdx) => {
  let _a = colIdx; 
 
  if (!col.tooltip || col.tooltip=='')
    return col.text;


  let toShow = col.text; 

 
  if (!toShow || toShow.trim() == ""){

    if (col.dataField == 'SiloArray')
       toShow =  <img  width="40px" id="silo-icon" src={SiloIcon}></img>;
    else  if (col.dataField ==  'AvarageWeight')
        toShow =  <img width="40px" id="scale-icon" src={ScaleIcon}></img>;
    else     //grams
        toShow =  <img width="40px" id="sum-icon" src={SumIcon}></img>;
  }
  

  return <OverlayTrigger delay={200} rootClose={true} placement='top' overlay={<Popover name = 'ColHeaderPopover' > {col.tooltip} </Popover >}>
  <div>
    {toShow}
      </div>
  </OverlayTrigger >



  return <div> col.txt  </div>


}

  let Cage_columns = [{
    dataField: 'Name',
    text: ' ',
    style: cellCagesStyle,
    headerFormatter: HeaderFormatter,
    headerStyle : HeaderStyle,
    tooltip: 'Cage',
    sort: true
  },
  {
    dataField: 'PopName',
    text: 'Population',
    style: cellCagesStyle,
    headerFormatter: HeaderFormatter,
    headerStyle : HeaderStyle,
    tooltip: 'ID of the population in cage'
  }, 
  {
    dataField: 'ProfileId',
    text: 'Profile',
    style: cellCagesStyle,
    formatter: profileFormatter,
    headerFormatter: HeaderFormatter,
    headerStyle : HeaderStyle,
    tooltip: '24 hour feeding profile preview'
  },
  
  {
    dataField: 'NoOfFish',
    text: '#',

    style: cellCagesStyle,
    sort: true,
    tooltip: 'Total number of fish in the cage',
     headerStyle : HeaderStyle,
    headerFormatter: HeaderFormatter
  }
    , {

    dataField: 'AvarageWeight',
    text: ' ',
    tooltip : 'Average fishweight in cage (in kg)',
    formatter: AvgWgtFormatter,

    style: cellCagesStyle,
    headerStyle : HeaderStyle,
    headerFormatter: HeaderFormatter
   
  },
  {
    dataField: 'grams',
    text: ' ',
  
    sort: true,
    tooltip:  <div> <div> {'Feeding today or during the last day of feeding ('+DateOnSite+') in (kg)'} </div> <div> Color blue if not today'</div></div>,
    formatter: gramsFormatter,
    headerFormatter: HeaderFormatter,
    headerStyle : HeaderStyle,
    style: cellCagesStyle
  }
  ,
  {
    dataField: 'SiloArray',
    tooltip: "Silo(s)",
    text: " ",
    icon: SiloIcon,
    sort: true,
     style: cellCagesStyle,
     headerStyle : HeaderStyle,
     headerFormatter: HeaderFormatter
  }
  ,
  {
    dataField: 'pipes',
    text: "P",
    sort: true,
     style: cellCagesStyle,
     tooltip: 'Pipe',
     headerStyle : HeaderStyle,
     headerFormatter: HeaderFormatter
  },
  {
    dataField: 'status',
    text: "S",
    formatter: statusFormatter,
    tooltip: <div> <div>Status.</div>  <div> Hover column cells for description</div></div>,
    headerFormatter: HeaderFormatter,
    headerStyle : HeaderStyle,
    style: cellCagesStyle


  }

  ];



  let Silo_columns = [{
    dataField: 'SiloId',
    text: 'No',
    style: cellSilosFormatter
  },
  {
    dataField: 'Description',
    text: 'Silo',
    style: cellSilosFormatter
  }, {
    dataField: 'KilosInSilo',
    text: '(kg)',
    style: cellSilosFormatter,
    formatter: kilosInSiloFormatter

  }, {
    dataField: 'CurrentFood',
    text: 'Food',
    style: cellSilosFormatter

  }

  ];





  const cageRowEvents = {
    onClick: (e, row, rowIndex) => {
     
      setSelectedCageIdx (rowIndex)
      setShowCage(row);
    }
  };


  const siloRowEvents = {
    onClick: (e, row, rowIndex) => {
     
      setSelectedSiloIdx (rowIndex)
      setShowSilo(row);
    }
  };







  let TheCageEdit = null;
  let TheSiloEdit = null;
  let ErrorMsg = "";
  let TheData = "";

  const hidePopEdCallback = () => {setShowCage(false); setSelectedCageIdx(-1)}

  const hideSiloEditCallback = () => {setShowSilo(null); setSelectedSiloIdx (-1) }



const updateCageRowCallback  = ( row /* PopName, NoOfFish, AverageWeight, Silos*/) => {
    
    let idx = Cages.findIndex (r=>r.CageId == row.CageId);

    let Temp = [];
    for (var i = 0; i< Cages.length; i++){
      if (i===idx){
        Temp.push(row);
       
      }else  
        Temp.push ({...Cages[i]})
    }


 


    setCages (Temp);
    

}



const updateSiloRowCallback  = ( row /* PopName, NoOfFish, AverageWeight, Silos*/) => {
    
  let idx = Silos.findIndex (r=>r.SiloId == row.SiloId);

  let Temp = [];
  for (var i = 0; i< Silos.length; i++){
    if (i===idx)
      Temp.push(row)
    else  
      Temp.push ({...Silos[i]})
  }


  



  setSilos (Temp);
  

}





  if (ShowCage){



    let siloSys = [];
    for (var i = 0; i<Silos.length; i++)
      //if (ShowCage.SysIdx === Silos[i].SysIdx)
        siloSys.push (Silos[i])

    


       

    TheCageEdit = (<PopEdit   {...props} CageInfo={ShowCage}   HideMe = {hidePopEdCallback} updateParent = {updateCageRowCallback} PopNameList = {PopNameList}
      Silos= {siloSys}   ProfileList = {ProfileList}  SGRIdList = {SGRIdList}    />);
      
      
  }
    



  if (ShowSilo)
    TheSiloEdit = (<AddFeed  SiloInfo = {ShowSilo}   FarmID  ={props.FarmID}  HideMe = {hideSiloEditCallback}   FeedTypeList = {FeedTypeList}  updateParent = {updateSiloRowCallback} />);


  

  if (ErrorToShow.length > 0) {

    
    ErrorMsg = <div className="feed-ErrorMSG" > {ErrorToShow} </div>
  }


  if (ShowData) {
    TheData = (
      <div className="flex-container">

        <div className="tv-container__cages">

          <BootstrapTable
            hover
            keyField='Name'
            data={Cages}
            columns={Cage_columns}
            rowEvents={cageRowEvents}
           

          />


        </div>
        <div className="tv-container__right">
          {TheCageEdit}
          {TheSiloEdit}


          <div className="tv-silos">
            <BootstrapTable
              hover
              keyField='SiloId'
              data={Silos}
              columns={Silo_columns}
              rowEvents={siloRowEvents}
            />
          </div>


        </div>
      </div>

    );

  }











  return (
    <div>
      {IsLoading && <Loading />}
      {ErrorMsg}
      {!IsLoading && TheData}
      <div className="mf-close">
  

    </div>

    </div>
  )


}

